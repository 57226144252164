import * as React from 'react';

import { useSessionStore } from '~/src/store';
import { UserRole } from '../constants/roleGroups';

import { getUserRole } from './appHelpers';

export const useAuthorization = () => {
  const { user } = useSessionStore();

  if (!user) {
    // eslint-disable-next-line i18next/no-literal-string
    console.error('User does not exist!');
  }

  const checkAccess = React.useCallback(
    ({ allowedRoles }: { allowedRoles: Array<UserRole> }) => {
      if (allowedRoles && allowedRoles.length > 0) {
        return allowedRoles?.includes(getUserRole(user));
      }

      return true;
    },
    // eslint-disable-next-line
    [getUserRole(user)],
  );

  return { checkAccess, role: getUserRole(user) };
};

type AuthorizationProps = {
  forbiddenFallback?: React.ReactNode;
  children: React.ReactNode;
} & (
  | {
      allowedRoles: Array<UserRole>;
      policyCheck?: never;
    }
  | {
      allowedRoles?: never;
      policyCheck: boolean;
    }
);

export const Authorization = ({
  policyCheck,
  allowedRoles,
  forbiddenFallback = null,
  children,
}: AuthorizationProps) => {
  const { checkAccess } = useAuthorization();

  let canAccess = false;

  if (allowedRoles) {
    canAccess = checkAccess({ allowedRoles });
  }

  if (typeof policyCheck !== 'undefined') {
    canAccess = policyCheck;
  }

  return <>{canAccess ? children : forbiddenFallback}</>;
};
