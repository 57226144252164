import React, { useImperativeHandle, forwardRef, Ref, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useToast, ToastVariant, ToastPropsV2, Locale } from '@evacenter/eden';
import { toWhomItMayConcernId, usePractitionerSearchModal } from '@eva-tech/graph-driven-ui';

import { apolloClient } from '@eva-pacs/client';

import { useLanguage } from '~/src/hooks/useLanguage';
import { useStudyActions } from '~/src/hooks/study/useStudyActions';
import { useStudyListStore } from '~/src/store';

import { ReferringPractitionerCellProps } from '.';

interface PractitionersModalProps extends ReferringPractitionerCellProps {
  defaultPractitioner: any;
  onCloseModal?: () => void;
}

export interface PractitionerModalImperativeMethods {
  handleOpenModal(): void;
}

export const PractitionersModal = forwardRef(
  (
    {
      referringPractitionerId,
      facilityId,
      modalityPractitionerName,
      defaultPractitioner,
      hasEditPermissions,
      onCloseModal,
      studyId,
    }: PractitionersModalProps,
    ref: Ref<PractitionerModalImperativeMethods>,
  ) => {
    const { currentLanguage } = useLanguage();
    const { performUpdateStudy } = useStudyActions();
    const getStudies = useStudyListStore((state) => state.getStudies);
    const { addToast } = useToast();
    const { t } = useTranslation();

    const handleOnSuccess = (practitionerId: string, practitionerName: string) => {
      const isAQCItem = practitionerId === toWhomItMayConcernId;
      const currentStudies = getStudies();
      const title = t('study.studyTable.referringPractitionerModal.successfullAssignationTitle');
      const subtitle = isAQCItem
        ? t('study.studyTable.referringPractitionerModal.itMayConcernSuccessfullAssignation')
        : t('study.studyTable.referringPractitionerModal.successfullAssignation', {
            fullName: practitionerName,
          });
      const study = currentStudies[studyId];

      const AQCItemStudy = {
        ...study,
        dicomReferringPhysician: null,
        dicomRequestingPhysician: null,
        referringPractitioner: null,
      };

      const newStudy = isAQCItem
        ? AQCItemStudy
        : {
            ...study,
            referringPractitioner: {
              id: practitionerId,
              fullName: practitionerName,
            },
          };

      performUpdateStudy(studyId, newStudy);

      return addToast({
        variant: ToastVariant.success,
        title,
        subtitle,
        dismissInterval: 5000,
      } as ToastPropsV2);
    };

    const handleOnSuccessPractitionerCreation = (physicianName: string) => {
      addToast({
        variant: ToastVariant.success,
        title: t('study.studyTable.referringPractitionerModal.createPractitionerSuccessfullTitle'),
        subtitle: t('study.studyTable.referringPractitionerModal.createPractitionerSuccessfull', {
          physicianName,
        }),
        dismissInterval: 5000,
      } as ToastPropsV2);
    };

    useImperativeHandle(ref, () => ({
      handleOpenModal: () => handleOpenSearchModal(),
    }));

    const { handleOpenSearchModal, modalNode } = usePractitionerSearchModal({
      studyId,
      referringPractitionerId: referringPractitionerId,
      onSuccessUpdate: handleOnSuccess,
      defaultPractitioner,
      modalityPractitionerName,
      client: apolloClient,
      onSuccessPractitionerCreation: handleOnSuccessPractitionerCreation,
      locale: currentLanguage as Locale,
      onCloseModal: onCloseModal,
      hasEditPermissions,
      facilityId: facilityId || '',
    });

    useEffect(() => {
      handleOpenSearchModal();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{modalNode}</>;
  },
);
