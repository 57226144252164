import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '~/src/hooks/useLanguage';
import { formatStringDateWithTime } from '@eva-pacs/core';

import type { StudyRow } from '../studyList.d';

interface ShowMoreProps {
  /**
   * Text to truncate
   */
  text: string;

  /**
   * Maximum number of characters allowed to truncate the `text`
   */
  maxLength?: number;
}

const TEXT_MAX_LENGTH_BEFORE_TRUNCATE = 200;

// [PACS-1650]: Follow up story to move this component to @eva-pacs/ui
/**
 * Use this component in order to display a toggle button to either show more or less content if exceeds the limit of allowed characters.
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2022-05-06
 */
const ShowMore = ({ text, maxLength = TEXT_MAX_LENGTH_BEFORE_TRUNCATE }: ShowMoreProps): JSX.Element => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const isLongText = text?.length > maxLength;
  const truncatedText = isLongText && !showMore ? text?.slice(0, maxLength) : text;
  const showMoreButton = (
    <button className="text-basic-white font-bold" onClick={() => setShowMore(!showMore ? true : false)}>
      {!showMore ? `...${t('general.more')}` : t('general.less')}
    </button>
  );

  return (
    <>
      {truncatedText} {isLongText && showMoreButton}
    </>
  );
};

interface DetailsItemProps {
  /**
   * Text to display as the header of the item
   */
  heading: string;

  /**
   * Text to display as the content of the item
   */
  text?: string;
}

/**
 * Use this component in order to display a cell item within `JoinStudiesDetails` component.
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2022-05-06
 */
const DetailsItem = ({ heading, text = '' }: DetailsItemProps) => {
  return (
    <div className="e-space-y-1">
      {heading && <div className="e-text-neutral-200 e-text-xs e-font-medium">{heading}</div>}
      <p className="e-text-neutral-50 e-text-xs e-font-medium">
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <ShowMore text={text || '-'} />
      </p>
    </div>
  );
};

interface JoinStudiesDetailsProps {
  /**
   * Target study from where to get the fields to display information.
   */
  study: StudyRow;
}

/**
 * Use this component in order to display detailed information about the fields of a target study
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2022-05-06
 */
export const JoinStudiesDetails = ({ study }: JoinStudiesDetailsProps) => {
  const { t } = useTranslation();
  const { currentLanguage } = useLanguage();

  return (
    <div className="e-bg-neutral-700 e-flex e-flex-col e-p-4 e-rounded-md">
      <div className="e-flex e-items-center e-space-x-1">
        <div className="e-text-xs e-text-neutral-200 e-font-light">{t('study.join.studyCaptureDate')}</div>
        <div className="e-text-xs e-text-neutral-50 e-font-medium">
          {formatStringDateWithTime(study.dicomDateTime.time, currentLanguage)}
        </div>
      </div>
      <hr className="e-border-neutral-500 e-my-4" />
      <div className="e-grid e-gap-4 e-grid-cols-2">
        <DetailsItem heading={t('study.studyFields.identifierEditable')} text={study.patient.identifier} />
        <DetailsItem
          heading={t('study.studyFields.practitionerAssigned')}
          text={study.practitionerAssigned.default.value}
        />
        <DetailsItem heading={t('study.studyFields.folio')} text={study.modalities.folio} />
        <DetailsItem heading={t('study.studyFields.dicomRequestingPhysician')} text={study.dicomRequestingPhysician} />
        <DetailsItem heading={t('study.studyFields.shortFacility')} text={study.facility} />
        <DetailsItem heading={t('study.studyFields.dicomReferringPhysician')} text={study.dicomReferringPhysician} />
        <DetailsItem heading={t('study.studyFields.studyTypeEditable')} text={study.studyType} />
        <DetailsItem heading={t('study.studyFields.dicomReason')} text={study.dicomReason} />
      </div>
    </div>
  );
};
