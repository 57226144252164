import React, { useEffect, useState } from 'react';
import {
  SortingState,
  useReactTable,
  getCoreRowModel,
  RowSelectionState,
  getSortedRowModel,
} from '@tanstack/react-table';
import { IntersectionObserverHookRefCallback } from 'react-intersection-observer-hook';

import { useStudyTableUtils } from '~/hooks';
import { useSessionStore, useStudyListStore } from '~/src/store';
import { CAN_DELETE_STUDY, POLICIES } from '~/constants';
import { useStudyListTabs } from '~/src/hooks/study/useStudyListTabs';
import { Accessor, useStudyTableColumns } from './useStudyTableColumns';
import { WorklistTabNames } from '~/pages/StudyList/Worklist/WorklistTabs';

import { Table } from './Table';
import { Loading } from './Loading';
import { Skeleton } from './Skeleton';
import { EmptyState } from './EmptyState';
import useBurnIso from '../BurnIso/useBurnISO';
import type { StudyRow } from '../studyList.d';
import { useStudyActionsStore } from '../studyActionsStore';

interface SortBy {
  id: string;
  desc: boolean;
}

export interface StudyTableProps {
  loading?: boolean;
  data: Array<StudyRow>;
  pageSize: number;
  totalCount: number;
  className?: string;
  hasNextPage?: boolean;
  initialLoading?: boolean;
  hasPreviousPage?: boolean;
  isEmptyFilterFields?: boolean;
  rootRef: IntersectionObserverHookRefCallback;
  sentryRef: IntersectionObserverHookRefCallback;
  onTableLoad: (sortBy: Array<SortBy>) => void;
  onTableNotificationRowClick: () => void;
}

/**
 * Study table (study list).
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-10-08
 */
export const StudyTable: React.FC<StudyTableProps> = ({
  loading = false,
  data = [],
  className,
  hasNextPage = false,
  initialLoading = false,
  hasPreviousPage = false,
  rootRef,
  sentryRef,
  pageSize,
  totalCount,
  isEmptyFilterFields,
  onTableLoad,
  onTableNotificationRowClick,
}) => {
  const { canBurnDiscs } = useBurnIso();
  const columns = useStudyTableColumns();
  const user = useSessionStore((state) => state.user);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const setSelectedStudies = useStudyActionsStore((state) => state.setSelectedStudies);
  const enabledInfiniteScroll = useStudyListStore((state) => state.enabledInfiniteScroll);

  // Workaround for avoiding a bug that is introduced because of calculating the rows with a timeout delay
  // in worklist, because that is needed for animations in worklist to behave properly.
  const { activeTab } = useStudyListTabs();
  const { rows: worklistRows } = useStudyTableUtils();

  const canDeleteStudy = POLICIES[CAN_DELETE_STUDY](user);

  const tableList = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
    },
    manualSorting: true,
    sortDescFirst: false,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.actionColumn,
    onRowSelectionChange: setRowSelection,
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: canBurnDiscs || canDeleteStudy,
  });
  const { rows } = tableList.getRowModel();

  useEffect(() => {
    const ids = Object.keys(rowSelection).filter((key) => rowSelection[key]);
    const selectedStudies = rows.filter((study) => ids.includes(study.id)).map((study) => study.original) as StudyRow[];
    setSelectedStudies(selectedStudies);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, rowSelection]);

  useEffect(() => {
    if (initialLoading) setRowSelection({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoading]);

  const parseSortByValue = (sortByList: Array<SortBy> = []): Array<SortBy> => {
    return sortByList.map((sortBy) => {
      if (sortBy.id === Accessor.patient) return { ...sortBy, id: 'patient__fullName' };
      return sortBy;
    });
  };

  useEffect(() => {
    if (!sorting) return;
    if (onTableLoad) onTableLoad(parseSortByValue(sorting as Array<SortBy>));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting]);

  if (!enabledInfiniteScroll && loading) return <Loading />;
  if (enabledInfiniteScroll && initialLoading) return <Skeleton showInTable={false} />;

  // Workaround for avoiding a bug that is introduced because of calculating the rows with a timeout delay
  // in worklist, because that is needed for animations in worklist to behave properly.
  if (activeTab === WorklistTabNames.WORKLIST) {
    if (worklistRows.length === 0 && !initialLoading) return <EmptyState />;
  } else {
    if (rows.length === 0 && !initialLoading) return <EmptyState />;
  }

  return (
    <Table
      rows={rows}
      columns={columns}
      loading={loading}
      rootRef={rootRef}
      pageSize={pageSize}
      className={className}
      sentryRef={sentryRef}
      totalCount={totalCount}
      hasNextPage={hasNextPage}
      hasPreviousPage={hasPreviousPage}
      headers={tableList.getHeaderGroups()}
      isEmptyFilterFields={isEmptyFilterFields}
      onTableNotificationRowClick={onTableNotificationRowClick}
    />
  );
};
