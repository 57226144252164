import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import shallow from 'zustand/shallow';

import {
  DataTableEditCell,
  DataTableEditCellSizes,
  DatatableEditCellVariant,
  NativeSelectProps,
  QuickEditListItemProps,
  SelectOption,
  Locale,
} from '@evacenter/eden';

import { StudyStatus } from '@eva-pacs/client';
import { NativeSelectOption } from '@eva-pacs/ui';

import { useLanguage } from '~/src/hooks/useLanguage';
import { PractitionerRow } from '~/components/StudyList/studyList';
import { useFeatureFlagsStore, useSessionStore } from '~/src/store';
import { FeatureFlags, POLICIES, CAN_CURRENT_USER_BE_ASSIGNED_TO_STUDY } from '~/constants';
import { useGetAssignablePractitionerOptions } from '~/src/hooks/practitioners/useGetAssignablePractitionerOptions';

const MIN_OPTIONS_TO_SHOW_SCROLLBAR = 7;

interface PractitionerCellProps extends Omit<NativeSelectProps, 'options' | 'onChange'> {
  fallbackOption: NativeSelectOption;
  onChangeAssignedPractitioner?: (option: SelectOption) => void;
  onChangeAssignedReviewer?: (option: SelectOption) => void;
  onChange?: (option: SelectOption) => void;
  practitionerAssignedValue?: string;
  reportStatus?: StudyStatus | undefined;
  reviewerAssignedValue?: string;
  isStudySigned?: boolean;
  isDoubleSignEnabled?: boolean;
  /**
   * The reviewer practitioner to show if it exists and double sign is enabled
   */
  reviewerPractitioner?: PractitionerRow;
}

/**
 * Practitioner Cell (Table)
 * @author Alejandro Forero<alejandro.forero@edenmed.com>
 * Created at 2024-01-31
 */

export const PractitionerCell = ({
  fallbackOption,
  onChangeAssignedPractitioner,
  onChangeAssignedReviewer,
  practitionerAssignedValue,
  reviewerAssignedValue,
  reviewerPractitioner,
  isDisabled,
  isStudySigned,
  reportStatus,
  isDoubleSignEnabled,
}: PractitionerCellProps) => {
  const { t } = useTranslation();
  const { currentLanguage } = useLanguage();
  const hasFlag = useFeatureFlagsStore((store) => store.hasFlag);

  const { options, loading } = useGetAssignablePractitionerOptions({ fetchPolicy: 'cache-first' });
  const { options: reviewersOptions, loading: loadingReviewersOptions } = useGetAssignablePractitionerOptions({
    fetchPolicy: 'cache-first',
    getReviewersList: true,
  });
  const [user, getCurrentAccount] = useSessionStore((store) => [store.user, store.getCurrentAccount], shallow);
  const denyAssign = (!hasFlag(FeatureFlags.EnablePractitionerReassignment) && isStudySigned) || isDisabled;

  const reviewerOptions = useMemo(() => {
    return getFullPractitionerOptions(
      reviewersOptions,
      getCurrentAccount,
      user,
      t('study.filterFields.notAssigned'),
      practitionerAssignedValue,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCurrentAccount, reviewersOptions, practitionerAssignedValue, user]);

  const practitionerAssignedOptions = useMemo(() => {
    return getFullPractitionerOptions(
      options,
      getCurrentAccount,
      user,
      t('study.filterFields.notAssigned'),
      // edge case when we have a reviewer assigned but we don't have double signature enabled,
      // dont remove any practitioner from the list.
      isDoubleSignEnabled ? reviewerAssignedValue : undefined,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCurrentAccount, options, reviewerAssignedValue, user, isDoubleSignEnabled]);

  const formatPrimaryText = (prependMessage, option) => {
    const notAssignedText = t('study.filterFields.notAssigned');
    if (isDoubleSignEnabled)
      return option?.value ? `${prependMessage}: ${option.label}` : `${prependMessage}: ${notAssignedText}`;
    return option?.value ? option.label : notAssignedText;
  };

  const practitionerItemProps: Array<QuickEditListItemProps> = useMemo(() => {
    const commonProps = {
      fallbackOption,
      locale: currentLanguage as Locale,
      isDisabled,
      options: practitionerAssignedOptions,
      onOptionChange: onChangeAssignedPractitioner,
      value: practitionerAssignedValue,
      primaryText: formatPrimaryText(t('study.studyTable.practitionerAssigned'), fallbackOption),
      cellVariant:
        denyAssign || reportStatus === StudyStatus.REVIEW_PENDING
          ? DatatableEditCellVariant.ONLY_READ
          : DatatableEditCellVariant.LOCAL,
    };

    if (isDoubleSignEnabled) {
      return [
        commonProps,
        {
          locale: currentLanguage as Locale,
          fallbackOption: reviewerPractitioner?.default || { value: '', label: '' },
          isDisabled,
          options: reviewerOptions,
          optionToRemove: practitionerAssignedValue,
          onOptionChange: onChangeAssignedReviewer,
          value: reviewerAssignedValue,
          primaryText: formatPrimaryText(t('study.studyTable.reviewerPractitioner'), reviewerPractitioner?.default),
          cellVariant: denyAssign ? DatatableEditCellVariant.ONLY_READ : DatatableEditCellVariant.LOCAL,
        },
      ];
    }

    return [commonProps];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fallbackOption,
    onChangeAssignedPractitioner,
    onChangeAssignedReviewer,
    practitionerAssignedValue,
    reviewerAssignedValue,
    reviewerPractitioner,
    currentLanguage,
    isDisabled,
    isStudySigned,
    reportStatus,
    practitionerAssignedOptions,
    reviewerOptions,
    isDoubleSignEnabled,
  ]);

  return (
    <div className="e-flex e-flex-col">
      <PractitionerItem listItems={practitionerItemProps} loading={loading || loadingReviewersOptions} />
    </div>
  );
};

const PractitionerItem = ({ listItems, loading }) => {
  const formatListItems = () =>
    listItems.map((item) => ({
      ...item,
      options: item.options,
      size: DataTableEditCellSizes.xs,
      selectValue: item.value || item.fallbackOption.value,
      blockBodyScroll: item.options.length <= MIN_OPTIONS_TO_SHOW_SCROLLBAR && loading,
      preloadOptions: item.value ? [item.fallbackOption] : [],
    }));

  return <DataTableEditCell listItems={formatListItems()} />;
};

const getFullPractitionerOptions = (options, getCurrentAccount, user, label, optionToRemove) => {
  const unAssingedPractitionerOption = { label, value: '' };
  const canUserBeAssigned = POLICIES[CAN_CURRENT_USER_BE_ASSIGNED_TO_STUDY](user);
  const cleanOptions = options.filter((option) => option.value !== optionToRemove);
  let currentUserOption;
  if (canUserBeAssigned) {
    const currentOptions = cleanOptions.filter((option) => {
      if (option.value === getCurrentAccount()?.practitionerId) {
        currentUserOption = option;
        return false;
      }
      return true;
    });

    if (!currentUserOption) return [unAssingedPractitionerOption, ...cleanOptions];

    // If the user is a radiologist, then assign its own practitionerId as the first option.
    return [currentUserOption, unAssingedPractitionerOption, ...currentOptions];
  }

  return [unAssingedPractitionerOption, ...cleanOptions];
};
