import { Account } from '~/src/store/sessionStore';
import { FRESH_CHAT_HOST_URL, EDEN_PACS_NAME, FreshChatConfig, FreshWidgetIframeClasses } from '~/constants';

export const getFreshChatConfig = (
  user: {
    id: string;
    email?: string;
  },
  currentAccount: Account,
  iFrameClasses: FreshWidgetIframeClasses = {},
): FreshChatConfig => ({
  token: process.env.REACT_APP_FRESHCHAT_TOKEN,
  host: FRESH_CHAT_HOST_URL,
  externalId: user.id,
  firstName: currentAccount.userInfo.name,
  lastName: currentAccount.userInfo.lastName,
  email: user.email || '',
  phone: currentAccount.userInfo.phone || '',
  phoneCountryCode: currentAccount.userInfo.phoneCode || '',
  organizationId: currentAccount.organizationId,
  organizationName: currentAccount.organizationName,
  practitionerId: currentAccount.practitionerId,
  config: {
    cssNames: iFrameClasses,
    headerProperty: {
      appName: EDEN_PACS_NAME,
      hideChatButton: true,
    },
  },
});
