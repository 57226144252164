import React from 'react';
import cn from 'classnames';

enum NotificationAlertVariant {
  primary = 'primary',
}

interface NotificationAlertProps {
  text: string;
  onClick?: () => void;
  variant?: NotificationAlertVariant;
}

export const NotificationAlert: React.FC<NotificationAlertProps> = ({
  text,
  onClick,
  variant = NotificationAlertVariant.primary,
}) => {
  const notificationClass = cn('text-sm p-2 text-center hover:underline font-medium rounded-xl cursor-pointer', {
    ['text-primary-200 hover:bg-primary-800  bg-primary-900']: variant === NotificationAlertVariant.primary,
  });

  const handleNotificationClick = () => onClick && onClick();

  return (
    <div
      className={notificationClass}
      role="button"
      tabIndex={0}
      onClick={handleNotificationClick}
      onKeyDown={handleNotificationClick}>
      {text}
    </div>
  );
};
