import cornerstone from 'cornerstone-core';
import csTools from 'cornerstone-tools';

import { conrnerstoneUnits, getFormatedFinalArea, numbersWithCommas } from '@eva-pacs/core';

const drawLinkedTextBox = csTools.importInternal('drawing/drawLinkedTextBox');
const drawJoinedLines = csTools.importInternal('drawing/drawJoinedLines');
const getPixelSpacing = csTools.importInternal('util/getPixelSpacing');
const getNewContext = csTools.importInternal('drawing/getNewContext');
const drawHandles = csTools.importInternal('drawing/drawHandles');
const draw = csTools.importInternal('drawing/draw');

export default class CustomFreehand extends csTools.FreehandRoiTool {
  name;
  constructor(props) {
    super(props);
  }

  renderToolData(evt) {
    const eventData = evt.detail;

    // If we have no toolState for this element, return immediately as there is nothing to do
    const toolState = csTools.getToolState(evt.currentTarget, this.name);

    if (!toolState) return;

    const { image, element } = eventData;

    // @ts-ignore
    const config = this.configuration;
    const seriesModule = cornerstone.metaData.get('generalSeriesModule', image.imageId);
    const modality = seriesModule ? seriesModule.modality : null;

    // We have tool data for this element - iterate over each one and draw it
    const context = getNewContext(eventData.canvasContext.canvas);
    const lineWidth = csTools.toolStyle.getToolWidth();
    const { renderDashed } = config;
    const lineDash = csTools.getModule('globalConfiguration').configuration.lineDash;

    const textBoxText = (data) => {
      const { meanStdDev, meanStdDevSUV, area } = data;
      // Define an array to store the rows of text for the textbox
      const textLines: any = [];

      // If the mean and standard deviation values are present, display them
      if (meanStdDev && meanStdDev.mean !== undefined) {
        // If the modality is CT, add HU to denote Hounsfield Units
        let moSuffix = '';

        if (modality === 'CT') moSuffix = 'HU';
        data.unit = moSuffix;

        // Create a line of text to display the mean and any units that were specified (i.e. HU)
        let meanText = `Mean: ${numbersWithCommas(meanStdDev.mean.toFixed(2))} ${moSuffix}`;
        // Create a line of text to display the standard deviation and any units that were specified (i.e. HU)
        let stdDevText = `StdDev: ${numbersWithCommas(meanStdDev.stdDev.toFixed(2))} ${moSuffix}`;

        // If this image has SUV values to display, concatenate them to the text line
        if (meanStdDevSUV && meanStdDevSUV.mean !== undefined) {
          const SUVtext = ' SUV: ';

          meanText += SUVtext + numbersWithCommas(meanStdDevSUV.mean.toFixed(2));
          stdDevText += SUVtext + numbersWithCommas(meanStdDevSUV.stdDev.toFixed(2));
        }

        // Add these text lines to the array to be displayed in the textbox
        textLines.push(meanText);
        textLines.push(stdDevText);
      }

      // If the area is a sane value, display it
      if (area) {
        // Determine the area suffix based on the pixel spacing in the image.
        // If pixel spacing is present, use millimeters. Otherwise, use pixels.
        // This uses Char code 178 for a superscript 2
        let suffix = ` ${conrnerstoneUnits.MM}${String.fromCharCode(178)}`;

        const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

        if (!rowPixelSpacing || !colPixelSpacing) {
          suffix = ` ${conrnerstoneUnits.pixelsComplete}${String.fromCharCode(178)}`;
        }

        // Create a line of text to display the area and its units
        const areaText = `Area: ${numbersWithCommas(area.toFixed(2))}${suffix}`;

        // Add this text line to the array to be displayed in the textbox
        textLines.push(areaText);
      }

      return textLines;
    };

    const textBoxAnchorPoints = (handles) => handles;

    for (let i = 0; i < toolState.data.length; i++) {
      const data = toolState.data[i];

      if (data.visible === false) {
        continue;
      }

      draw(context, (context) => {
        let color = csTools.toolColors.getColorIfActive(data);
        let fillColor;

        if (data.active) {
          if (data.handles.invalidHandlePlacement) {
            color = config.invalidColor;
            fillColor = config.invalidColor;
          } else {
            color = csTools.toolColors.getColorIfActive(data);
            fillColor = csTools.toolColors.getFillColor();
          }
        } else {
          fillColor = csTools.toolColors.getToolColor();
        }

        let options: any = { color };

        if (renderDashed) {
          options.lineDash = lineDash;
        }

        if (data.handles.points.length) {
          const points = data.handles.points;

          drawJoinedLines(context, element, points[0], points, options);

          if (data.polyBoundingBox) {
            drawJoinedLines(context, element, points[points.length - 1], [points[0]], options);
          } else {
            drawJoinedLines(context, element, points[points.length - 1], [config.mouseLocation.handles.start], options);
          }
        }

        // Draw handles

        options = {
          color,
          fill: fillColor,
        };

        if (config.alwaysShowHandles || (data.active && data.polyBoundingBox)) {
          // Render all handles
          options.handleRadius = config.activeHandleRadius;

          // @ts-ignore
          if (this.configuration.drawHandles) {
            drawHandles(context, eventData, data.handles.points, options);
          }
        }

        if (data.canComplete) {
          // Draw large handle at the origin if can complete drawing
          options.handleRadius = config.completeHandleRadius;
          const handle = data.handles.points[0];

          // @ts-ignore
          if (this.configuration.drawHandles) {
            drawHandles(context, eventData, [handle], options);
          }
        }

        if (data.active && !data.polyBoundingBox) {
          // Draw handle at origin and at mouse if actively drawing
          options.handleRadius = config.activeHandleRadius;

          // @ts-ignore
          if (this.configuration.drawHandles) {
            drawHandles(context, eventData, config.mouseLocation.handles, options);
          }

          const firstHandle = data.handles.points[0];

          // @ts-ignore
          if (this.configuration.drawHandles) {
            drawHandles(context, eventData, [firstHandle], options);
          }
        }

        // Update textbox stats
        if (data.invalidated === true && !data.active) {
          if (data.meanStdDev && data.meanStdDevSUV && data.area) {
            // @ts-ignore
            this.throttledUpdateCachedStats(image, element, data);
          } else {
            // @ts-ignore
            this.updateCachedStats(image, element, data);
          }
        }

        // Only render text if polygon ROI has been completed and freehand 'shiftKey' mode was not used:
        if (data.polyBoundingBox && !data.handles.textBox.freehand) {
          // If the textbox has not been moved by the user, it should be displayed on the right-most
          // Side of the tool.
          if (!data.handles.textBox.hasMoved) {
            // Find the rightmost side of the polyBoundingBox at its vertical center, and place the textbox here
            // Note that this calculates it in image coordinates
            data.handles.textBox.x = data.polyBoundingBox.left + data.polyBoundingBox.width;
            data.handles.textBox.y = data.polyBoundingBox.top + data.polyBoundingBox.height / 2;
          }

          const text = textBoxText.call(this, data);
          text[2] = getFormatedFinalArea(text[2]);

          drawLinkedTextBox(
            context,
            element,
            data.handles.textBox,
            text,
            data.handles.points,
            textBoxAnchorPoints,
            color,
            lineWidth,
            0,
            true,
          );
        }
      });
    }
  }
}
