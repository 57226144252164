import React, { FC, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, ButtonSize, ButtonVariant, IconCatalog, SimplePopover, PopoverPosition } from '@evacenter/eden';

import { useStudyListStore } from '~/src/store';
import { ListType } from '~/src/types/StudyList';

import { ListTypeItem } from './ListTypeItem';

interface ListTypeSwitchProps {
  handleSwitchListType: (listType: ListType) => void;
}

const DELAY_TIME_SWITCH = 1000;

export const ListTypeSwitch: FC<ListTypeSwitchProps> = ({ handleSwitchListType }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const listType = useStudyListStore((state) => state.listType);

  const switchListType = (type: ListType) => {
    handleSwitchListType(type);
    setTimeout(() => {
      setIsOpen(false);
    }, DELAY_TIME_SWITCH);
  };

  const classes = {
    container: cn('e-drop-shadow-xl e-w-screen sm:e-w-auto'),
    menu: cn('e-bg-neutral-700 e-flex e-flex-col e-pt-5 e-pb-6 e-px-6 e-text-left e-rounded-lg'),
    title: cn('e-text-base e-text-base-white e-mb-1'),
    description: cn('e-text-sm e-text-neutral-200 e-mb-6'),
    items: cn('e-flex e-space-x-4'),
  };

  const renderListType = () => {
    return (
      <div className={classes.menu}>
        <span className={classes.title}>{t('study.switchListType.title')}</span>
        <span className={classes.description}>{t('study.switchListType.description')}</span>
        <div className={classes.items}>
          <ListTypeItem
            type={ListType.INFINITE_SCROLL}
            isActive={listType === ListType.INFINITE_SCROLL}
            label={t('study.switchListType.infiniteScroll')}
            onClick={() => switchListType(ListType.INFINITE_SCROLL)}
          />
          <ListTypeItem
            type={ListType.PAGINATION}
            isActive={listType === ListType.PAGINATION}
            label={t('study.switchListType.pagination')}
            onClick={() => switchListType(ListType.PAGINATION)}
          />
        </div>
      </div>
    );
  };

  return (
    <SimplePopover
      controlled
      open={isOpen}
      onOpenChange={setIsOpen}
      content={renderListType()}
      position={PopoverPosition.TOP}
      contentClassName={classes.container}>
      <Button size={ButtonSize.xs} endIcon={IconCatalog.chevronUp} variant={ButtonVariant.tertiary}>
        {t('study.switchListType.button')}
      </Button>
    </SimplePopover>
  );
};
