import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonSize, IconCatalog, Popover, PopoverPlacement, Spinner } from '@evacenter/eden';
import { formatStringDateFullTime, Modals, UPLOAD_STUDY_PATH, useModal, useRouter } from '@eva-pacs/core';

import { FeatureFlags } from '~/src/constants/featureFlags';
import { useFeatureFlagsStore } from '~/src/store/flagsStore';
import { useCreatePatientNoDicom } from '~/src/hooks/patient/useCreatePatientNoDicom';
import { useCreateStudyNoDicom } from '~/src/hooks/study/useCreateStudyNoDicom';
import { useErrorHandler } from '~/utils/appHelpers';

export const CreateStudyButton = () => {
  const { t } = useTranslation();
  const { hasFlag } = useFeatureFlagsStore();
  const { push: historyPush } = useRouter();
  const { setCurrentModal } = useModal();
  const { createPatientNoDicom } = useCreatePatientNoDicom();
  const { createStudyNoDicom } = useCreateStudyNoDicom();
  const { handleError } = useErrorHandler();

  const handleDicomStudyClick = () => historyPush(UPLOAD_STUDY_PATH);

  const handleNoDicomStudyClick = async () => {
    setCurrentModal({
      name: Modals.GenericModal,
      props: {
        title: t('study.creatingStudy'),
        children: <Spinner />,
      },
    });
    try {
      //TODO: Remove temporary name when patient form already exists
      const patientName = t('general.new').toUpperCase();
      const patientFirstSurname = t('study.patient').toUpperCase();
      const patientLastSurname = formatStringDateFullTime(new Date().toISOString());
      const patientId = await createPatientNoDicom({
        name: patientName,
        firstSurname: patientFirstSurname,
        lastSurname: patientLastSurname,
      });
      if (!patientId) throw new Error();
      const studyId = await createStudyNoDicom(patientId);
      if (!studyId) throw new Error();
      setCurrentModal(null);
      const evacenterUrl = process.env['REACT_APP_EVACENTER_URL'];
      window.open(`${evacenterUrl}/pacs/study-files/${studyId}`, '_blank');
    } catch (error) {
      console.error(error);
      handleError({ modalProps: { description: t('study.errors.creatingStudyNoDicom') } });
    }
  };

  const createStudyOptions = (
    <div className="flex flex-col">
      <button className="flex px-4 py-2 hover:bg-gray-900" onClick={handleDicomStudyClick}>
        {t('study.studyDicom')}
      </button>
      <button className="flex px-4 py-2 hover:bg-gray-900" onClick={handleNoDicomStudyClick}>
        {t('study.studyNoDicom')}
      </button>
    </div>
  );

  if (!hasFlag(FeatureFlags.CreateStudyNoDicom)) return <AddStudyButton onClick={handleDicomStudyClick} />;
  return (
    <Popover
      content={createStudyOptions}
      placement={PopoverPlacement.bottomEnd}
      hasArrow={false}
      menuClassName="overflow-hidden">
      <span className="flex flex-grow lg:flex-grow-0">
        <AddStudyButton />
      </span>
    </Popover>
  );
};

interface AddStudyButtonProps {
  onClick?: () => void;
}

// eslint-disable-next-line
const AddStudyButton = ({ onClick = () => {} }: AddStudyButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button className="flex-grow" endIcon={IconCatalog.plus} size={ButtonSize.sm} onClick={onClick}>
      {t('general.navbar.uploadStudy')}
    </Button>
  );
};
