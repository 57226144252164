import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTableEditCell,
  DataTableEditCellSizes,
  Locale,
  DatatableEditCellVariant,
  QuickEditListItemProps,
} from '@evacenter/eden';

import { PractitionerType, useGetPractitionerBasicInfoLazyQuery } from '@eva-pacs/client';

import { RowData, formatPractitionerRowData } from '@eva-tech/graph-driven-ui';

import { useLanguage } from '~/src/hooks/useLanguage';
import { referringPractitionerDTO } from '~/src/dtos/study/referringPractitionerDTO';
import { PractitionersModal } from './PractitionersModal';

export interface ReferringPractitionerCellProps {
  referringPractitionerId?: string;
  referringPractitionerFullName?: string;
  modalityPractitionerName?: string;
  studyId: string;
  hasEditPermissions?: boolean;
  facilityId?: string;
}

export const ReferringPractitionerCell = ({
  referringPractitionerId,
  facilityId,
  modalityPractitionerName,
  referringPractitionerFullName,
  hasEditPermissions,
  studyId,
}: ReferringPractitionerCellProps) => {
  const [mountModal, setMountModal] = useState(false);
  const formatDefaultPractitioner = (practitioner: any) => {
    if (!practitioner) return null;

    return formatPractitionerRowData({
      isSelected: true,
      locale: currentLanguage as Locale,
      telText: t('study.studyTable.referringPractitionerModal.telAbbreviation'),
      withoutInfoText: t('study.studyTable.referringPractitionerModal.withoutInfo'),
      practitionerEmail: practitioner?.email,
      practitionerFullName: practitioner?.fullName,
      practitionerHasAccess: practitioner?.hasAccess,
      practitionerId: practitioner?.id,
      practitionerPhone: practitioner?.phone,
      practitionerStatus: practitioner?.status,
    });
  };

  useEffect(() => {
    setMountModal(false);
  }, [referringPractitionerId]);

  const { currentLanguage } = useLanguage();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const defaultPractitionerRef = useRef<RowData>();
  const [getPractitionerById] = useGetPractitionerBasicInfoLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data.practitioner) return setMountModal(true);

      const practitioner = referringPractitionerDTO(data.practitioner as PractitionerType);
      const formattedPractitioner = formatDefaultPractitioner(practitioner);
      if (!formattedPractitioner) return;
      defaultPractitionerRef.current = formattedPractitioner;
      setMountModal(true);
    },
    onError: () => {
      setMountModal(true);
    },
  });
  const { t } = useTranslation();

  const toWhomItMayConcernText = t('study.studyTable.referringPractitionerModal.toWhomItMayConcern');

  const getCellVariant = () => {
    if (hasEditPermissions) return DatatableEditCellVariant.EXTERNAL;

    return DatatableEditCellVariant.ONLY_READ;
  };

  const getPrimaryText = () => {
    if (referringPractitionerFullName) return referringPractitionerFullName;

    if (modalityPractitionerName) return modalityPractitionerName;

    return toWhomItMayConcernText;
  };

  const handleGetPractitionerInfo = () => {
    if (!referringPractitionerId) {
      defaultPractitionerRef.current = undefined;
      return setMountModal(true);
    }

    // proceed to request default practitioner info data.
    getPractitionerById({
      variables: {
        id: referringPractitionerId,
      },
    });
  };

  const formatListItems = (): Array<QuickEditListItemProps> => {
    return [
      {
        onEditClick: handleGetPractitionerInfo,
        primaryText: getPrimaryText(),
        locale: currentLanguage as Locale,
        cellVariant: getCellVariant(),
        size: DataTableEditCellSizes.xs,
      },
    ];
  };

  return (
    <>
      <DataTableEditCell listItems={formatListItems()} />
      {mountModal && (
        <PractitionersModal
          onCloseModal={() => setMountModal(false)}
          facilityId={facilityId}
          referringPractitionerId={referringPractitionerId}
          defaultPractitioner={defaultPractitionerRef.current}
          studyId={studyId}
          modalityPractitionerName={modalityPractitionerName}
          hasEditPermissions={hasEditPermissions}
        />
      )}
    </>
  );
};
