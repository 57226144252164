import React, { useEffect, useRef, useState } from 'react';

import { useStudyTableUtils } from '~/hooks';
import { useStudyListStore } from '~/src/store';
import { StudyRow } from '~/components/StudyList/studyList';
import { StudyTable, StudyTableProps } from '~/components/StudyList/StudyTable';

interface WorklistStudyTableProps
  extends Pick<
    StudyTableProps,
    'onTableLoad' | 'hasNextPage' | 'initialLoading' | 'pageSize' | 'hasPreviousPage' | 'sentryRef' | 'rootRef'
  > {
  handleRefresh: () => void;
  loading: boolean;
}

export const WorklistStudyTable = ({
  handleRefresh,
  onTableLoad,
  rootRef,
  sentryRef,
  loading,
  pageSize,
  hasNextPage,
  initialLoading,
  hasPreviousPage,
}: WorklistStudyTableProps) => {
  const timerId = useRef<null | number>(null);
  const { rows } = useStudyTableUtils();
  const [localRows, setLocalRows] = useState<Array<StudyRow>>(rows);
  const totalCount = useStudyListStore((state) => state.worklistTotalCount);

  useEffect(() => {
    if (timerId.current) clearTimeout(timerId.current);
    startTimerToRender(rows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const startTimerToRender = (rowsInternal: Array<StudyRow>) => {
    if (initialLoading) return setLocalRows(rowsInternal);
    timerId.current = window.setTimeout(() => {
      setLocalRows(rowsInternal);
    }, 100);
  };

  return (
    <StudyTable
      data={localRows}
      loading={loading}
      rootRef={rootRef}
      pageSize={pageSize}
      sentryRef={sentryRef}
      totalCount={totalCount}
      hasNextPage={hasNextPage}
      onTableLoad={onTableLoad}
      hasPreviousPage={hasPreviousPage}
      initialLoading={localRows.length === 0}
      onTableNotificationRowClick={handleRefresh}
    />
  );
};
