import React from 'react';
import { useTranslation } from 'react-i18next';
import { SEARCH_EMPTY_STATE_IMAGE_PATH } from '~/constants';
import { EmptyState as EmptyStateComponent } from '@eva-pacs/ui';

export const EmptyState = () => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center justify-items-center mb-16 pt-16">
      <EmptyStateComponent
        className="max-w-md"
        data-testid="empty-study-list"
        title={t('study.studyTable.emptyState.title')}
        description={t('study.studyTable.emptyState.description')}
        imageSrc={SEARCH_EMPTY_STATE_IMAGE_PATH}
      />
    </div>
  );
};
