import React, { useMemo } from 'react';
import cn from 'classnames';
import { VIEWER_PATH } from '@eva-pacs/core';
import { useTranslation } from 'react-i18next';
import { createColumnHelper } from '@tanstack/react-table';
import { PatientGender, StudyStatus, getStudyStatusText } from '@eva-pacs/client';
import { DateAndTimeCell, IndeterminateCheckbox, LongTextCell, PatientCell, TitleDescriptionCell } from '@eva-pacs/ui';

import { useSessionStore } from '~/src/store';
import { studyIsSigned } from '~/utils/appHelpers';
import { useStudyActions } from '~/src/hooks/study/useStudyActions';
import { CAN_DELETE_STUDY, CAN_EDIT_PRIORITY, CAN_EDIT_STUDY, POLICIES } from '~/constants';
import { ScreenPreferences, openWindowsWithScreenPreferences } from '~/utils/screenPreferences';

import { PriorityCell } from './Cells/PriorityCell';
import { StudyOptionMenu } from './StudyOptionMenu';
import { PractitionerCell } from './Cells/PractitionerCell';
import { ReferringPractitionerCell } from './Cells/ReferringPractitionerCell';
import { ReferringPractitioner } from '~/src/types/Study';
import { IconCatalog, SelectOption, TagV2, TagV2Appearance, TagV2Size, TagV2Type } from '@evacenter/eden';
import { PractitionerRow } from '../studyList';

interface Column {
  [key: string]: string | any;
}

export enum Accessor {
  selection = 'selection',
  patient = 'patient',
  modalities = 'modalities',
  dicomDescription = 'dicomDescription',
  facility = 'facility',
  practitionerAssigned = 'practitionerAssigned',
  referringPractitioner = 'referringPractitioner',
  status = 'status',
  urgencyLevel = 'urgencyLevel',
  dicomDateTimeEditable = 'dicomDateTimeEditable',
  createdAt = 'createdAt',
  actionColumn = 'actionColumn',
}

export const Sizes: Record<Accessor, number> = {
  [Accessor.selection]: 50,
  [Accessor.patient]: 250,
  [Accessor.modalities]: 110,
  [Accessor.dicomDescription]: 224,
  [Accessor.facility]: 200,
  [Accessor.practitionerAssigned]: 200,
  [Accessor.referringPractitioner]: 200,
  [Accessor.status]: 145,
  [Accessor.urgencyLevel]: 140,
  [Accessor.dicomDateTimeEditable]: 180,
  [Accessor.createdAt]: 180,
  [Accessor.actionColumn]: 50,
};

export const useStudyTableColumns = () => {
  const { t } = useTranslation();
  const user = useSessionStore((store) => store.user);
  const { performUpdatePriority, performUpdatePractitioner, performUpdateReviewerPractitioner } = useStudyActions();

  const canEditStudy = POLICIES[CAN_EDIT_STUDY](user);
  const canDeleteStudy = POLICIES[CAN_DELETE_STUDY](user);
  const canUpdatePriority = POLICIES[CAN_EDIT_PRIORITY](user);

  const columnHelper = createColumnHelper<Column>();
  const columns = useMemo(
    () => [
      columnHelper.display({
        id: Accessor.selection,
        size: Sizes[Accessor.selection],
        header: ({ table }) => {
          return (
            <IndeterminateCheckbox
              name="select-all"
              className="cursor-pointer"
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          );
        },
        cell: ({ row }) => {
          const isStudyRowSelectable = row.getCanSelect();
          const disabledCheckboxProps = {
            disabled: !isStudyRowSelectable,
            className: cn('cursor-pointer', {
              ['cursor-not-allowed opacity-30']: !isStudyRowSelectable,
            }),
          };
          return (
            <IndeterminateCheckbox
              name="select-row"
              {...disabledCheckboxProps}
              checked={row.getIsSelected()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
            />
          );
        },
      }),
      columnHelper.accessor(Accessor.patient, {
        id: Accessor.patient,
        size: Sizes[Accessor.patient],
        header: t('study.studyTable.patient'),
        cell: ({ getValue }) => {
          const initialValue = getValue();
          const setGender = (gender: PatientGender | null) => {
            if (gender === null) return '';
            if (gender === PatientGender.F) return t('admin.formFields.gender.options.female');
            if (gender === PatientGender.M) return t('admin.formFields.gender.options.male');
            else return t('admin.formFields.gender.options.other');
          };
          const patientLabel = () => {
            if (initialValue.age) return `${initialValue.age}, ${setGender(initialValue.gender as PatientGender)}`;
            return setGender(initialValue.gender as PatientGender);
          };
          const isButton = Boolean(initialValue.screenPreferences);
          const titleOnClick = () => {
            openWindowsWithScreenPreferences(initialValue.screenPreferences as ScreenPreferences, initialValue.id);
          };

          return (
            <PatientCell
              titleExternalLink
              title={initialValue.name}
              patientLabel={patientLabel()}
              description={initialValue.identifierEditable}
              titleOnClick={isButton ? titleOnClick : undefined}
              descriptionPrefix={`${t('study.studyTable.identifier')}: `}
              titleHref={isButton ? undefined : `${VIEWER_PATH}${initialValue.id}`}
            />
          );
        },
      }),
      columnHelper.accessor(Accessor.modalities, {
        id: Accessor.modalities,
        size: Sizes[Accessor.modalities],
        header: t('study.studyTable.modality.header'),
        cell: ({ getValue }) => (
          <>
            <TitleDescriptionCell
              title={getValue().name}
              description={getValue().folio}
              descriptionPrefix={`${t('study.studyTable.modality.prefix')}: `}
            />
          </>
        ),
      }),
      columnHelper.accessor(Accessor.dicomDescription, {
        id: Accessor.dicomDescription,
        size: Sizes[Accessor.dicomDescription],
        header: t('study.studyTable.information'),
        cell: ({ getValue }) => <LongTextCell text={getValue()} maxLines={2} />,
      }),
      columnHelper.accessor(Accessor.facility, {
        id: Accessor.facility,
        size: Sizes[Accessor.facility],
        header: t('general.facility'),
        cell: ({ getValue }) => <LongTextCell text={getValue()} maxLines={2} />,
      }),
      columnHelper.accessor(Accessor.practitionerAssigned, {
        id: Accessor.practitionerAssigned,
        size: Sizes[Accessor.practitionerAssigned],
        header: t('study.studyTable.practitionerAssigned'),
        cell: ({ getValue, row }) => {
          const practitionerAssigned = getValue();
          const reviewerPractitioner = row.original.reviewerPractitioner as PractitionerRow;
          const handleChangePractitioner = (option: SelectOption) =>
            performUpdatePractitioner(practitionerAssigned.studyId, option.value, option.label);

          const handleChangeReviewer = (option: SelectOption) =>
            performUpdateReviewerPractitioner(reviewerPractitioner.studyId, option.value, option.label);

          const study = row.original;
          const reportStatus: StudyStatus | undefined = study?.status;
          const isStudySigned = reportStatus && studyIsSigned(reportStatus);
          const isDoubleSignEnabled = study.reviewRequired;

          return (
            <PractitionerCell
              isStudySigned={isStudySigned}
              practitionerAssignedValue={practitionerAssigned.value ?? ''}
              reportStatus={reportStatus}
              reviewerAssignedValue={reviewerPractitioner.value ?? ''}
              className="w-full"
              fallbackOption={practitionerAssigned.default}
              isDoubleSignEnabled={isDoubleSignEnabled}
              reviewerPractitioner={reviewerPractitioner}
              onChangeAssignedPractitioner={handleChangePractitioner}
              onChangeAssignedReviewer={handleChangeReviewer}
              isDisabled={!canEditStudy}
            />
          );
        },
      }),
      columnHelper.accessor(Accessor.referringPractitioner, {
        id: Accessor.referringPractitioner,
        size: Sizes[Accessor.referringPractitioner],
        header: t('study.studyTable.referringPractitioner'),
        cell: ({ row, getValue }) => {
          const practitioner = getValue() as ReferringPractitioner | null;
          const study = row.original;

          const modalityPractitioner = study.dicomReferringPhysician || study.dicomRequestingPhysician;
          return (
            <ReferringPractitionerCell
              referringPractitionerId={practitioner?.id}
              referringPractitionerFullName={practitioner?.fullName}
              facilityId={study.facilityId}
              hasEditPermissions={canEditStudy}
              studyId={study.actionColumn}
              modalityPractitionerName={modalityPractitioner}
            />
          );
        },
      }),
      columnHelper.accessor(Accessor.status, {
        id: Accessor.status,
        size: Sizes[Accessor.status],
        header: t('study.studyTable.status'),
        cell: ({ getValue }) => {
          const status = getValue();
          const formattedStatusText = getStudyStatusText(t, status);
          if (status === StudyStatus.REVIEW_PENDING) {
            return (
              <TagV2
                startIcon={IconCatalog.warning}
                size={TagV2Size.xs}
                appearance={TagV2Appearance.moderate}
                type={TagV2Type.info}>
                {formattedStatusText}
              </TagV2>
            );
          }

          return formattedStatusText;
        },
      }),
      columnHelper.accessor(Accessor.urgencyLevel, {
        id: Accessor.urgencyLevel,
        size: Sizes[Accessor.urgencyLevel],
        header: t('study.studyTable.priority'),
        cell: ({ getValue }) => {
          const initialValue = getValue();
          const handleItemClick = (newValue) => {
            performUpdatePriority(initialValue.studyId, newValue);
          };

          return (
            <PriorityCell
              onItemClick={handleItemClick}
              isDisabled={!canUpdatePriority}
              defaultValue={initialValue.level}
            />
          );
        },
      }),
      columnHelper.accessor(Accessor.dicomDateTimeEditable, {
        id: Accessor.dicomDateTimeEditable,
        size: Sizes[Accessor.dicomDateTimeEditable],
        header: t('study.studyTable.time.header'),
        cell: ({ getValue, row }) => <DateAndTimeCell date={[getValue()]} timezone={row.original.facilityTimezone} />,
      }),
      columnHelper.accessor(Accessor.createdAt, {
        id: Accessor.createdAt,
        size: Sizes[Accessor.createdAt],
        header: t('study.studyTable.createdAt.header'),
        cell: ({ getValue, row }) => (
          <DateAndTimeCell date={[getValue().time]} timezone={row.original.facilityTimezone} />
        ),
      }),
      columnHelper.display({
        id: Accessor.actionColumn,
        size: Sizes[Accessor.actionColumn],
        cell: ({ row }) => {
          const study = row.original;
          const reportStatus: StudyStatus | undefined = study?.status;
          const isDoubleSignEnabled = study.reviewRequired;
          const showDeleteStudy = reportStatus && !studyIsSigned(reportStatus) && canDeleteStudy;

          return (
            <StudyOptionMenu
              isDoubleSignEnabled={isDoubleSignEnabled}
              studyId={study?.actionColumn}
              studyStatus={reportStatus}
              showDeleteStudy={showDeleteStudy}
              showReport={reportStatus && studyIsSigned(reportStatus)}
            />
          );
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return columns;
};
