import React, { useEffect, useMemo, useRef } from 'react';
import shallow from 'zustand/shallow';
import { Button, ButtonSize, ButtonVariant, IconCatalog } from '@evacenter/eden';
import { useTranslation } from 'react-i18next';
import {
  DownloadIsoStatus,
  DownloadIsoStatusBurn,
  useBurnIsoMutation,
  useCreateDownloadIsoMutation,
  useGetIsoByStudyQuery,
} from '@eva-pacs/client';

import { RETRY_ISO_DOWNLOAD_TIME } from '~/constants';
import { BurnProgressType, useStudyActionsStore } from '../studyActionsStore';
import { useErrorHandler } from '~/utils/appHelpers';
import { StudyRow } from '../studyList';

type BurnRowProps = {
  index: number;
  study: StudyRow;
};

export const TableElement = ({ children }) => <div className="flex justify-start max-w-lg truncate">{children}</div>;

const BurnRow = ({ study, index }: BurnRowProps) => {
  const { t } = useTranslation();
  const [burnerId, setProgress, burnProgress] = useStudyActionsStore(
    (store) => [store.burnerId, store.setProgress, store.burnProgress],
    shallow,
  );
  const { handleError } = useErrorHandler();
  const studyId = study.practitionerAssigned.studyId;

  const intervalReference = useRef<number>();

  const [createDownloadIsoMutation] = useCreateDownloadIsoMutation({
    variables: { input: { study: studyId } },
    onError: (error) => handleError({ logMessage: error }),
  });

  const { data, refetch, loading } = useGetIsoByStudyQuery({
    variables: { studyId },
    fetchPolicy: 'network-only',
    skip: false,
    onCompleted: ({ study }) => {
      if (!study?.downloadsIso) return;
      const isoUrlExist = study?.downloadsIso[0]?.fileUrl?.length;
      if (!isoUrlExist) createDownloadIsoMutation();
    },
  });

  const [burnIsoMutation] = useBurnIsoMutation({
    onCompleted: ({ createBurnIso: { success } }) => {
      if (success) refetch();
      else handleError({ logMessage: t('errors.burn.genericError') });
    },
    onError: (error) => handleError({ logMessage: error }),
  });

  const getProgress = (previousValue: Array<BurnProgressType>, burnStatus: DownloadIsoStatusBurn | null) => {
    const existingIndex = previousValue.findIndex((value) => value.studyId === studyId);
    const isExisting = existingIndex > -1;
    if (isExisting) {
      previousValue.splice(existingIndex, 1, { status: burnStatus, studyId: studyId, index }).reverse();
      return JSON.parse(JSON.stringify(previousValue));
    }
    return [...previousValue, { status: burnStatus, studyId: studyId, index }];
  };

  const handleBurnCd = () => {
    burnIsoMutation({
      variables: { input: { study: studyId, burner: burnerId } },
    });
  };

  useEffect(() => {
    const datainfo = data?.study?.downloadsIso[0];
    if (datainfo?.statusBurn === DownloadIsoStatusBurn.PENDING) {
      intervalReference.current = setInterval(refetch, RETRY_ISO_DOWNLOAD_TIME);
    }
    if (intervalReference.current && datainfo?.statusBurn !== DownloadIsoStatusBurn.PENDING)
      clearInterval(intervalReference.current);

    return () => clearInterval(intervalReference?.current);
  }, [data?.study?.downloadsIso, refetch]);

  const studyStatus = useMemo(() => {
    const datainfo = data?.study?.downloadsIso[0];
    const burnStatus = datainfo?.statusBurn || null;
    if (datainfo) setProgress(getProgress([...burnProgress], burnStatus));
    return {
      processStatus: datainfo?.status || DownloadIsoStatus.COMPLETED,
      burnStatus,
      id: datainfo?.id || '',
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.study?.downloadsIso]);

  const burnStatusElement = useMemo(() => {
    if (loading) return t('general.loading');

    switch (studyStatus.burnStatus) {
      case DownloadIsoStatusBurn.PENDING:
        return t('general.genericLoadingCaption');
      case DownloadIsoStatusBurn.COMPLETED:
        return t('general.complete');
      case DownloadIsoStatusBurn.FAILED:
        return t('general.failed');
      default:
        return t('general.noStarted');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyStatus.burnStatus, loading]);

  const burnAction = useMemo(() => {
    const { burnStatus } = studyStatus;
    const isBurnPending = burnStatus === DownloadIsoStatusBurn.PENDING;
    const isBurnCompleted = burnStatus === DownloadIsoStatusBurn.COMPLETED;
    const burnActionText = isBurnCompleted
      ? `${t('study.dropdownMenu.burn')} ${t('general.again').toLowerCase()}`
      : `${t('study.dropdownMenu.burn')} CD`;

    const handleBurnActionClick = () => {
      if (isBurnPending) return;
      handleBurnCd();
    };

    return (
      <Button
        size={ButtonSize.xs}
        variant={ButtonVariant.ghost}
        isLoading={loading}
        endIcon={IconCatalog.burn}
        aria-label={`burn-button-${index}`}
        isDisabled={isBurnPending}
        onClick={handleBurnActionClick}>
        {burnActionText}
      </Button>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studyStatus.burnStatus, loading]);

  return (
    <tr key={studyId}>
      <td>
        <TableElement>
          {/* eslint-disable-next-line i18next/no-literal-string */}
          {study.patient.name} - {study.patient.identifier}
        </TableElement>
      </td>
      <td>
        <TableElement>{burnStatusElement}</TableElement>
      </td>
      <td>
        <TableElement>{burnAction}</TableElement>
      </td>
    </tr>
  );
};

export default BurnRow;
