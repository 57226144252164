import { useCreateStudyNoDicomMutation } from '@eva-pacs/client';

export const useCreateStudyNoDicom = () => {
  const [createStudyNoDicomRequest] = useCreateStudyNoDicomMutation();

  const createStudyNoDicom = async (patientId: string): Promise<string | null> => {
    try {
      const response = await createStudyNoDicomRequest({
        variables: {
          input: {
            patient: patientId,
          },
        },
      });
      const studyId = response.data?.createStudyNoDicom.study?.id;
      if (!response.data?.createStudyNoDicom.success || !studyId) throw new Error();
      return studyId;
    } catch {
      return null;
    }
  };

  return { createStudyNoDicom };
};
