import create, { SetState } from 'zustand';

import { DownloadIsoStatusBurn } from '@eva-pacs/client';

import type { StudyRow } from './studyList';
import { immer } from '~/src/store';

export type BurnProgressType = { status: DownloadIsoStatusBurn | null; studyId: string; index: number };

interface StudyActionsStore {
  /**
   * Current selected studies from the study list
   */
  selectedStudies: Array<StudyRow>;
  /**
   * Current selected burner
   */
  burnerId: string;
  /**
   * Set current burner
   */
  setBurnerId: (studies: string) => void;
  /**
   * Current burner progress
   */
  burnProgress: Array<BurnProgressType>;
  /**
   * Update burner progress
   */
  setProgress: (burnProgress: Array<BurnProgressType>) => void;
  /**
   * Set selectedStudies on state
   */
  setSelectedStudies: (studies: Array<StudyRow>) => void;
  /**
   * Check if the user has joined studies
   * @returns boolean
   */
  joinedStudies: boolean;
  /**
   * Set joinedStudies on state
   */
  setJoinedStudies: (joinedStudies: boolean) => void;
}

const studyActionsStore = (set: SetState<StudyActionsStore>) => ({
  burnerId: '',
  burnProgress: [],
  selectedStudies: [],
  joinedStudies: false,
  setProgress: (burnProgress: Array<BurnProgressType>) => {
    return set((state) => {
      state.burnProgress = burnProgress;
    });
  },
  setBurnerId: (burnerId: string) => {
    return set((state) => {
      state.burnerId = burnerId;
    });
  },
  setSelectedStudies: (studies: Array<StudyRow>) => {
    return set((state) => {
      state.selectedStudies = studies;
    });
  },
  setJoinedStudies: (joinedStudies: boolean) => {
    return set((state) => {
      state.joinedStudies = joinedStudies;
    });
  },
});

export const useStudyActionsStore = create<StudyActionsStore>(immer(studyActionsStore));
