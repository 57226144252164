import React, { useEffect, useState } from 'react';
import cornerstone from 'cornerstone-core';
import { useTranslation } from 'react-i18next';

import {
  useUpdateSerieMutation,
  useGetSerieCropAreaByIdLazyQuery,
  SeriesType,
  SeriesInputType,
} from '@eva-pacs/client';
import { Modals, useModal, FileType } from '@eva-pacs/core';

import { useSetterTools } from '../tools/useSetterTools';
import { CropType, useToolbarStore, useViewportStore } from '../../../store';
import { ToolbarOption } from '../../../constants/toolbarOption';
import { ConfirmCropModalContent } from '../ConfirmCropModalContent';

type cropDataType = Pick<
  SeriesType,
  'id' | 'dicomBottomRightX' | 'dicomBottomRightY' | 'dicomTopLeftX' | 'dicomTopLeftY'
>;

export const useCropDicomSeries = () => {
  const { t } = useTranslation();
  const { setActiveTool } = useSetterTools();
  const { activeIndex, viewportData } = useViewportStore();
  const { cropInfo, setCropInfo } = useToolbarStore();
  const { setCurrentModal } = useModal();
  const [savedCropData, setSavedCropData] = useState<Record<string, cropDataType>>({});

  const { currentSerieId, dom: enabledElement, displayType } = viewportData[activeIndex];

  const isDicom = displayType === FileType.DICOM;

  const [getSerieCropData, { refetch: refetchCropData, data }] = useGetSerieCropAreaByIdLazyQuery();

  const [sendUpdatedCropData] = useUpdateSerieMutation({
    onCompleted: () => {
      if (refetchCropData) refetchCropData({ serieId: currentSerieId });
    },
  });

  const cutViewport = ({ selectedElement = enabledElement, cropData = savedCropData[currentSerieId] }) => {
    if (!selectedElement) return;

    const viewport = cornerstone.getViewport(selectedElement);
    if (!viewport || !cropData?.dicomBottomRightX) return;

    viewport.displayedArea.tlhc.x = cropData.dicomTopLeftX as string;
    viewport.displayedArea.tlhc.y = cropData.dicomTopLeftY as string;
    viewport.displayedArea.brhc.x = cropData.dicomBottomRightX as string;
    viewport.displayedArea.brhc.y = cropData.dicomBottomRightY as string;

    cornerstone.setViewport(selectedElement, viewport);
  };

  const getSavedCropData = (serieId = currentSerieId) => {
    if (!Object.values(savedCropData).length) return;
    return savedCropData[serieId];
  };

  const saveCropData = (cropInfo: CropType) => {
    setCropInfo(cropInfo);
    openConfirmCropModal(cropInfo);
  };

  const resetCropData = () => {
    if (!cropInfo?.serieId?.length) return;
    setCropInfo(undefined);
    if (!enabledElement) return;
    setActiveTool(ToolbarOption.CLEAN, { activeElement: enabledElement });
  };

  const handleDissmissModal = () => {
    setCurrentModal(null);
    if (!enabledElement) return;
    setActiveTool(ToolbarOption.CLEAN, { activeElement: enabledElement });
  };

  const handleSendData = (cropData = cropInfo) => {
    if (!cropData) return;
    sendUpdatedCropData({
      variables: {
        id: cropData.serieId,
        input: cropInputDTO(cropData.areaData),
      },
    }).then(() => window.location.reload());
  };

  const deleteCutInformation = () => {
    setCropInfo(undefined);
    sendUpdatedCropData({
      variables: {
        id: currentSerieId,
        input: {
          dicomTopLeftX: null,
          dicomTopLeftY: null,
          dicomBottomRightX: null,
          dicomBottomRightY: null,
        },
      },
    }).then(() => {
      if (!enabledElement) return;
      cornerstone.reset(enabledElement);
    });
  };

  const openConfirmCropModal = (cropData = cropInfo) => {
    setCurrentModal({
      name: Modals.GenericModal,
      props: {
        title: t('viewer.saveCutImage'),
        className: 'max-w-xl text-light-gray-600',
        isMaxWidthCustom: true,
        onClose: () => handleDissmissModal(),
        children: (
          <ConfirmCropModalContent
            handleSendData={() => handleSendData(cropData)}
            setCurrentModal={setCurrentModal}
            handleDissmissModal={handleDissmissModal}
          />
        ),
      },
    });
  };

  // Get crop data only when the serie id exist
  useEffect(() => {
    if (currentSerieId && isDicom) getSerieCropData({ variables: { serieId: currentSerieId } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSerieId, activeIndex]);

  // Save crop data when is available
  useEffect(() => {
    if (!data) return;
    setSavedCropData((prev) => ({
      ...prev,
      [data.serie?.id]: data.serie!,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    openConfirmCropModal,
    saveCropData,
    savedCropData,
    getSavedCropData,
    cutViewport,
    deleteCutInformation,
    resetCropData,
  };
};

const cropInputDTO = (
  cropData: CropType['areaData'],
): Pick<SeriesInputType, 'dicomTopLeftX' | 'dicomTopLeftY' | 'dicomBottomRightX' | 'dicomBottomRightY'> => {
  let dicomTopLeftX = cropData.topLeftX;
  let dicomTopLeftY = cropData.topLeftY;
  let dicomBottomRightX = cropData.bottomRightX;
  let dicomBottomRightY = cropData.bottomRightY;
  if (dicomTopLeftX > dicomBottomRightX) [dicomTopLeftX, dicomBottomRightX] = [dicomBottomRightX, dicomTopLeftX];
  if (dicomTopLeftY > dicomBottomRightY) [dicomTopLeftY, dicomBottomRightY] = [dicomBottomRightY, dicomTopLeftY];
  return {
    dicomTopLeftX: dicomTopLeftX.toString(),
    dicomTopLeftY: dicomTopLeftY.toString(),
    dicomBottomRightX: dicomBottomRightX.toString(),
    dicomBottomRightY: dicomBottomRightY.toString(),
  };
};
