import React from 'react';
import { useReportAndPatientFromStudyQuery } from '@eva-pacs/client';
import { useModal } from '@eva-pacs/core';
import { PreviewPDF, Spinner } from '@eva-pacs/ui';
import { redirectToProductURL } from '~/utils/redirectToProductURL';
import { EVA_PRODUCT } from '~/constants';

export interface PreviewPDFWrapperProps {
  /**
   * Study id
   */
  studyId: string;

  /**
   * Specify an optional className to be added to the component
   */
  className?: string;
}

/**
 * Use this component as logic for send report from preview pdf
 * @author Ricardo Alfonso Aguirre Nava<ricardo.a.nava@gmail.com>
 * Created at 2021-09-20
 */
export const PreviewPDFWrapper: React.FC<PreviewPDFWrapperProps> = ({ studyId }) => {
  const { setCurrentModal } = useModal();

  const { ...reportFromStudy } = useReportAndPatientFromStudyQuery({
    variables: {
      id: studyId,
    },
    fetchPolicy: 'no-cache',
  });

  const study = reportFromStudy.data?.study;
  const firstReport = study?.reports[0];
  const reportUrl = firstReport?.pdfUrl;
  const reportLetterheadUrl = firstReport?.pdfLetterheadUrl;
  const showPrintButton = firstReport?.isExternalPdf !== true;

  const goToSendView = () => {
    setCurrentModal(null);
    redirectToProductURL(EVA_PRODUCT.PACS, `report-send/${study?.id}`);
  };

  if (reportFromStudy.loading) return <Spinner />;

  return (
    <div>
      <PreviewPDF
        pdfFile={reportUrl as string}
        onPrimaryAction={goToSendView}
        showPrintButton={showPrintButton}
        pdfLetterheadFile={reportLetterheadUrl as string}
      />
    </div>
  );
};
