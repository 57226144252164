import React from 'react';
import { useModal, ConfirmationModal } from '@evacenter/eden';
import { useTranslation } from 'react-i18next';

interface useDisableDoubleSignatureOnSignedStudyModalProps {
  onConfirm?: () => void;
}

export const useDisableDoubleSignatureOnSignedStudyModal = ({
  onConfirm,
}: useDisableDoubleSignatureOnSignedStudyModalProps) => {
  const { modalNode, openModal } = useModal();
  const { t } = useTranslation();

  const handleConfirmation = async () => {
    const confirmation = await openModal<boolean>((close) => (
      <ConfirmationModal
        header={{ hasCloseBtn: true, title: t('study.studyTable.tableActions.signedStudyAlertTitle') }}
        description={t('study.doubleSignature.disableDoubleSignatureOnSignedStudyDescription')}
        cancelBtnLabel={t('admin.formActions.cancel')}
        confirmBtnLabel={t('general.actions.accept')}
        onClose={() => close(false)}
        onConfirm={() => {
          if (onConfirm) onConfirm();
          close(false);
        }}
      />
    ));

    return confirmation;
  };

  return {
    modalNode,
    handleConfirmation,
  };
};
