import React from 'react';
import { Button, ButtonSize, ButtonVariant, IconCatalog } from '@evacenter/eden';

interface GoToButtonProps {
  label: string;
  goToTop?: boolean;
  disabled?: boolean;
  onClick: () => void;
}

export const GoToButton = ({ label, goToTop, disabled, onClick }: GoToButtonProps) => {
  return (
    <Button
      onClick={onClick}
      size={ButtonSize.xs}
      isDisabled={disabled}
      variant={ButtonVariant.tertiary}
      endIcon={goToTop ? IconCatalog.arrowUp : IconCatalog.arrowDown}>
      {label}
    </Button>
  );
};
