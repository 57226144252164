import React, { useState } from 'react';
import cn from 'classnames';
import NativeSelect from '../../../NativeSelect';
/**
 * Select Cell (Table)
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-07-27
 */
export var SelectCell = function (_a) {
    var name = _a.name, options = _a.options, placeholder = _a.placeholder, defaultValue = _a.defaultValue, _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, className = _a.className, onChange = _a.onChange;
    var cellClass = cn(className);
    var _c = useState(defaultValue), value = _c[0], setValue = _c[1];
    var handleChange = function (event) {
        if (onChange)
            onChange(event);
        setValue(event.target.value);
    };
    return (React.createElement(NativeSelect, { name: name, className: cellClass, placeholder: placeholder, options: options, value: value, onChange: handleChange, isDisabled: isDisabled }));
};
