import {
  useCreateUserPreferenceMutation,
  useDeleteAllUserPreferencesMutation,
  useUpdateUserPreferenceMutation,
} from '@eva-pacs/client';
import { Family } from '@eva-pacs/core';
import { useSessionStore } from '~/src/store';
import { useErrorHandler } from '~/utils/appHelpers';

interface UseFiltersOptions {
  /**
   * Callback to trigger when a creation/updation/deletion of a filter happens
   */
  onSuccess?: () => void;
}

/**
 * Use `useFilters` to perform common actions over filters, like create/update/delete
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2023-01-31
 */
export const useFilters = (options?: UseFiltersOptions) => {
  const { onSuccess } = options ?? {};
  const { handleError } = useErrorHandler();
  const user = useSessionStore((state) => state.user);

  const [createUserPreferenceMutation] = useCreateUserPreferenceMutation({
    onCompleted: ({ createUserPreference: { success } }) => {
      if (success) {
        if (onSuccess) onSuccess();
      }
    },
    onError: (error) => handleError({ logMessage: error }),
  });

  const [updateUserPreferenceMutation] = useUpdateUserPreferenceMutation({
    onCompleted: ({ updateUserPreference: { success } }) => {
      if (success) {
        if (onSuccess) onSuccess();
      }
    },
    onError: (error) => handleError({ logMessage: error }),
  });

  const [deleteAllUserPreferenceMutation] = useDeleteAllUserPreferencesMutation({
    onCompleted: ({ deleteUserPreferences: { success } }) => {
      if (success) {
        if (onSuccess) onSuccess();
      }
    },
    onError: (error) => handleError({ logMessage: error }),
  });

  const createFilter = async (key: string, value: any) => {
    createUserPreferenceMutation({
      variables: {
        input: {
          user: user?.id,
          family: Family.filter,
          key,
          value: JSON.stringify(value),
        },
      },
    });
  };

  const updateFilter = async (key: string, value: any, filterId: string) => {
    updateUserPreferenceMutation({
      variables: {
        id: filterId,
        input: {
          user: user?.id,
          family: Family.filter,
          key,
          value: JSON.stringify(value),
        },
      },
    });
  };

  const removeAllFilters = async () => {
    deleteAllUserPreferenceMutation({
      variables: {
        input: {
          user: user?.id,
          family: Family.filter,
        },
      },
    });
  };

  return {
    createFilter,
    updateFilter,
    removeAllFilters,
  };
};
