import { PractitionerType, PractitionerStatus as PractitionerStatusClient } from '@eva-pacs/client';
import { PractitionerStatus } from '@eva-tech/graph-driven-ui';
import { ReferringPractitioner } from '~/src/types/Study';

/**
 * Generates an object model to be used as payload for create/update study mutations for a given studyData
 * @author Salvador Gonzalez<salvador.gonzalez@evacenter.com>
 * Created 2023-01-20
 */
export const referringPractitionerDTO = (practitioner: PractitionerType): ReferringPractitioner => {
  return {
    id: practitioner.id,
    fullName: practitioner.fullName || '',
    email: practitioner.user.email || '',
    hasAccess: practitioner.isValidPacsPractitioner,
    phone: practitioner.phone || '',
    status: parsePractitionerStatus(practitioner.status),
  };
};

const parsePractitionerStatus = (status?: PractitionerStatusClient): PractitionerStatus => {
  if (status === PractitionerStatusClient.ACTIVE) return PractitionerStatus.Active;
  if (status === PractitionerStatusClient.PENDING) return PractitionerStatus.Pending;
  if (status === PractitionerStatusClient.SUSPENDED) return PractitionerStatus.Suspended;
  return PractitionerStatus.Suspended;
};
