import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonSize, ButtonVariant, IconCatalog } from '@evacenter/eden';
import { ADMIN_INVITE_PATH, ADMIN_PATH, FOUND_SERIES_PATH, UPLOAD_STUDY_PATH, useRouter } from '@eva-pacs/core';

import { useSessionStore } from '~/src/store';
import { CAN_UPLOAD_STUDY, POLICIES } from '~/constants';
import { CreateStudyButton } from '~/components/CreateStudy/CreateStudy';

import useBurnIso from '../BurnIso/useBurnISO';
import { useJoinStudies } from '../JoinStudies/useJoinStudies';

/**
 * Use this component in order to display a set of actions at the top of the study table
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2022-05-06
 */
export const StudyListActions = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    location: { pathname },
  } = useRouter();
  const user = useSessionStore((state) => state.user);
  const { openJoinStudiesModal, canJoinStudies, hasPermisionToJoin } = useJoinStudies();
  const { canBurnDiscs, openBurnMultipleModal, canActiveMultipleBurn } = useBurnIso();
  /**
   * Verify authorization policies
   */
  const canUploadstudy = POLICIES[CAN_UPLOAD_STUDY](user);

  return (
    <div className="e-flex e-justify-end e-ml-auto mb-2 lg:mb-0">
      <div className="e-flex e-items-center e-flex-wrap lg:e-space-x-4 gap-x-6 gap-y-2 lg:gap-x-0 lg:gap-y-0">
        {canBurnDiscs && (
          <Button
            className="e-flex-grow lg:e-flex-grow-0"
            size={ButtonSize.sm}
            endIcon={IconCatalog.burn}
            data-testid="burn-iso-button"
            onClick={openBurnMultipleModal}
            variant={ButtonVariant.secondary}
            isDisabled={!canActiveMultipleBurn}>
            {/* eslint-disable-next-line i18next/no-literal-string */}
            {t('study.dropdownMenu.burn')} CDs
          </Button>
        )}
        {hasPermisionToJoin && (
          <Button
            className="e-flex-grow lg:e-flex-grow-0"
            size={ButtonSize.sm}
            endIcon={IconCatalog.join}
            isDisabled={!canJoinStudies}
            onClick={openJoinStudiesModal}
            data-testid="join-study-button"
            variant={ButtonVariant.secondary}>
            {t('study.join.joinStudies')}
          </Button>
        )}
        {![UPLOAD_STUDY_PATH, ADMIN_PATH, ADMIN_INVITE_PATH, FOUND_SERIES_PATH].includes(pathname as string) &&
          canUploadstudy && (
            <>
              {/* <div className="e-w-px e-h-8 e-bg-neutral-600" /> */}
              <CreateStudyButton />
            </>
          )}
      </div>
    </div>
  );
};
