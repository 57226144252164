import React, { ChangeEvent, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormField, NativeSelect, NativeSelectOption } from '@eva-pacs/ui';
import { OrganizationSpecificFieldRelatedModelType, useOrganizationSpecificFieldsQuery } from '@eva-pacs/client';
import { OrganizationSpecificFilterAPIKeys } from '~/src/constants/organizationSpecificFields';
import { StudyFilterFields, StudyFilterName } from '../';

interface StudyFilterOrganizationSpecificFieldsProps {
  /**
   * Filters to apply to the query
   */
  filters: StudyFilterFields;
}

export const StudyFilterOrganizationSpecificFields = ({ filters }: StudyFilterOrganizationSpecificFieldsProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<StudyFilterFields>();
  const [rightPossessionList, setRightPossessionList] = useState<NativeSelectOption[]>([]);
  const { loading: organizationSpecificFieldsLoading } = useOrganizationSpecificFieldsQuery({
    variables: {
      relatedModelType: OrganizationSpecificFieldRelatedModelType.PATIENT,
    },
    onCompleted: (data) => {
      const organizationSpecificFields = data?.organizationSpecificFields?.results ?? [];

      const tmpRightPossessions = organizationSpecificFields
        ?.find((specificField) => specificField?.key === OrganizationSpecificFilterAPIKeys.rightPossession)
        ?.organizationSpecificFieldOptions?.filter((specificFieldOption) => Boolean(specificFieldOption?.show))
        ?.map((rightPossession) => {
          return {
            value: rightPossession?.id,
            label: rightPossession?.name,
          };
        });

      if (tmpRightPossessions) setRightPossessionList(tmpRightPossessions as NativeSelectOption[]);
    },
  });

  // TODO: Import this method from a common helper file
  // https://eva-pacs.atlassian.net/browse/EVA-3064
  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>, field, options: Array<NativeSelectOption>) => {
    const selectedOption = options.find((option) => option.value === event.target.value);
    field.onChange(selectedOption);
  };

  return (
    //  RIGHT POSSESSION
    <FormField
      name={StudyFilterName.rightPossession}
      defaultValue={filters?.rightPossession || { label: '', value: '' }}
      label={t('Derechohabiencia')}
      control={control}
      render={({ field }) => {
        const { value } = field;
        return (
          <NativeSelect
            placeholder={t('general.select')}
            options={rightPossessionList}
            name={field.name}
            value={value?.value}
            isDisabled={organizationSpecificFieldsLoading}
            onChange={(event) => handleSelectChange(event, field, rightPossessionList)}
          />
        );
      }}
    />
  );
};
