import { AppliedFilterOption } from '~/components/StudyList/AppliedFilters';
import { NativeSelectOption } from '@eva-pacs/ui';
import { useFilters } from './useFilters';
import { useUserFilters } from './useUserFilters';
import { useFeatureFlagsStore } from '~/src/store/flagsStore';
import { FeatureFlags } from '~/src/constants/featureFlags';
import { OrganizationSpecificFilterAPIKeys } from '~/src/constants/organizationSpecificFields';
import { StudyFilterFields, StudyFilterName } from '~/components/StudyList/StudyFilterForm';

interface UseOrganizationSpecificFieldsFiltersOptions {
  onSuccess?: () => void;
}

/**
 * Use `useOrganizationSpecificFieldsFilter` to perform actions over organization specific filters
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2023-01-31
 */
export const useOrganizationSpecificFieldsFilter = (options?: UseOrganizationSpecificFieldsFiltersOptions) => {
  const { onSuccess } = options ?? {};
  const { hasFlag } = useFeatureFlagsStore();
  const { getUserFilterByKey } = useUserFilters();
  const { updateFilter, createFilter } = useFilters({
    onSuccess,
  });

  const isOrganizationSpecificFieldsEnabled = hasFlag(FeatureFlags.EnableOrganizationSpecificFields);

  const removeOrganizationSpecificFilter = (filter: AppliedFilterOption) => {
    // Get the organizationSpecificFields user preference value
    const organizationSpecificFieldsPreferences = getUserFilterByKey(
      OrganizationSpecificFilterAPIKeys.organizationSpecificFields,
    );

    const organizationSpecificFilters = JSON.parse((organizationSpecificFieldsPreferences?.value ?? `{}`) as string);

    if (!Object.keys(organizationSpecificFilters).length) return;

    // Remove the filter key from the organizationSpecificFields object
    delete organizationSpecificFilters[OrganizationSpecificFilterAPIKeys[filter?.key]];

    updateFilter(
      OrganizationSpecificFilterAPIKeys.organizationSpecificFields,
      organizationSpecificFilters,
      organizationSpecificFieldsPreferences?.id,
    );
  };

  const processOrganizationSpecificFilters = (organizationSpecificFields) => {
    if (!organizationSpecificFields || !isOrganizationSpecificFieldsEnabled) return {};

    const organizationSpecificFilters = {
      [StudyFilterName.rightPossession]: organizationSpecificFields?.[
        OrganizationSpecificFilterAPIKeys.rightPossession
      ] as NativeSelectOption,
    };

    const cleanOrganizationSpecificFilters = Object.entries(organizationSpecificFilters).filter(([_, value]) =>
      Boolean(value),
    );

    if (!cleanOrganizationSpecificFilters?.length) return {};

    return Object.fromEntries(cleanOrganizationSpecificFilters);
  };

  const saveOrganizationSpecificFilters = (
    organizationSpecificFilterKeys: Array<string>,
    fields: StudyFilterFields,
  ) => {
    // Organization specific filters
    if (!organizationSpecificFilterKeys?.length) return;

    // Recover organizationSpecificFields filters and its values
    const organizationSpecificFieldsValue = organizationSpecificFilterKeys.reduce(
      (prevFilterKeys, filterKey) => ({
        ...prevFilterKeys,
        [OrganizationSpecificFilterAPIKeys[filterKey as StudyFilterName]]: fields[filterKey],
      }),
      {},
    );

    // const organizationSpecificFilterApiKey = OrderFilterAPIKeys.organizationSpecificFields;
    // Get the organizationSpecificFields value from the user preference filters
    const organizationSpecificFilterToSave = getUserFilterByKey(
      OrganizationSpecificFilterAPIKeys.organizationSpecificFields,
    );

    if (organizationSpecificFilterToSave)
      return updateFilter(
        OrganizationSpecificFilterAPIKeys.organizationSpecificFields,
        organizationSpecificFieldsValue,
        organizationSpecificFilterToSave.id,
      );

    createFilter(OrganizationSpecificFilterAPIKeys.organizationSpecificFields, organizationSpecificFieldsValue);
  };

  return {
    processOrganizationSpecificFilters,
    removeOrganizationSpecificFilter,
    saveOrganizationSpecificFilters,
  };
};
