import { useEffect, useState } from 'react';
import {
  UserPreferenceFamily,
  useUserPreferencesQuery,
  useUpdateUserPreferenceMutation,
  useCreateUserPreferenceMutation,
} from '@eva-pacs/client';

import { ListType } from '~/src/types/StudyList';
import { useErrorHandler } from '~/utils/appHelpers';
import { useSessionStore, useStudyListStore } from '~/src/store';

interface UseSwitchListTypeOptions {
  onSuccess?: () => void;
}

const key = 'listType';

/**
 * @name useSwitchListType
 * @description This hook is used to switch between the list types (Infinite Scroll and Pagination) in the StudyList component.
 */
export const useSwitchListType = ({ onSuccess }: UseSwitchListTypeOptions) => {
  const { handleError } = useErrorHandler();
  const user = useSessionStore((state) => state.user);
  const [isReady, setIsReady] = useState<boolean>(false);
  const setListType = useStudyListStore((state) => state.setListType);
  const [preferenceId, setPreferenceId] = useState<string | null>(null);

  const { refetch: getListType } = useUserPreferencesQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: { userId: user?.id, family: UserPreferenceFamily.STUDY_LIST },
    onCompleted: ({ userPreferences }) => {
      if (userPreferences) {
        const listTypePreference = userPreferences.results?.find((preference) => preference?.key === key);
        if (listTypePreference) {
          setPreferenceId(listTypePreference?.id);
          setListType(listTypePreference?.value as ListType);
        }
        setIsReady(true);
      }
    },
  });

  const [createUserPreferenceMutation] = useCreateUserPreferenceMutation({
    onCompleted: () => getListType(),
  });

  const [updateUserPreferenceMutation] = useUpdateUserPreferenceMutation({
    onCompleted: () => {
      if (onSuccess) onSuccess();
    },
    onError: (error) => handleError({ logMessage: error }),
  });

  const handleSwitchListType = (type: ListType) => {
    setListType(type);
    updateUserPreferenceMutation({
      variables: {
        id: preferenceId,
        input: {
          key,
          value: type,
          family: UserPreferenceFamily.STUDY_LIST,
        },
      },
    });
  };

  useEffect(() => {
    if (isReady && !preferenceId) {
      createUserPreferenceMutation({
        variables: {
          input: {
            key,
            user: user?.id,
            value: ListType.INFINITE_SCROLL,
            family: UserPreferenceFamily.STUDY_LIST,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, preferenceId]);

  return { handleSwitchListType };
};
