import React, { memo, useEffect } from 'react';
import shallow from 'zustand/shallow';
import { useTranslation } from 'react-i18next';
import { PractitionerGender } from '@eva-pacs/client';
import { Button, ButtonVariant, ButtonSize, IconCatalog } from '@evacenter/eden';

import { useInfiniteScroll } from '~/hooks';
import { useLanguage } from '~/src/hooks/useLanguage';
import { Footer } from '~/components/StudyList/Footer';
import { FeatureFlags } from '~/src/constants/featureFlags';
import { useFeatureFlagsStore } from '~/src/store/flagsStore';
import { useSessionStore, useStudyListStore } from '~/src/store';
import { useStudyListTabs } from '~/src/hooks/study/useStudyListTabs';
import { useStudyWorklist } from '~/src/hooks/worklist/useStudyWorklist';
import { useSwitchListType } from '~/src/hooks/studyList/useSwitchListType';
import { useStudyActionsStore } from '~/components/StudyList/studyActionsStore';
import { useJoinStudies } from '~/components/StudyList/JoinStudies/useJoinStudies';
import { NotificationType, useNotificationsStore } from '~/src/store/notifications-store';

import { WorklistTabNames } from './WorklistTabs';
import { WorklistEmptyState } from './WorklistEmptyState';
import { WorklistStudyTable } from './WorklistStudyTable/WorklistStudyTable';

/**
 * This is the section of the worklist for users. It only contains flow for the worklist and not the
 * main study list. It contains a cool empty video with animations for when the worklist is completed.
 * @author Salvador Gonzalez<salvador.gonzalez@edenmed.com>
 * Created at 2024-02-29
 */
const WorklistSection = () => {
  const { t } = useTranslation();

  const { currentLanguage } = useLanguage();
  const clearNotifications = useNotificationsStore((state) => state.clearNotifications);
  const hasFlag = useFeatureFlagsStore((state) => state.hasFlag);
  const isChatAvailable = hasFlag(FeatureFlags.CustomerSupportChat);
  const { activeTab } = useStudyListTabs();
  const [worklistTotalCount, tableSortBy, setTableSortBy, page] = useStudyListStore(
    (state) => [state.worklistTotalCount, state.tableSortBy, state.setTableSortBy, state.currentPage],
    shallow,
  );
  const isEmptyWorklist = worklistTotalCount === 0;

  const [joinedStudies, setJoinedStudies] = useStudyActionsStore(
    (state) => [state.joinedStudies, state.setJoinedStudies],
    shallow,
  );
  const { sentryRef, isVisible, rootRef } = useInfiniteScroll({});
  const parseSortBy = (sortBy) => {
    if (!sortBy) return '';
    // eslint-disable-next-line i18next/no-literal-string
    return sortBy.length !== 0 ? `${sortBy[0].id},${sortBy[0].desc ? 'desc' : 'asc'}` : '';
  };

  const handleTableLoad = (sortBy) => {
    const ordering = parseSortBy(sortBy);
    setTableSortBy(ordering);
    handleReset();
  };

  const { handleSwitchListType } = useSwitchListType({ onSuccess: () => handleRefresh() });

  const {
    hasNextPage,
    initialLoading,
    hasPreviousPage,
    reloadData,
    handleReset,
    loading,
    handleSetCurrentPage,
    pageSize,
  } = useStudyWorklist({
    isVisible,
    tableSortBy,
  });

  const handleRefresh = () => {
    reloadData();
  };

  useEffect(() => {
    if (joinedStudies) {
      handleRefresh();
      setJoinedStudies(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joinedStudies]);

  const handlePageChange = (page: number, scrollToPage = false) => {
    handleSetCurrentPage(page, scrollToPage);
    clearNotifications(NotificationType.StudyListUpdates);
  };

  const getCurrentAccount = useSessionStore((store) => store.getCurrentAccount);
  const { openJoinStudiesModal, canJoinStudies, hasPermisionToJoin } = useJoinStudies();

  useEffect(() => {
    if (!isEmptyWorklist) return;
    // eslint-disable-next-line i18next/no-literal-string
    document.body.style.backgroundColor = 'transparent';
    const element = document.getElementById('root');
    if (!element) return;
    // eslint-disable-next-line i18next/no-literal-string
    element.style.backgroundColor = 'transparent';
  }, [isEmptyWorklist]);

  const getDoctorGenderPrefixText = () => {
    // For english, we don't apply prefix by gender.
    if (currentLanguage === 'en') return t('study.worklist.doctorPrefixMale');

    const gender = getCurrentAccount()?.userInfo.gender;

    if (!gender) return t('study.worklist.doctorPrefixMale');

    if (gender === PractitionerGender.MALE) return t('study.worklist.doctorPrefixMale');

    // If is not male, return the female.
    return t('study.worklist.doctorPrefixFemale');
  };

  return (
    <>
      {!isEmptyWorklist && (
        <div className="bottom-0 left-0 right-0 top-36 flex flex-col pt-6 px-2 lg:fixed lg:pb-0 lg:px-8">
          <div className="e-mb-8 e-flex e-justify-between e-items-end">
            <div className="e-flex e-flex-col">
              <h2 className="e-text-lg text-bold e-text-base-white font-semi-bold">
                {getDoctorGenderPrefixText()}{' '}
                {t('study.worklist.welcomeTitleLegend', { doctorLastName: getCurrentAccount()?.userInfo.lastName })}
              </h2>
              <p className="e-text-neutral-300 e-text-sm e-mt-2">{t('study.worklist.welcomeSubtitleLegend')}</p>
            </div>
            {hasPermisionToJoin && (
              <div>
                <Button
                  variant={ButtonVariant.secondary}
                  size={ButtonSize.sm}
                  endIcon={IconCatalog.join}
                  isDisabled={!canJoinStudies}
                  disabled={!canJoinStudies}
                  onClick={openJoinStudiesModal}>
                  {t('study.join.joinStudies')}
                </Button>
              </div>
            )}
          </div>
          <WorklistStudyTable
            loading={loading}
            rootRef={rootRef}
            pageSize={pageSize}
            sentryRef={sentryRef}
            hasNextPage={hasNextPage}
            handleRefresh={handleRefresh}
            onTableLoad={handleTableLoad}
            initialLoading={initialLoading}
            hasPreviousPage={hasPreviousPage}
          />
          <Footer
            currentPage={page}
            pageSize={pageSize}
            isEmptyFilterFields={true}
            totalCount={worklistTotalCount}
            isChatAvailable={isChatAvailable}
            handlePageChange={handlePageChange}
            handleSwitchListType={handleSwitchListType}
          />
        </div>
      )}
      <WorklistEmptyState
        showWorklistEmptyState={isEmptyWorklist && !loading && activeTab === WorklistTabNames.WORKLIST}
        isChatAvailable={isChatAvailable}
      />
    </>
  );
};

export default memo(WorklistSection);
