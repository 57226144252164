import React, { Fragment } from 'react';
import { FrigadeAnnouncement, StepData } from '@frigade/react';
import { Modals, useModal } from '@eva-pacs/core';

const AnnounceBaseClasses =
  'mt-4 flex w-full items-center justify-center text-center whitespace-nowrap transition duration-100 ease-out text-label-sm font-semi-bold h-8 px-4 py-2.5';

const AnnouncementMultiPageBaseClasses =
  'mt-4 flex items-center justify-center text-center whitespace-nowrap transition duration-100 ease-out text-label-sm font-semi-bold h-8 px-8 py-2.5';

// See: https://docs.frigade.com/sdk/styling
/* eslint-disable i18next/no-literal-string */
const classes = {
  image: 'rounded',
  modalClose: 'text-gray-100',
  announcementPagination: {
    // eslint-disable-next-line i18next/no-literal-string
    color: '#575a5d',
  },
  announcementTitle: 'font-medium text-left e-text-xl justify-start mb-6',
  announcementSubtitle: 'text-left e-text-sm justify-start mb-6',
  announcementButton: `${AnnounceBaseClasses} text-basic-white bg-primary-500 hover:bg-primary-600 rounded-4xl`,
  announcementButtonSecondary: `${AnnounceBaseClasses} text-basic-white bg-primary-500 hover:bg-primary-600 rounded-4xl`,
  announcementBackButtonSecondary: `${AnnounceBaseClasses} text-primary-200 bg-primary-900 hover:bg-primary-1000 rounded-4xl`,
  modalContainer: 'rounded-2x max-w-4xl w-3xl z-50 e-bg-neutral-900 text-basic-white rounded-xl',
  announcementContainer: 'w-xl max-w-xl',
  modalBackground: 'bg-gray-500 opacity-80 z-20',
  npsSurveyDismissButton: 'hidden',
};

const classesMultiPage = {
  ...classes,
  ...{
    announcementCTAContainer: {
      // This is needed because the Tailwind version used in Pacs doesn't support !important classes or more specific selectors
      justifyContent: 'flex-end',
      marginTop: '0',
    },
    announcementButton: `${AnnouncementMultiPageBaseClasses} text-basic-white bg-primary-500 hover:bg-primary-600 rounded-4xl`,
    announcementButtonSecondary: `${AnnouncementMultiPageBaseClasses} text-basic-white bg-primary-500 hover:bg-primary-600 rounded-4xl`,
    announcementBackButtonSecondary: `${AnnouncementMultiPageBaseClasses} text-primary-200 bg-primary-900 hover:bg-primary-1000 rounded-4xl`,
  },
};
/* eslint-enable */

const flowId = process.env.REACT_APP_FRIGADE_ANNOUNCEMENT_FLOW_ID;
const flowIdMultiPage = process.env.REACT_APP_FRIGADE_MULTI_PAGE_ANNOUNCEMENT_FLOW_ID;
const flowIdSinglePage = process.env.REACT_APP_FRIGADE_SINGLE_PAGE_ANNOUNCEMENT_FLOW_ID;

export default function Announcements() {
  const { setCurrentModal } = useModal();
  const theme = {
    // eslint-disable-next-line i18next/no-literal-string
    colorPrimary: '#352dde',
  };

  function handleButtonClick(
    step: StepData,
    index?: number,
    cta?: 'primary' | 'secondary' | 'link' | 'back' | 'collapse' | 'expand',
  ) {
    const maybeModalVideoUrl = step?.props?.modalVideoUrl;
    if (maybeModalVideoUrl && cta == 'primary') {
      setCurrentModal({
        name: Modals.GenericModal,
        props: {
          title: '',
          className: 'max-w-7xl',
          isMaxWidthCustom: true,
          children: (
            <div className="flex justify-center">
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video
                className="min-h-[200px] w-full h-auto rounded-md lg:scale-150"
                disablePictureInPicture
                controls
                controlsList="nodownload noplaybackrate"
                src={maybeModalVideoUrl}
              />
            </div>
          ),
        },
      });
    }
    return true;
  }

  return (
    <>
      {flowId && (
        <FrigadeAnnouncement
          flowId={flowId!}
          // eslint-disable-next-line i18next/no-literal-string
          modalPosition="center"
          appearance={{
            styleOverrides: classes,
          }}
          onButtonClick={handleButtonClick}
        />
      )}
      {flowIdSinglePage && (
        <FrigadeAnnouncement
          flowId={flowIdSinglePage!}
          // eslint-disable-next-line i18next/no-literal-string
          modalPosition="center"
          allowBackNavigation
          appearance={{
            styleOverrides: classes,
            theme: theme,
          }}
          onButtonClick={handleButtonClick}
        />
      )}
      {flowIdMultiPage && (
        <FrigadeAnnouncement
          flowId={flowIdMultiPage!}
          // eslint-disable-next-line i18next/no-literal-string
          modalPosition="center"
          allowBackNavigation
          appearance={{
            styleOverrides: classesMultiPage,
            theme: theme,
          }}
          onButtonClick={handleButtonClick}
        />
      )}
    </>
  );
}
