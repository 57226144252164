import { UserPreferenceFamily, useUserPreferencesQuery } from '@eva-pacs/client';
import { useLocalStorage } from '@eva-pacs/core';
import { useEffect, useState } from 'react';
import { useSessionStore } from '~/src/store';
import {
  ScreenPreferences,
  SCREEN_PREFERENCES_MACHINE_ID_LOCAL_STORAGE_DEFAULT,
  SCREEN_PREFERENCES_MACHINE_ID_LOCAL_STORAGE_KEY,
} from '~/utils/screenPreferences';

/**
 * Hook that retrieves the user screen preferences. It returns the screen preferences as a state.
 * @author Salvador Gonzalez<salvador.gonzalez@evacenter.com>
 * Created at 2024-26-02
 */
export const useScreenPreferences = () => {
  const [screenPreferences, setScreenPreferences] = useState<ScreenPreferences>();
  const [screenPreferencesMachineId, setScreenPreferencesMachineId] = useLocalStorage<string | undefined>(
    SCREEN_PREFERENCES_MACHINE_ID_LOCAL_STORAGE_KEY,
    undefined,
  );
  const { user } = useSessionStore();
  const { data: screenPreferencesData } = useUserPreferencesQuery({
    variables: { userId: user?.id, family: UserPreferenceFamily.SCREENS },
  });

  useEffect(() => {
    const userScreenPreferences = screenPreferencesData?.userPreferences?.results.filter((shortcut) => {
      const hasSameMachineId = shortcut?.key === screenPreferencesMachineId;
      return hasSameMachineId;
    });

    if (userScreenPreferences?.length) {
      const screenPreferences: ScreenPreferences = JSON.parse(userScreenPreferences[0]?.value ?? '');
      setScreenPreferences(screenPreferences);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenPreferencesData]);

  useEffect(() => {
    if (!screenPreferencesMachineId) setScreenPreferencesMachineId(SCREEN_PREFERENCES_MACHINE_ID_LOCAL_STORAGE_DEFAULT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenPreferencesMachineId]);

  return {
    screenPreferences,
  };
};
