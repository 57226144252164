import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Icon, IconCatalog, NativeSelectOption } from '@eva-pacs/ui';
import { StudyFilterName } from '~/components/StudyList/StudyFilterForm';

export interface AppliedFilterOption extends NativeSelectOption {
  key: StudyFilterName;
}

export interface AppliedFiltersProps {
  /**
   * Specify an optional className to be added to the component.
   */
  className?: string;

  /**
   * Applied filters
   */
  appliedFilters?: Array<AppliedFilterOption>;

  /**
   * Provide a handler that is called whenever the remove filters button is clicked
   */
  onRemoveFilterBtnClick: (removedFilter: AppliedFilterOption) => void;

  /**
   * Provide a handler that is called whenever the remove filters button is clicked
   */
  onRemoveAllFiltersBtnClick: () => void;

  /**
   * The applied search term
   */
  searchBy?: string | null;
}

/**
 * The header section to show the applied filters
 * @author Sergio Ruiz <sergioruizdavila@gmail.com>
 * Created at 2021-08-12
 */
export const AppliedFilters: React.FC<AppliedFiltersProps> = ({
  className,
  appliedFilters = [],
  onRemoveFilterBtnClick,
  onRemoveAllFiltersBtnClick,
  searchBy,
}) => {
  const appliedFiltersClass = cn(className, 'flex items-center');
  const { t } = useTranslation();
  const [filters, setFilters] = useState(appliedFilters);

  useEffect(() => {
    setFilters(appliedFilters);
  }, [appliedFilters]);

  const handleRemoveFilterClick = (filter: AppliedFilterOption) => () => {
    const newAppliedFilters = filters.filter((element) => element.value !== filter.value);
    if (onRemoveFilterBtnClick) onRemoveFilterBtnClick(filter);
    setFilters(newAppliedFilters);
  };

  const renderFilterLabel = (): Array<JSX.Element> | null => {
    return filters.map((filter, index) => {
      return (
        <div key={index} className="flex items-center m-1 pl-3 pr-2 py-1 bg-gray-600 rounded-full space-x-2">
          <span className="text-primary-200 text-label-sm">
            {filter.label}: {filter.value}
          </span>
          <span
            role="button"
            tabIndex={-1}
            onClick={handleRemoveFilterClick(filter)}
            onKeyPress={handleRemoveFilterClick(filter)}>
            <Icon className="text-gray-200" icon={IconCatalog.close} width="20px" height="20px" />
          </span>
        </div>
      );
    });
  };

  const handleRemoveAllFiltersBtnClick = () => {
    if (onRemoveAllFiltersBtnClick) onRemoveAllFiltersBtnClick();
    setFilters([]);
  };

  if (filters.length === 0 && (searchBy === null || searchBy === '')) return <></>;

  return (
    <div className={appliedFiltersClass} data-testid="applied-filters">
      <div className="flex flex-wrap items-center pr-5">{renderFilterLabel()}</div>
      <div
        role="button"
        tabIndex={-1}
        className="flex-shrink-0 ml-auto text-primary-300 underline text-label-sm font-semi-bold cursor-pointer"
        onClick={handleRemoveAllFiltersBtnClick}
        data-testid="remove-filters-button"
        onKeyPress={handleRemoveAllFiltersBtnClick}>
        {t('study.removeAllFiters')}
      </div>
    </div>
  );
};
