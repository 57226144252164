/* eslint-disable i18next/no-literal-string */
import React, { useMemo } from 'react';

interface ColorPath {
  path: string;
  fill: string;
}

type IconPaths = ColorPath[];

export const MulticolorIconCatalog: Record<string, IconPaths> = {
  chatNotification: [
    {
      path:
        'M12.04 4C13.9358 4 15.7002 4.46264 17.1577 5.2529C16.4762 5.96996 16.0578 6.93722 16.0578 8C16.0578 10.2057 17.8601 12 20.0756 12C20.4032 12 20.7217 11.9608 21.0267 11.8868C20.5332 15.4505 16.6803 18.2222 12.04 18.2222C10.9191 18.2222 9.8433 18.0622 8.8489 17.7778C6.20921 20 3 20 3 20C5.10633 17.9289 5.44081 16.5333 5.48601 16C3.9492 14.7289 3 13.0044 3 11.1111C3 7.18222 7.06801 4 12.04 4Z',
      fill: '',
    },
    {
      path:
        'M20.0756 11.0917C21.6882 11.0917 23 9.78217 23 8.17233C23 6.56249 21.6882 5.25293 20.0756 5.25293C18.463 5.25293 17.1512 6.56249 17.1512 8.17233C17.1512 9.78217 18.463 11.0917 20.0756 11.0917Z',
      fill: '#FF5C61',
    },
  ],
  bellNotification: [
    {
      path:
        'M18.8243 11.1749C18.4084 11.2833 17.9925 11.3501 17.5766 11.3501C15.0561 11.3501 13.0015 9.2898 13.0015 6.76243C13.0015 5.53628 13.4839 4.42691 14.2492 3.60113C13.9581 3.24246 13.5089 3.00891 13.0015 3.00891C12.0864 3.00891 11.3378 3.75961 11.3378 4.67714V4.91903C8.86721 5.65306 7.17857 7.93019 7.17857 10.5159V15.5206L5.51489 17.1889V18.023H20.488V17.1889L18.8243 15.5206V11.1749ZM13.0015 20.5253C13.9248 20.5253 14.6651 19.783 14.6651 18.8571H11.3378C11.3378 19.783 12.0864 20.5253 13.0015 20.5253Z',
      fill: 'white',
    },
    {
      path:
        'M20.0756 11.0917C21.6882 11.0917 23 9.78217 23 8.17233C23 6.56249 21.6882 5.25293 20.0756 5.25293C18.463 5.25293 17.1512 6.56249 17.1512 8.17233C17.1512 9.78217 18.463 11.0917 20.0756 11.0917Z',
      fill: '#FF5C61',
    },
  ],
};

export interface MulticolorIconProps {
  /**
   * Sets the width of the Icon
   */
  width?: string;

  /**
   * Sets the width of the Icon
   */
  height?: string;

  /**
   * The icon paths to display
   */
  iconPaths: IconPaths;

  /**
   * Specify an optional className to be added to the component.
   */
  className?: string;

  /**
   * The callback to get notified when the icon was clicked.
   */
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

/**
 * Icon multicolorIcon
 * @author Mauricio Campos De la Rosa<mau.cdr.19@gmail.com>
 * Created at 2021-04-02
 */
export const MulticolorIcon = ({
  width = '24px',
  height = '24px',
  iconPaths,
  className,
  onClick,
}: MulticolorIconProps) => {
  const svgPaths = useMemo(
    () =>
      iconPaths.map(({ path, fill }, i) => <path key={i} fillRule="evenodd" clipRule="evenodd" d={path} fill={fill} />),
    [iconPaths],
  );
  return (
    <svg
      data-testid="Icon"
      className={className}
      width={width}
      height={height}
      fill="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      focusable="false"
      aria-hidden="true"
      onClick={onClick}>
      {svgPaths}
    </svg>
  );
};
