import React, { useState, useImperativeHandle, forwardRef, useMemo } from 'react';
import { TranscriberV2, getCookie, ACCESS_TOKEN_KEY } from '@eva-pacs/core';
import { Button, IconCatalog, ButtonVariant } from '@evacenter/eden';
import { recordaudiobuttonClass } from './styles';
/**
 * Button to record audio and convert it to text. This uses our new transcriptions API (Transcriberv2).
 * @author Salvador Gonzalez<salvador.gonzalez@evacenter.com>
 * Created at 2024-01-24
 */
export var RecordAudioButtonV2 = forwardRef(function (_a, ref) {
    var className = _a.className, onRecord = _a.onRecord, onTerminate = _a.onTerminate, studyId = _a.studyId, reportField = _a.reportField, organizationId = _a.organizationId, onClick = _a.onClick, onSuccessfullConnection = _a.onSuccessfullConnection;
    var _b = useState(false), isRecording = _b[0], setIsRecording = _b[1];
    var _c = useState(false), isDisabled = _c[0], setIsDisabled = _c[1];
    var _d = useState(false), isLoading = _d[0], setIsLoading = _d[1];
    var currentSessionToken = getCookie(ACCESS_TOKEN_KEY);
    var handleButtonLoadingState = function () {
        setIsLoading(true);
        setIsDisabled(true);
    };
    var handleButtonFinishLoadingState = function () {
        setIsLoading(false);
        setIsDisabled(false);
    };
    var transcriberSink = useMemo(function () {
        return {
            onTranscript: function (content) {
                onRecord(content);
            },
            onError: function (message) {
                setIsRecording(false);
                console.warn(message);
            },
            onStopping: function () {
                // Since we stop the connection lazy, we need to disable the button
                handleButtonLoadingState();
            },
            onSuccessfullConnection: function () {
                setIsRecording(true);
                handleButtonFinishLoadingState();
                onSuccessfullConnection();
            },
            onTerminate: function (message) {
                if (onTerminate)
                    onTerminate(message);
                handleButtonFinishLoadingState();
                setIsRecording(false);
            },
            studyId: studyId,
            reportField: reportField,
            organizationId: organizationId,
            sessionToken: currentSessionToken,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSessionToken, reportField, studyId]);
    var transcriber = useMemo(function () {
        return new TranscriberV2(transcriberSink);
    }, [transcriberSink]);
    var handleOnClick = function () {
        handleButtonLoadingState();
        if (onClick)
            onClick();
        if (isRecording) {
            return transcriber.stop();
        }
        try {
            transcriber.start();
        }
        catch (error) {
            handleButtonFinishLoadingState();
        }
    };
    useImperativeHandle(ref, function () { return ({
        onClickButton: function () { return handleOnClick(); },
    }); });
    return (React.createElement(Button, { isDisabled: isDisabled, disabled: isDisabled, isLoading: isLoading, style: { height: '32px', padding: 0 }, className: recordaudiobuttonClass(className), startIcon: isRecording ? IconCatalog.pause : IconCatalog.mic, variant: isRecording ? ButtonVariant.destructive : ButtonVariant.primary, onClick: handleOnClick }));
});
