import React, { FC, useMemo, useState } from 'react';
import cn from 'classnames';
import { Breakpoint, isMobileDevice, useBreakpoint } from '@eva-pacs/core';

import { ListType } from '~/src/types/StudyList';

interface ListTypeItemProps {
  label: string;
  type: ListType;
  isActive?: boolean;
  onClick: () => void;
}

const ListTypeImages = {
  [ListType.INFINITE_SCROLL]: `${process.env.REACT_APP_CDN_URL}/images/si.gif`,
  [ListType.PAGINATION]: `${process.env.REACT_APP_CDN_URL}/images/pag.gif`,
};

const ListTypeMobileImages = {
  [ListType.INFINITE_SCROLL]: `${process.env.REACT_APP_CDN_URL}/images/mob-si.gif`,
  [ListType.PAGINATION]: `${process.env.REACT_APP_CDN_URL}/images/mob-pag.gif`,
};

export const ListTypeItem: FC<ListTypeItemProps> = ({ label, type, isActive = false, onClick }) => {
  const breakpoint = useBreakpoint();
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const isBreakpointMobile = useMemo(() => [Breakpoint.xs].includes(breakpoint), [breakpoint]);
  const isMobile = useMemo(() => isMobileDevice() && isBreakpointMobile, [isBreakpointMobile]);
  const images = useMemo(() => (isMobile ? ListTypeMobileImages[type] : ListTypeImages[type]), [isMobile, type]);

  const classes = {
    container: cn('e-flex e-flex-col e-items-center e-space-y-4 e-cursor-pointer', {
      'e-w-56': !isMobile,
    }),
    image: cn('e-border-2 e-rounded', {
      'e-border-neutral-400': isHovered && !isActive,
      'e-border-neutral-600': !isHovered && !isActive,
      'e-border-primary-500': isActive,
    }),
    label: cn('e-text-xs', {
      'e-text-neutral-300': !isActive,
      'e-text-base-white-100': isActive,
    }),
  };

  return (
    <div
      tabIndex={0}
      role="button"
      onClick={onClick}
      onKeyPress={onClick}
      className={classes.container}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <img src={images} alt={label} className={classes.image} />
      <span className={classes.label}>{label}</span>
    </div>
  );
};
