import { useState, useEffect, useCallback, useMemo } from 'react';
import shallow from 'zustand/shallow';
import { StudyWorklistType, useGetStudyWorkListLazyQuery } from '@eva-pacs/client';

import { useStudyListStore } from '~/src/store';
import { studyDTO } from '~/src/dtos/study/studyDTO';
import { PAGE_SIZE, PAGE_SIZE_INFINITE_SCROLL } from '~/src/constants';

import { useStudyActions } from '../study/useStudyActions';

interface WorkListDataLoaderProps {
  isVisible?: boolean;
  tableSortBy?: string;
}

export const useStudyWorklist = ({ isVisible, tableSortBy }: WorkListDataLoaderProps) => {
  const { performReset } = useStudyActions();
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [
    addStudies,
    setWorklistTotalCount,
    currentPage,
    setCurrentPage,
    reverse,
    setReverse,
    setEnableGoToTop,
    setEnableGoToBottom,
    enabledInfiniteScroll,
  ] = useStudyListStore(
    (state) => [
      state.addStudies,
      state.setWorklistTotalCount,
      state.currentPage,
      state.setCurrentPage,
      state.reverse,
      state.setReverse,
      state.setEnableGoToTop,
      state.setEnableGoToBottom,
      state.enabledInfiniteScroll,
    ],
    shallow,
  );

  const pageSize = useMemo(() => (enabledInfiniteScroll ? PAGE_SIZE_INFINITE_SCROLL : PAGE_SIZE), [
    enabledInfiniteScroll,
  ]);

  const queryVariables = useMemo(
    () => ({
      limit: pageSize,
      ordering: tableSortBy,
      offset: (currentPage - 1) * pageSize,
    }),
    [currentPage, pageSize, tableSortBy],
  );

  const [getStudyList, { loading }] = useGetStudyWorkListLazyQuery({
    variables: queryVariables,
    fetchPolicy: 'no-cache',
    onCompleted: (result) => {
      const parsedStudies = result.studyWorklist?.results.map((studyWorklist) =>
        studyDTO(studyWorklist as StudyWorklistType),
      );
      setWorklistTotalCount(result.studyWorklist?.totalCount || 0);
      if (!parsedStudies) return;
      setInitialLoading(false);
      addStudies(parsedStudies);
      setHasNextPage(result?.studyWorklist?.pageInfo?.hasNextPage || false);
      setHasPreviousPage(result?.studyWorklist?.pageInfo?.hasPreviousPage || false);
    },
  });

  const handleReset = () => {
    setInitialLoading(true);
    performReset();
    setReverse(false);
    setCurrentPage(1);
    setHasNextPage(false);
    setEnableGoToTop(false);
    setHasPreviousPage(false);
    setEnableGoToBottom(true);
  };

  const handleSetCurrentPage = useCallback(
    (page: number, scrollToPage = false) => {
      if (scrollToPage) {
        setInitialLoading(true);
        performReset();
        setCurrentPage(page);
        getStudyList();
      } else if (page !== currentPage) {
        performReset();
        setCurrentPage(page);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage],
  );

  const reloadData = () => {
    handleReset();
    getStudyList();
  };

  useEffect(() => {
    getStudyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Triggers query to add more studies for infinite scroll.
  useEffect(() => {
    if (isVisible && enabledInfiniteScroll) {
      reverse ? setCurrentPage(currentPage - 1) : setCurrentPage(currentPage + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, enabledInfiniteScroll, reverse]);

  return {
    loading,
    pageSize,
    reloadData,
    handleReset,
    hasNextPage,
    hasPreviousPage,
    setInitialLoading,
    handleSetCurrentPage,
    initialLoading,
  };
};
