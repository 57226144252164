import cn from 'classnames';
import { AvatarSize } from './index';
/* Class assignment */
export var avatarClass = function (className, size) {
    return cn(className, 
    /* Avatar (base) */
    'flex items-center', 'relative', 'whitespace-nowrap overflow-hidden overflow-ellipsis', 'bg-gray-100 rounded-full', 
    /* avatar--2xs */
    size === AvatarSize['2xs'] && 'w-6 h-6', 
    /* avatar--xs */
    size === AvatarSize.xs && 'w-8 h-8', 
    /* avatar--sm */
    size === AvatarSize.sm && 'w-10 h-10', 
    /* avatar--md */
    size === AvatarSize.md && 'w-16 h-16', 
    /* avatar--lg */
    size === AvatarSize.lg && 'w-20 h-20', 
    /* avatar--xl */
    size === AvatarSize.xl && 'w-24 h-24');
};
export var imageClass = function (size) {
    return cn(
    /* Image (base) */
    'inline-block', 'align-middle', 
    /* image--2xs */
    size === AvatarSize['2xs'] && 'w-6 h-6', 
    /* image--xs */
    size === AvatarSize.xs && 'w-8 h-8', 
    /* image--sm */
    size === AvatarSize.sm && 'w-10 h-10', 
    /* image--md */
    size === AvatarSize.md && 'w-16 h-16', 
    /* image--lg */
    size === AvatarSize.lg && 'w-20 h-20', 
    /* image--xl */
    size === AvatarSize.xl && 'w-24 h-24');
};
