import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchInput, ButtonColor } from '@eva-pacs/ui';
import { Button, ButtonSize, ButtonVariant, IconCatalog } from '@evacenter/eden';

interface SearchBarProps {
  value: string;
  onSearchChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onFilterBtnClick: () => void;
}

export const SearchBar = ({ value, onSearchChange, onFilterBtnClick }: SearchBarProps) => {
  const { t } = useTranslation();
  return (
    <section data-testid="filter-section">
      <div className="lg:e-w-100 flex items-center space-x-3 lg:flex-row">
        <SearchInput
          isFullWidth
          value={value}
          dataTestId="study-search"
          onChange={onSearchChange}
          placeholder={t('study.searchPlaceholder')}
        />
        {/* TODO: temporal overiding classes to fix height extra pixels */}
        <Button
          size={ButtonSize.sm}
          onClick={onFilterBtnClick}
          endIcon={IconCatalog.filter}
          variant={ButtonVariant.secondary}
          data-testid="filter-section-button"
          color={ButtonColor.primaryAlternative}>
          {t('general.filter')}
        </Button>
      </div>
    </section>
  );
};
