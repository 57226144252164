import cn from 'classnames';
/* Class assignment */
export var wrapperClasses = function (className) {
    return cn(className, 
    /* IconLabel (base) */
    'flex items-center');
};
export var iconClasses = function (className) {
    return cn(className, 
    /* IconLabel (base) */
    'flex items-center mr-3');
};
export var labelClasses = function (className) {
    return cn(className, 
    /* IconLabel (base) */
    'flex items-center text-label-sm');
};
