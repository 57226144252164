import React, { useMemo } from 'react';
import shallow from 'zustand/shallow';
import { useTranslation } from 'react-i18next';
import { Button, ButtonSize, ButtonVariant, IconCatalog, SelectOption } from '@evacenter/eden';

import { DownloadIsoStatusBurn } from '@eva-pacs/client';
import { Progress } from '@eva-pacs/ui';
import { Select } from '@evacenter/eden';

import useBurnIso from './useBurnISO';
import { BurnProgressType, useStudyActionsStore } from '../studyActionsStore';
import BurnRow, { TableElement } from './BurnRow';

const getBurnerAverageProgress = (averageProgress: number, current: BurnProgressType) => {
  if (current.status === DownloadIsoStatusBurn.COMPLETED) return averageProgress + 100;
  if (current.status === DownloadIsoStatusBurn.PENDING) return averageProgress + 50;
  return averageProgress;
};

const formatBurnOptions = (burnerName: string, facilityName: string, value: string) => ({
  label: `${burnerName} - ${facilityName}`,
  value,
});

const BurnMultiple = () => {
  const [selectedStudies, setBurnerId, burnProgress] = useStudyActionsStore(
    (store) => [store.selectedStudies, store.setBurnerId, store.burnProgress],
    shallow,
  );
  const { burnersList } = useBurnIso();
  const { t } = useTranslation();

  const burnProgressAverage = useMemo(() => burnProgress.reduce(getBurnerAverageProgress, 0) / selectedStudies.length, [
    burnProgress,
    selectedStudies.length,
  ]);

  const burnerOptions = useMemo(
    () =>
      burnersList?.map((burner, index) =>
        formatBurnOptions(burner?.name || '', burner?.facility.name || '', index.toString()),
      ) || [],
    [burnersList],
  );

  const handleBurnerChange = (event: SelectOption) => {
    if (!burnersList) return;

    const selectedIndex = parseInt(event.value);
    const newBurnerId = burnersList[selectedIndex]?.id;

    setBurnerId(newBurnerId);
  };

  const handleBurnAll = () => {
    const readyToBurnStudies: Array<BurnProgressType> = [];

    // I could use a filter function but procces array was mutating its value too
    burnProgress.forEach((values) => {
      if (values.status === DownloadIsoStatusBurn.FAILED || values.status === null) readyToBurnStudies.push(values);
    });

    readyToBurnStudies.forEach((study) => {
      const burnButton = document.querySelector(`[aria-label="burn-button-${study.index}"]`) as HTMLButtonElement;
      burnButton.click();
    });
  };

  return (
    <>
      <div className="flex flex-col justify-items-start mb-4">
        <h3 className="e-text-neutral-50 text-left text-paragraph font-medium">
          {t('study.dropdownMenu.selectBurner')}
        </h3>
        <Select name="select" options={burnerOptions} onChange={handleBurnerChange} />
      </div>
      <table className="e-text-neutral-50 e-w-full">
        <thead>
          <tr>
            <th>
              <TableElement>{t('viewer.report.patientHistory.study')}</TableElement>
            </th>
            <th>
              <TableElement>{t('study.studyTable.status')}</TableElement>
            </th>
            <th className="pl-4">
              <TableElement>{t('viewer.report.actionsBtn')}</TableElement>
            </th>
          </tr>
        </thead>
        <tbody>
          {selectedStudies.map((study, index) => (
            <BurnRow key={study.actionColumn} study={study} index={index} />
          ))}
          <tr>
            <td className="relative">
              <Progress maxValue={100} value={burnProgressAverage} />
            </td>
            <td></td>
            <td>
              <Button
                size={ButtonSize.xs}
                variant={ButtonVariant.ghost}
                endIcon={IconCatalog.burn}
                onClick={handleBurnAll}>
                {t('study.dropdownMenu.burn')} {t('general.all').toLowerCase()}
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default BurnMultiple;
