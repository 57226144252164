import React, { ReactElement, useEffect } from 'react';
import { FrigadeProvider, useUser, useOrganization } from '@frigade/react';
import { useSessionStore } from '~/src/store';
import ErrorBoundary from '~/utils/ErrorBoundary';
import Announcements from './Announcements';
import Surveys from './Surveys';
import { isMobileDevice } from '@eva-pacs/core';

function FrigadeAuthInitializer({ children }: { children: ReactElement }) {
  const user = useSessionStore((state) => state.user);
  const getCurrentAccount = useSessionStore((state) => state.getCurrentAccount);
  const { setUserIdWithProperties } = useUser();
  const { setOrganizationIdWithProperties } = useOrganization();

  useEffect(() => {
    if (user && user.id) {
      const currentAccount = getCurrentAccount();
      setUserIdWithProperties(user.id, {
        email: user.email ?? null,
      });
      setOrganizationIdWithProperties(currentAccount.organizationId, {
        name: currentAccount.organizationName,
      });
    }
  }, [setUserIdWithProperties, user, getCurrentAccount, setOrganizationIdWithProperties]);

  return children;
}

export function Onboarding({ children }: { children: ReactElement }) {
  const user = useSessionStore((state) => state.user);
  const apiKey = process.env.REACT_APP_FRIGADE_PUBLIC_API_KEY;

  const isDesktop = !isMobileDevice();

  if (!apiKey) {
    return children;
  }

  if (!user?.id) {
    return children;
  }

  return (
    <ErrorBoundary context="Frigade Announcement" fallback={children}>
      <FrigadeProvider publicApiKey={apiKey} userId={user.id}>
        <>
          <FrigadeAuthInitializer>{children}</FrigadeAuthInitializer>
          <Announcements />
          {isDesktop && <Surveys />}
        </>
      </FrigadeProvider>
    </ErrorBoundary>
  );
}

export default Onboarding;
