import { UserPreferenceType, useUserPreferencesQuery } from '@eva-pacs/client';
import { Family } from '@eva-pacs/core';
import { useSessionStore } from '~/src/store';

interface UseUserFiltersOptions {
  /**
   * Callback to trigger on fetch preferences completion
   */
  onCompleted?: (userPreferences: Array<UserPreferenceType>) => void;
}

/**
 * Use `useUserFilters` to retrieve user filters and methods to help accessing that data
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2023-01-31
 */
export const useUserFilters = (options?: UseUserFiltersOptions) => {
  const { onCompleted } = options ?? {};
  const { user } = useSessionStore();

  const { data: userPreferencesData, refetch: getUserFilters, loading } = useUserPreferencesQuery({
    variables: { userId: user?.id, family: Family.filter },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: (response) => {
      if (onCompleted) onCompleted(response.userPreferences?.results as Array<UserPreferenceType>);
    },
  });

  const userFilters = userPreferencesData?.userPreferences?.results as Array<UserPreferenceType>;

  const getUserFilterByKey = (filterKey: string) => userFilters?.find((preference) => preference?.key === filterKey);

  return {
    loading,
    userFilters,
    getUserFilters,
    getUserFilterByKey,
  };
};
