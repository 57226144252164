import React, { useEffect, useState } from 'react';
import cn from 'classnames';
/**
 * Use this component to set the app shortcuts
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-05-25
 */
var HotkeyButton = function (_a) {
    var actionName = _a.actionName, _b = _a.defaultValue, defaultValue = _b === void 0 ? '' : _b, className = _a.className, ariaLabel = _a.ariaLabel, _c = _a.hasError, hasError = _c === void 0 ? false : _c, onChange = _a.onChange;
    var hotkeyButtonClass = cn(className, 'appearance-none outline-none', 'p-2 w-full', 'bg-basic-white bg-opacity-0', 'focus:text-secondary-300 text-label-md font-semi-bold text-center', 'border-2  focus:border-secondary-300 rounded-lg', hasError && 'border-feedback-negative-500 text-feedback-negative-500', !hasError && 'border-primary-300 text-primary-300');
    var _d = useState(defaultValue), value = _d[0], setValue = _d[1];
    var _e = useState(false), listen = _e[0], setListen = _e[1];
    var handleKeysListener = function (event) {
        var _a;
        if (event.isComposing)
            return;
        if (onChange)
            onChange(actionName, {
                id: event.key,
                keys: (_a = {},
                    _a["".concat(event.key)] = true,
                    _a),
            });
        setValue(event.key);
        setListen(false);
        event.target.blur();
    };
    useEffect(function () {
        setValue(defaultValue);
    }, [defaultValue]);
    useEffect(function () {
        if (listen)
            window.addEventListener('keyup', handleKeysListener);
        else
            window.removeEventListener('keyup', handleKeysListener);
        return function () { return window.removeEventListener('keyup', handleKeysListener); };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listen]);
    var handleClick = function () { return setListen(true); };
    return (React.createElement("input", { "aria-label": ariaLabel, type: "text", readOnly: true, className: hotkeyButtonClass, value: value, onBlur: function () { return setListen(false); }, onClick: handleClick }));
};
export default HotkeyButton;
