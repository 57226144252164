import React, { useEffect } from 'react';
import cn from 'classnames';
import { EmptyState } from '@eva-pacs/ui';
import { useTranslation } from 'react-i18next';
import { DownloadStatus, useCreateDownloadMutation, CreateDownloadMutation } from '@eva-pacs/client';
import { handleProgrammaticDownload, useInterval } from '@eva-pacs/core';
import { useErrorHandler } from '~/utils/appHelpers';
import { RETRY_DOWNLOAD_TIME, ZIP_IMAGE_PATH } from '~/constants';

export interface DownloadDicomModalProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Pass study id for mutation
   */
  studyId: string;

  /**
   * Handle close current modal
   */
  onClose: () => void;
}

/**
 * Use this component in order to show waiting state for zip compression and download modal
 * @author Ricardo Aguirre Nava<ricardo.a.nava@gmail.com>
 * Created at 2021-09-06
 */
export const DownloadDicomModal: React.FC<DownloadDicomModalProps> = ({ className, studyId, onClose }) => {
  const { t } = useTranslation();
  const { handleError } = useErrorHandler();

  const modalClass = cn(className, 'max-w-sm');

  const [createDownloadMutation] = useCreateDownloadMutation({
    onCompleted: ({ createDownload: { success, download, errors } }: CreateDownloadMutation) => {
      if (success && download?.status === DownloadStatus.COMPLETED) {
        onClose();
        handleProgrammaticDownload(download.fileUrl as string);
      } else if (!success && errors) {
        onClose();
        handleError({
          logMessage: Object.keys(errors)[0],
          modalProps: { onClose },
        });
      }
    },
  });

  useEffect(() => {
    createDownloadMutation({
      variables: { input: { study: studyId } },
    });
  }, [createDownloadMutation, studyId]);

  useInterval(() => {
    createDownloadMutation({
      variables: { input: { study: studyId } },
    });
  }, RETRY_DOWNLOAD_TIME);

  return (
    <div className={modalClass}>
      <EmptyState
        imageSrc={ZIP_IMAGE_PATH}
        title={t('general.downloadDicom.caption')}
        description={t('general.downloadDicom.description')}
      />
    </div>
  );
};
