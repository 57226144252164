import { ENCRYPTED_CREDENTIALS_PARAM, ENCRYPTED_CREDENTIALS_PRACTITIONER_PARAM } from '@eva-pacs/core';
import { REPORT_FULLSCREEN_MODE_PARAM } from '../constants/queryParams';

export const getEncryptedCredentialsParam = (param: string): string => {
  const params = new URLSearchParams(window.location.search);
  const encryptedCredentials = params.get(param);
  return `${encryptedCredentials ?? ''}`.replace(/==$/, '');
};

export const hasEncryptedCredentials = (): boolean => {
  return Boolean(getEncryptedCredentialsVisitant() || getEncryptedCredentialsPractitioner());
};

export const getEncryptedCredentialsVisitant = (): string => {
  return getEncryptedCredentialsParam(ENCRYPTED_CREDENTIALS_PARAM);
};

export const getEncryptedCredentialsPractitioner = (): string => {
  return getEncryptedCredentialsParam(ENCRYPTED_CREDENTIALS_PRACTITIONER_PARAM);
};

export const getDecryptedCredentials = (encryptedCredentials: string) => {
  const newParams = new URLSearchParams(atob(encryptedCredentials));
  const user = newParams.get('user') ?? '';
  const password = newParams.get('password') ?? '';
  return { user, password };
};

export const isOpenReportFullscreenWindowEnable = () => {
  const params = new URLSearchParams(window.location.search);
  const openReportFullscreenWindow =
    params.get(REPORT_FULLSCREEN_MODE_PARAM) && params.get(REPORT_FULLSCREEN_MODE_PARAM) !== 'false';
  return openReportFullscreenWindow;
};
