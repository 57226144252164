import React, { useMemo } from 'react';
import cn from 'classnames';
import shallow from 'zustand/shallow';
import { useModal } from '@eva-pacs/core';
import { Pagination } from '@eva-pacs/ui';
import { Trans, useTranslation } from 'react-i18next';
import { Button, ButtonSize, ButtonVariant, IconCatalog } from '@evacenter/eden';

import { ListType } from '~/src/types/StudyList';
import { Authorization } from '~/src/utils/authorization';
import { createDownloadCsv } from '~/src/utils/appHelpers';
import { CAN_DOWNLOAD_CSV, POLICIES } from '~/src/constants';
import { useSessionStore, useStudyListStore } from '~/src/store';
import { useStudyListTabs } from '~/src/hooks/study/useStudyListTabs';
import { WorklistTabNames } from '~/pages/StudyList/Worklist/WorklistTabs';
import { SupportChatButton } from '~/src/components/SupportChatButton/SupportChatButton';

import { GoToButton } from '../GoToButton';
import { ListTypeSwitch } from '../ListTypeSwitch';

const LOCK_CSV_LIMIT = 5000;

interface StudyListFooterProps {
  pageSize: number;
  totalCount: number;
  currentPage: number;
  isChatAvailable: boolean;
  isEmptyFilterFields?: boolean;
  handleSwitchListType: (listType: ListType) => void;
  handlePageChange: (currentPage: number, scrollToPage?: boolean) => void;
}

export const Footer = ({
  pageSize = 0,
  totalCount = 0,
  currentPage = 0,
  isChatAvailable,
  handlePageChange,
  handleSwitchListType,
}: StudyListFooterProps): JSX.Element => {
  const { t } = useTranslation();
  const { setCurrentModal } = useModal();
  const { activeTab } = useStudyListTabs();
  const user = useSessionStore((state) => state.user);
  const [
    setReverse,
    enableGoToTop,
    enableGoToBottom,
    setEnableGoToTop,
    setEnableGoToBottom,
    enabledInfiniteScroll,
  ] = useStudyListStore(
    (state) => [
      state.setReverse,
      state.enableGoToTop,
      state.enableGoToBottom,
      state.setEnableGoToTop,
      state.setEnableGoToBottom,
      state.enabledInfiniteScroll,
    ],
    shallow,
  );

  const canDownloadCsv = POLICIES[CAN_DOWNLOAD_CSV](user);

  const hasStudies = useMemo(() => {
    return totalCount > 0;
  }, [totalCount]);

  const handleCreateCsvDownload = () => createDownloadCsv({ setCurrentModal });

  const firstPage = 1;
  const lastPage = Math.ceil(totalCount / pageSize);

  const classes = {
    container: cn('e-fixed e-z-10 e-left-0 e-right-0 e-bottom-0 e-flex e-flex-col e-w-screen'),
    pageInfo: cn(
      'e-flex e-items-center e-justify-between e-px-6 e-py-3',
      'horizontal-scroll-styles overflow-y-scroll lg:overflow-hidden',
      'e-bg-neutral-800/80 e-backdrop-blur-md e-border-t e-border-neutral-600',
    ),
    chatButton: cn('absolute -e-top-10 e-mr-0'),
    boxContainer: cn(
      'e-h-8 e-flex e-items-center e-pl-4 e-pr-1 e-py-1 e-space-x-4',
      'e-shadow-2xl e-shadow-base-black/90',
      {
        'pr-4': activeTab === WorklistTabNames.WORKLIST,
      },
    ),
    pagination: cn('e-h-8 e-flex e-items-center e-space-x-4'),
    goTo: cn('e-flex e-items-center e-space-x-2'),
  };

  if (!totalCount) return <></>;

  return (
    <div className={classes.container}>
      {isChatAvailable && (
        <div className="relative flex justify-end px-8 pointer-events-none">
          <SupportChatButton className={classes.chatButton} />
        </div>
      )}
      <div className={classes.pageInfo} style={{ backdropFilter: 'blur(11.5px)' }}>
        <div className={classes.boxContainer}>
          <div className="e-text-neutral-300 e-text-sm whitespace-nowrap lg:whitespace-normal">
            <Trans
              i18nKey="study.scrollInfo"
              values={{ fromCount: totalCount.toLocaleString() }}
              components={{ 1: <span className="e-font-semi-bold e-text-base-white" /> }}
            />
          </div>
          {hasStudies && activeTab !== WorklistTabNames.WORKLIST && (
            <Authorization policyCheck={canDownloadCsv}>
              <Button
                onClick={handleCreateCsvDownload}
                size={ButtonSize.xs}
                isDisabled={totalCount > LOCK_CSV_LIMIT}
                endIcon={IconCatalog.download}
                variant={ButtonVariant.tertiary}>
                {t('study.csv')}
              </Button>
            </Authorization>
          )}
        </div>
        <div className={classes.pagination}>
          {enabledInfiniteScroll ? (
            <div className={classes.goTo}>
              <span className="e-text-neutral-300 e-text-sm">{t('study.goTo')}</span>
              <GoToButton
                goToTop
                label={t('study.goToTop')}
                disabled={!enableGoToTop}
                onClick={() => {
                  setReverse(false);
                  setEnableGoToTop(false);
                  setEnableGoToBottom(true);
                  handlePageChange(firstPage, true);
                }}
              />
              <GoToButton
                label={t('study.goToBottom')}
                disabled={!enableGoToBottom}
                onClick={() => {
                  setReverse(true);
                  setEnableGoToTop(true);
                  setEnableGoToBottom(false);
                  handlePageChange(lastPage, true);
                }}
              />
            </div>
          ) : (
            <Pagination
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              totalCount={totalCount as number}
            />
          )}
          <div className="e-w-px e-h-8 e-bg-neutral-600" />
          <ListTypeSwitch handleSwitchListType={handleSwitchListType} />
        </div>
      </div>
    </div>
  );
};
