import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Badge, BadgeColor, BadgeSize } from '../../../Badge';
/**
 * See Note Cell (Table)
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-07-27
 */
export var SeeNoteCell = function (_a) {
    var _b = _a.counter, counter = _b === void 0 ? 0 : _b, className = _a.className, onClick = _a.onClick;
    var t = useTranslation().t;
    var cellClass = cn(className, 'flex items-center', {
        'cursor-pointer': counter !== 0,
        'cursor-default': counter === 0,
    });
    var textClass = cn('text-label-sm font-semi-bold', {
        'text-basic-white': counter !== 0,
        'text-light-gray-600': counter === 0,
    });
    var handleClick = function () {
        if (onClick && counter !== 0)
            onClick();
    };
    return (React.createElement("div", { role: "button", tabIndex: -1, className: cellClass, onClick: handleClick, onKeyPress: handleClick },
        React.createElement(Badge, { className: "mr-2", color: counter === 0 ? BadgeColor.neutral : BadgeColor.primary, size: BadgeSize.md, counter: counter }),
        React.createElement("span", { className: textClass }, t('general.actions.see'))));
};
