import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals, useModal } from '@eva-pacs/core';
import { JoinMultipleStudiesMutation, StudyStatus } from '@eva-pacs/client';
import shallow from 'zustand/shallow';

import { JoinStudies } from './';
import { useSessionStore } from '~/src/store';
import { useErrorHandler } from '~/utils/appHelpers';
import { useStudyActionsStore } from '../studyActionsStore';
import { CAN_JOIN_STUDIES, POLICIES } from '~/constants';

const MINIMUM_STUDIES_SELECTED_TO_JOIN = 2;

interface UseJoinStudiesHook {
  /**
   * Handler to open the join studies modal
   */
  openJoinStudiesModal: () => void;

  /**
   * Flag to indicate if the join studies action can be performed or not
   */
  hasMoreThanOneStudyEnabled: boolean;

  /**
   * Flag to indicate if the user can join the selected studies
   */
  canJoinStudies: boolean;

  /**
   * Flag to indicate if the user has the right permissions to perform the action
   */
  hasPermisionToJoin: boolean;
}

/**
 * Use this hook in order to get access to relevant information regarding "Join Studies"
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2022-05-06
 */
export const useJoinStudies = (): UseJoinStudiesHook => {
  const { t } = useTranslation();
  const [selectedStudies, setJoinedStudies, setSelectedStudies] = useStudyActionsStore(
    (state) => [state.selectedStudies, state.setJoinedStudies, state.setSelectedStudies],
    shallow,
  );
  const { setCurrentModal, currentModal } = useModal();
  const { handleError } = useErrorHandler();
  const { user } = useSessionStore();

  const hasMoreThanOneStudyEnabled = selectedStudies.length >= MINIMUM_STUDIES_SELECTED_TO_JOIN;
  const hasPermisionToJoin = POLICIES[CAN_JOIN_STUDIES](user);

  const closeModal = () => setCurrentModal(null);

  const handleJoinStudiesCompleted = (data?: JoinMultipleStudiesMutation) => {
    if (data?.joinMultipleStudies?.success === false) {
      handleError({ modalProps: { onClose: closeModal }, errors: data?.joinMultipleStudies.errors });
      return;
    }

    closeModal();
    setSelectedStudies([]);
    setJoinedStudies(true);
  };

  const openJoinStudiesModal = () => {
    setCurrentModal({
      name: Modals.GenericModal,
      props: {
        title: t('study.join.joinStudies'),
        className: 'max-w-5xl',
        isMaxWidthCustom: true,
        onClose: closeModal,
        children: <JoinStudies onClose={closeModal} onJoinedStudiesCompleted={handleJoinStudiesCompleted} />,
      },
    });
  };

  const canJoinStudies = useMemo(() => {
    const studiesAvailableToJoin = selectedStudies.filter((study) =>
      [StudyStatus.READING, StudyStatus.READING_PENDING, StudyStatus.IMAGES_SENT].includes(study.status as StudyStatus),
    );
    const allStudiesCanBeJoined = studiesAvailableToJoin.length === selectedStudies.length;
    return hasPermisionToJoin && allStudiesCanBeJoined && hasMoreThanOneStudyEnabled;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMoreThanOneStudyEnabled, hasPermisionToJoin, selectedStudies]);

  // This helps to adapt the modal width with the updated classes,
  // since we are using a single component as the modal children.
  useEffect(() => {
    if (!currentModal) return;
    openJoinStudiesModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    hasPermisionToJoin,
    openJoinStudiesModal,
    hasMoreThanOneStudyEnabled,
    canJoinStudies,
  };
};
