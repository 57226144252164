import React from 'react';
import cn from 'classnames';
import { StudyFilterForm, StudyFilterFields } from '~/components/StudyList/StudyFilterForm';

export interface FilterStudyModalProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Initial values for each filter
   */
  defaultFilters?: StudyFilterFields;

  /**
   * Provide a handler that is called whenever the save filters button is clicked
   */
  onFilterSave: (filters?: StudyFilterFields) => void;

  /**
   * Provide a handler that is called whenever the cancel button is clicked
   */
  onCancel: () => void;
}

/**
 * Use this component in order to show the fiter form of the study list
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-05-21
 */
export const FilterStudyModal: React.FC<FilterStudyModalProps> = ({
  defaultFilters,
  className,
  onFilterSave,
  onCancel,
}) => {
  const modalClass = cn(className, 'text-basic-white');

  const handleFilterBtnClick = (filters?: StudyFilterFields) => {
    if (onFilterSave) onFilterSave(filters);
  };

  const handleCancelClick = () => {
    if (onCancel) onCancel();
  };

  return (
    <div className={modalClass}>
      <StudyFilterForm
        filters={defaultFilters}
        onCancelBtnClick={handleCancelClick}
        onFilterBtnClick={handleFilterBtnClick}
      />
    </div>
  );
};
