import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modals, useModal } from '@eva-pacs/core';
import { useBurnersQuery } from '@eva-pacs/client';

import { useSessionStore } from '~/src/store';
import { CAN_BURN_DISCS, POLICIES } from '~/constants';

import BurnMultiple from './BurnMultiple';
import { useStudyActionsStore } from '../studyActionsStore';

const MINIMUM_STUDIES_SELECTED_TO_BURN = 1;

const useBurnIso = () => {
  const { t } = useTranslation();
  const { setCurrentModal } = useModal();
  const { data: burnersData } = useBurnersQuery();
  const user = useSessionStore((state) => state.user);
  const selectedStudies = useStudyActionsStore((store) => store.selectedStudies);

  const burnersList = burnersData?.burners?.results || [];
  const discBurningPolicy = POLICIES[CAN_BURN_DISCS](user);
  const canActiveMultipleBurn = selectedStudies.length >= MINIMUM_STUDIES_SELECTED_TO_BURN;

  const canBurnDiscs = useMemo(() => Boolean(burnersData?.burners?.results.length) && discBurningPolicy, [
    burnersData?.burners?.results?.length,
    discBurningPolicy,
  ]);

  const openBurnMultipleModal = () => {
    setCurrentModal({
      name: Modals.GenericModal,
      props: {
        title: `${t('study.dropdownMenu.burn')} CDs`,
        className: 'max-w-5xl',
        isMaxWidthCustom: true,
        onClose: () => setCurrentModal(null),
        children: <BurnMultiple />,
      },
    });
  };

  return {
    burnersList,
    canBurnDiscs,
    openBurnMultipleModal,
    canActiveMultipleBurn,
  };
};

export default useBurnIso;
