import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { getDateFormatField, getTextField, getUUIDField } from '@eva-pacs/core';
import {
  PatientGender,
  useFilterFieldsQuery,
  StudyUrgencyLevel,
  StudyStatus,
  getStudyStatusText,
  ReportBirads,
  useOrganizationModalityQuery,
} from '@eva-pacs/client';
import {
  FormField,
  BasicInput,
  Button,
  ButtonColor,
  ButtonType,
  DatePickerInput,
  NativeSelect,
  NativeSelectOption,
  MultiSelect,
} from '@eva-pacs/ui';
import { useGetAssignablePractitionerOptions } from '~/src/hooks/practitioners/useGetAssignablePractitionerOptions';
import { useFeatureFlagsStore } from '~/src/store/flagsStore';
import { StudyFilterOrganizationSpecificFields } from './StudyFilterOrganizationSpecificFields/StudyFilterOrganizationSpecificFields';
import { FeatureFlags } from '~/src/constants/featureFlags';
import { formatModalityLabel } from '~/src/utils/formatModalityLabel';

export enum StudyFilterName {
  patientFullName = 'patientFullName',
  patientGender = 'patientGender',
  seriesModality = 'seriesModality',
  dicomDateTimeGte = 'dicomDateTimeGte',
  dicomDateTimeLte = 'dicomDateTimeLte',
  status = 'status',
  urgencyLevel = 'urgencyLevel',
  studyType = 'studyType',
  practitionersAssignedUserId = 'practitionersAssignedUserId',
  practitionerReviewerUserId = 'practitionerReviewerUserId',
  seriesDicomOperatorsName = 'seriesDicomOperatorsName',
  facility = 'facility',
  reportsBirads = 'reportsBirads',
  patientAgeStart = 'patientAgeStart',
  patientAgeEnd = 'patientAgeEnd',
  pacsStudyStartDate = 'pacsStudyStartDate',
  pacsStudyEndDate = 'pacsStudyEndDate',
  reportSignedStartDate = 'reportSignedStartDate',
  reportSignedEndDate = 'reportSignedEndDate',
  rightPossession = 'rightPossession',
  search = 'search',
}

export interface StudyFilterFields {
  patientFullName: string;
  patientGender: Array<NativeSelectOption>;
  seriesModality: Array<NativeSelectOption>;
  dicomDateTimeGte: string;
  dicomDateTimeLte: string;
  status: Array<NativeSelectOption>;
  urgencyLevel: Array<NativeSelectOption>;
  studyType: Array<NativeSelectOption>;
  practitionersAssignedUserId: Array<NativeSelectOption>;
  practitionerReviewerUserId: Array<NativeSelectOption>;
  seriesDicomOperatorsName: NativeSelectOption;
  facility: Array<NativeSelectOption>;
  reportsBirads: Array<NativeSelectOption>;
  patientAgeStart: string;
  patientAgeEnd: string;
  pacsStudyStartDate: string;
  pacsStudyEndDate: string;
  reportSignedStartDate: string;
  reportSignedEndDate: string;
  organizationSpecificFields?: Record<string, unknown>;
  rightPossession?: NativeSelectOption;
}

export interface StudyFilterFormProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Filters to apply to the query
   */
  filters?: StudyFilterFields;

  /**
   * Provide a handler that is called whenever the filter result button is clicked
   */
  onFilterBtnClick: (filters?: StudyFilterFields) => void;

  /**
   * Provide a handler that is called whenever the cancel button is clicked
   */
  onCancelBtnClick: () => void;
}

/**
 * Form to filter the study list.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-08-11
 */
export const StudyFilterForm: React.FC<StudyFilterFormProps> = ({
  className,
  filters,
  onFilterBtnClick,
  onCancelBtnClick,
}) => {
  const { t } = useTranslation();
  const { data: selectData } = useFilterFieldsQuery();
  const { data: organizationModalitiesData } = useOrganizationModalityQuery();

  const { hasFlag } = useFeatureFlagsStore();

  const isOrganizationSpecificFieldsEnabled = hasFlag(FeatureFlags.EnableOrganizationSpecificFields);
  const classes = {
    formClass: cn(className),
    patientNameField: cn({
      'col-span-2': isOrganizationSpecificFieldsEnabled,
      'col-span-4': !isOrganizationSpecificFieldsEnabled,
    }),
  };
  const [facilityList, setFacilityList] = useState<NativeSelectOption[]>([]);
  const [dicomOperatorsNameList, setDicomOperatorsNameList] = useState<NativeSelectOption[]>([]);
  const [modalitiesList, setModalitiesList] = useState<NativeSelectOption[]>([]);

  // Fill select lists
  useEffect(() => {
    const facilities = selectData?.facilities?.results;
    const modalities = organizationModalitiesData?.organizationModalities;
    const dicomOperatorsName = selectData?.seriesDistinct;

    if (facilities?.length) {
      const tmpFacilities = facilities?.map((facility) => {
        return {
          value: facility?.id,
          label: facility?.name,
        };
      });
      setFacilityList(tmpFacilities as NativeSelectOption[]);
    }

    if (modalities?.length) {
      const tmpModalities = modalities?.map((modality) => {
        return {
          value: modality?.id,
          label: formatModalityLabel(modality),
        };
      });
      setModalitiesList(tmpModalities as NativeSelectOption[]);
    }

    if (dicomOperatorsName?.length) {
      const tmpDicomOperatorsName = dicomOperatorsName?.map((dicomOperatorName) => {
        return {
          value: dicomOperatorName?.dicomOperatorsName,
          label: dicomOperatorName?.dicomOperatorsName,
        };
      });
      setDicomOperatorsNameList(tmpDicomOperatorsName as NativeSelectOption[]);
    }
  }, [selectData, organizationModalitiesData]);

  const schema = yup.object().shape({
    patientFullName: getTextField(t, false),
    patientFirstSurname: getTextField(t, false),
    patientLastSurname: getTextField(t, false),
    patientGender: yup.array().of(yup.object({ value: getTextField(t, false) })),
    facility: yup.array().of(yup.object({ value: getUUIDField(t, false) })),
    seriesModality: yup.array().of(yup.object({ value: getUUIDField(t, false) })),
    dicomDateTimeGte: getDateFormatField(t, false),
    dicomDateTimeLte: getDateFormatField(t, false),
    status: yup.array().of(yup.object({ value: getTextField(t, false) })),
    urgencyLevel: yup.array().of(yup.object({ value: getTextField(t, false) })),
    studyType: yup.array().of(yup.object({ value: getUUIDField(t, false) })),
    practitionersAssignedUserId: yup.array().of(yup.object({ value: getTextField(t, false) })),
    practitionerReviewerUserId: yup.array().of(yup.object({ value: getTextField(t, false) })),
    seriesDicomOperatorsName: yup.object({ value: getTextField(t, false) }),
    reportsBirads: yup.array().of(yup.object({ value: getTextField(t, false) })),
    patientAgeStart: yup.number().optional().nullable(),
    patientAgeEnd: yup
      .number()
      // eslint-disable-next-line i18next/no-literal-string
      .moreThan(yup.ref('patientAgeStart'), t('errors.formFields.maxAge'))
      .optional()
      .nullable(),
    pacsStudyStartDate: getDateFormatField(t, false),
    pacsStudyEndDate: getDateFormatField(t, false),
    reportSignedStartDate: getDateFormatField(t, false),
    reportSignedEndDate: getDateFormatField(t, false),
    rightPossession: yup.object({ value: getTextField(t, false) }),
  });

  const methods = useForm<StudyFilterFields>({
    resolver: yupResolver(schema),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = methods;

  const formIsEmpty = (fields: StudyFilterFields) => {
    if (fields === undefined) return true;

    return (
      fields.patientFullName === '' &&
      fields.patientGender === [] &&
      fields.seriesModality === [] &&
      fields.dicomDateTimeGte === '' &&
      fields.dicomDateTimeLte === '' &&
      fields.status === [] &&
      fields.facility === [] &&
      fields.practitionersAssignedUserId === [] &&
      fields.practitionerReviewerUserId === [] &&
      fields.studyType === [] &&
      fields.urgencyLevel === [] &&
      fields.seriesDicomOperatorsName.value === '' &&
      fields.reportsBirads === [] &&
      !fields.rightPossession?.value
    );
  };

  const onSubmit = (filters: StudyFilterFields) => {
    const response = formIsEmpty(filters) ? undefined : filters;
    if (onFilterBtnClick) onFilterBtnClick(response);
  };

  // TODO: Move this method to a common helper file
  // https://eva-pacs.atlassian.net/browse/EVA-3064
  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>, field, options: Array<NativeSelectOption>) => {
    const selectedOption = options.find((option) => option.value === event.target.value);
    field.onChange(selectedOption);
  };

  // TODO: Move this method to a common helper file
  // https://eva-pacs.atlassian.net/browse/EVA-3064
  const handleMultiSelectChange = (field, selectedOptions: Array<NativeSelectOption>) =>
    field.onChange(selectedOptions);

  const handleCancelBtnClick = () => {
    if (onCancelBtnClick) onCancelBtnClick();
  };

  // Adds "Not Assigned" filter option to the Practictioners list
  // at the very top.
  const formatPractitionerList = (list: NativeSelectOption[]) => [
    // eslint-disable-next-line i18next/no-literal-string
    { value: 'UNASSIGNED', label: t('study.filterFields.notAssigned') },
    ...list,
  ];

  const {
    options: assignablePractitioners,
    loading: loadingAssignablePractitioners,
  } = useGetAssignablePractitionerOptions({ useUserId: true });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const formattedPractitionersList = useMemo(() => formatPractitionerList(assignablePractitioners), [
    assignablePractitioners,
  ]);

  return (
    <FormProvider {...methods}>
      <form data-testid="studyFilterForm" className={classes.formClass} onSubmit={handleSubmit(onSubmit)}>
        <fieldset className="grid gap-6 grid-cols-8 px-2 text-left text-basic-white">
          {/* PATIENT FULLNAME */}
          <div className={classes.patientNameField}>
            <FormField
              name={StudyFilterName.patientFullName}
              defaultValue={filters?.patientFullName || ''}
              label={t('study.filterFields.patientName')}
              control={control}
              render={({ field }) => {
                return (
                  <BasicInput
                    autoFocus
                    id={field.name}
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    data-testid="filter-patientName"
                    placeholder={t('study.filterFields.patientName')}
                  />
                );
              }}
            />
          </div>

          {/* ORGANIZATION SPECIFIC FILTERS */}
          {isOrganizationSpecificFieldsEnabled && (
            <div className="col-span-2">
              <StudyFilterOrganizationSpecificFields filters={filters as StudyFilterFields} />
            </div>
          )}

          {/* FROM DATE */}
          <div className="col-span-2">
            <FormField
              className="mb-1"
              name={StudyFilterName.dicomDateTimeGte}
              defaultValue={filters?.dicomDateTimeGte || ''}
              label={t('study.filterFields.fromDicomDateTime')}
              control={control}
              render={({ field }) => {
                return (
                  <DatePickerInput
                    id={field.name}
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder={t('study.filterFields.shortFromDicomDateTime')}
                    isFullWidth
                  />
                );
              }}
            />
            {errors.dicomDateTimeGte && (
              <div className="text-feedback-negative-500 text-caption">{errors.dicomDateTimeGte.message}</div>
            )}
          </div>

          {/* TO DATE */}
          <div className="col-span-2">
            <FormField
              className="mb-1"
              name={StudyFilterName.dicomDateTimeLte}
              defaultValue={filters?.dicomDateTimeLte || ''}
              label={t('study.filterFields.toDicomDateTime')}
              control={control}
              render={({ field }) => {
                return (
                  <DatePickerInput
                    id={field.name}
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder={t('study.filterFields.shortToDicomDateTime')}
                    isFullWidth
                  />
                );
              }}
            />
            {errors.dicomDateTimeLte && (
              <div className="text-feedback-negative-500 text-caption">{errors.dicomDateTimeLte.message}</div>
            )}
          </div>

          {/* MIN PATIENT AGE */}
          <div className="col-span-2">
            <FormField
              name={StudyFilterName.patientAgeStart}
              defaultValue={filters?.patientAgeStart || null}
              label={t('study.filterFields.minAge')}
              control={control}
              render={({ field }) => (
                <BasicInput
                  id={field.name}
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  type="number"
                  min="0"
                  data-testid="filter-patientAgeStart"
                  placeholder={t('study.filterFields.minAge')}
                />
              )}
            />
          </div>

          {/* MAX PATIENT AGE */}
          <div className="col-span-2">
            <FormField
              name={StudyFilterName.patientAgeEnd}
              defaultValue={filters?.patientAgeEnd || null}
              label={t('study.filterFields.maxAge')}
              control={control}
              render={({ field }) => (
                <BasicInput
                  id={field.name}
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  type="number"
                  min="0"
                  data-testid="filter-patientAgeEnd"
                  placeholder={t('study.filterFields.maxAge')}
                />
              )}
            />
            {errors.patientAgeEnd && (
              <div className="text-feedback-negative-500 text-caption">{errors.patientAgeEnd.message}</div>
            )}
          </div>

          {/* PATIENT GENDER */}
          <div className="col-span-2">
            <FormField
              isFullWidth
              name={StudyFilterName.patientGender}
              defaultValue={filters?.patientGender || []}
              label={t('study.filterFields.patientGender')}
              control={control}
              render={({ field }) => {
                const { value } = field;
                const options = [
                  { label: t('admin.formFields.gender.options.male'), value: PatientGender.M },
                  { label: t('admin.formFields.gender.options.female'), value: PatientGender.F },
                  { label: t('admin.formFields.gender.options.other'), value: PatientGender.O },
                ];
                return (
                  <MultiSelect
                    placeholder={t('general.select')}
                    options={options}
                    name={field.name}
                    value={value}
                    onChange={(selectedOptions: Array<NativeSelectOption>) =>
                      handleMultiSelectChange(field, selectedOptions)
                    }
                  />
                );
              }}
            />
          </div>

          {/* FACILITY */}
          <div className="col-span-2">
            <FormField
              name={StudyFilterName.facility}
              defaultValue={filters?.facility || []}
              label={t('study.filterFields.facilityName')}
              control={control}
              render={({ field }) => {
                const { value } = field;
                return (
                  <MultiSelect
                    placeholder={t('general.select')}
                    options={facilityList}
                    name={field.name}
                    value={value}
                    onChange={(selectedOptions: Array<NativeSelectOption>) =>
                      handleMultiSelectChange(field, selectedOptions)
                    }
                  />
                );
              }}
            />
          </div>

          {/* STATUS */}
          <div className="col-span-2">
            <FormField
              name={StudyFilterName.status}
              defaultValue={filters?.status || []}
              label={t('study.filterFields.status')}
              control={control}
              render={({ field }) => {
                const { value } = field;
                const options = [
                  { label: getStudyStatusText(t, StudyStatus.READING), value: StudyStatus.READING },
                  { label: getStudyStatusText(t, StudyStatus.READING_PENDING), value: StudyStatus.READING_PENDING },
                  { label: getStudyStatusText(t, StudyStatus.IN_ADDENDUM), value: StudyStatus.IN_ADDENDUM },
                  { label: getStudyStatusText(t, StudyStatus.SIGNED), value: StudyStatus.SIGNED },
                  { label: getStudyStatusText(t, StudyStatus.ADDENDUM_SIGNED), value: StudyStatus.ADDENDUM_SIGNED },
                  { label: getStudyStatusText(t, StudyStatus.DELIVERED), value: StudyStatus.DELIVERED },
                  { label: getStudyStatusText(t, StudyStatus.UPLOADING), value: StudyStatus.UPLOADING },
                  { label: getStudyStatusText(t, StudyStatus.IMAGES_SENT), value: StudyStatus.IMAGES_SENT },
                  { label: getStudyStatusText(t, StudyStatus.REVIEW_PENDING), value: StudyStatus.REVIEW_PENDING },
                ];
                return (
                  <MultiSelect
                    dataTestId="filter-status"
                    placeholder={t('general.select')}
                    options={options}
                    name={field.name}
                    value={value}
                    onChange={(selectedOptions: Array<NativeSelectOption>) =>
                      handleMultiSelectChange(field, selectedOptions)
                    }
                  />
                );
              }}
            />
          </div>

          {/* DICOM OPERATOR NAME */}
          <div className="col-span-2">
            <FormField
              name={StudyFilterName.seriesDicomOperatorsName}
              defaultValue={filters?.seriesDicomOperatorsName || { label: '', value: '' }}
              label={t('study.filterFields.dicomOperatorsName')}
              control={control}
              render={({ field }) => {
                const { value } = field.value;
                return (
                  <NativeSelect
                    placeholder={t('general.select')}
                    options={dicomOperatorsNameList}
                    name={field.name}
                    value={value}
                    onChange={(event) => handleSelectChange(event, field, dicomOperatorsNameList)}
                  />
                );
              }}
            />
          </div>

          {/* PRACTITIONER ASSIGNED */}
          <div className="col-span-2">
            <FormField
              name={StudyFilterName.practitionersAssignedUserId}
              defaultValue={filters?.practitionersAssignedUserId || []}
              label={t('study.filterFields.practitionerAssigned')}
              control={control}
              render={({ field }) => {
                const { value } = field;
                return (
                  <MultiSelect
                    dataTestId="filter-practitionerAssigned"
                    placeholder={t('general.select')}
                    options={formattedPractitionersList}
                    name={field.name}
                    value={value}
                    isDisabled={loadingAssignablePractitioners}
                    onChange={(selectedOptions: Array<NativeSelectOption>) =>
                      handleMultiSelectChange(field, selectedOptions)
                    }
                  />
                );
              }}
            />
          </div>

          {/* REVIEWER PRACTITIONER */}
          <div className="col-span-2">
            <FormField
              name={StudyFilterName.practitionerReviewerUserId}
              defaultValue={filters?.practitionerReviewerUserId || []}
              label={t('study.filterFields.reviewerPractitioner')}
              control={control}
              render={({ field }) => {
                const { value } = field;
                return (
                  <MultiSelect
                    dataTestId="filter-reviewerPractitioner"
                    placeholder={t('general.select')}
                    options={formattedPractitionersList}
                    name={field.name}
                    value={value}
                    isDisabled={loadingAssignablePractitioners}
                    onChange={(selectedOptions: Array<NativeSelectOption>) =>
                      handleMultiSelectChange(field, selectedOptions)
                    }
                  />
                );
              }}
            />
          </div>

          {/* MODALITY */}
          <div className="col-span-4">
            <FormField
              isFullWidth
              name={StudyFilterName.seriesModality}
              defaultValue={filters?.seriesModality || []}
              label={t('study.filterFields.seriesModality')}
              control={control}
              render={({ field }) => {
                const { value } = field;
                return (
                  <MultiSelect
                    dataTestId="filter-modalities"
                    placeholder={t('general.select')}
                    options={modalitiesList}
                    name={field.name}
                    value={value}
                    onChange={(selectedOptions: Array<NativeSelectOption>) =>
                      handleMultiSelectChange(field, selectedOptions)
                    }
                  />
                );
              }}
            />
          </div>

          {/* URGENCY */}
          <div className="col-span-2">
            <FormField
              name={StudyFilterName.urgencyLevel}
              defaultValue={filters?.urgencyLevel || []}
              label={t('study.filterFields.urgencyLevel')}
              control={control}
              render={({ field }) => {
                const { value } = field;
                const options = [
                  { label: t('study.urgencyLevel.normal'), value: StudyUrgencyLevel.NORMAL },
                  { label: t('study.urgencyLevel.priority'), value: StudyUrgencyLevel.PRIORITY },
                  { label: t('study.urgencyLevel.urgent'), value: StudyUrgencyLevel.URGENT },
                ];
                return (
                  <MultiSelect
                    dataTestId="filter-urgencyLevel"
                    placeholder={t('general.select')}
                    options={options}
                    name={field.name}
                    value={value}
                    onChange={(selectedOptions: Array<NativeSelectOption>) =>
                      handleMultiSelectChange(field, selectedOptions)
                    }
                  />
                );
              }}
            />
          </div>

          {/* BIRADS */}
          <div className="col-span-2">
            <FormField
              name={StudyFilterName.reportsBirads}
              defaultValue={filters?.reportsBirads || []}
              label={t('study.filterFields.reportsBirads')}
              control={control}
              render={({ field }) => {
                const { value } = field;
                const options = [
                  { label: ReportBirads.BIRADS_0, value: ReportBirads.BIRADS_0 },
                  { label: ReportBirads.BIRADS_1, value: ReportBirads.BIRADS_1 },
                  { label: ReportBirads.BIRADS_2, value: ReportBirads.BIRADS_2 },
                  { label: ReportBirads.BIRADS_3, value: ReportBirads.BIRADS_3 },
                  { label: ReportBirads.BIRADS_4, value: ReportBirads.BIRADS_4 },
                  { label: ReportBirads.BIRADS_4A, value: ReportBirads.BIRADS_4A },
                  { label: ReportBirads.BIRADS_4B, value: ReportBirads.BIRADS_4B },
                  { label: ReportBirads.BIRADS_4C, value: ReportBirads.BIRADS_4C },
                  { label: ReportBirads.BIRADS_5, value: ReportBirads.BIRADS_5 },
                  { label: ReportBirads.BIRADS_6, value: ReportBirads.BIRADS_6 },
                  { label: ReportBirads.INADEQUATE, value: ReportBirads.INADEQUATE },
                ];
                return (
                  <MultiSelect
                    dataTestId="filter-reports-birads"
                    placeholder={t('general.select')}
                    options={options}
                    name={field.name}
                    value={value}
                    onChange={(selectedOptions: Array<NativeSelectOption>) =>
                      handleMultiSelectChange(field, selectedOptions)
                    }
                  />
                );
              }}
            />
          </div>

          {/* FROM UPLOAD DATE */}
          <div className="col-span-2">
            <FormField
              name={StudyFilterName.pacsStudyStartDate}
              defaultValue={filters?.pacsStudyStartDate || ''}
              label={t('study.filterFields.fromPacsStudyDateTime')}
              control={control}
              render={({ field }) => (
                <DatePickerInput
                  id={field.name}
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  isFullWidth
                />
              )}
            />
          </div>

          {/* TO UPLOAD DATE */}
          <div className="col-span-2">
            <FormField
              name={StudyFilterName.pacsStudyEndDate}
              defaultValue={filters?.pacsStudyEndDate || ''}
              label={t('study.filterFields.toPacsStudyDateTime')}
              control={control}
              render={({ field }) => (
                <DatePickerInput
                  id={field.name}
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  isFullWidth
                />
              )}
            />
          </div>

          {/* FROM SIGNED DATE */}
          <div className="col-span-2">
            <FormField
              name={StudyFilterName.reportSignedStartDate}
              defaultValue={filters?.reportSignedStartDate || ''}
              label={t('study.filterFields.fromReportSignedDateTime')}
              control={control}
              render={({ field }) => (
                <DatePickerInput
                  id={field.name}
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  isFullWidth
                />
              )}
            />
          </div>

          {/* TO SIGNED DATE */}
          <div className="col-span-2">
            <FormField
              name={StudyFilterName.reportSignedEndDate}
              defaultValue={filters?.reportSignedEndDate || ''}
              label={t('study.filterFields.toReportSignedDateTime')}
              control={control}
              render={({ field }) => (
                <DatePickerInput
                  id={field.name}
                  name={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  isFullWidth
                />
              )}
            />
          </div>
        </fieldset>

        <div className="flex justify-end mt-10">
          <Button className="mr-6" color={ButtonColor.primaryAlternative} onClick={handleCancelBtnClick} isRounded>
            {t('viewer.report.cancelBtn')}
          </Button>
          <Button type={ButtonType.submit} isRounded data-testid="filter-submit-button">
            {t('study.filterResults')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
