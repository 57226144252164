import React, { LegacyRef, forwardRef } from 'react';
import cn from 'classnames';

import { Row } from './Row';

interface SkeletonProps {
  showInTable?: boolean;
  numberOfItems?: number;
}

export const Skeleton = forwardRef<HTMLTableElement, SkeletonProps>(
  ({ showInTable = true, numberOfItems = 10 }, ref) => {
    const classes = cn('animate-pulse', {
      'py-2': !showInTable,
    });

    return (
      <>
        {Array.from({ length: numberOfItems }).map((_, index) => (
          <tr key={index} className={classes} ref={index === 0 ? (ref as LegacyRef<HTMLTableRowElement>) : null}>
            <Row showInTable={showInTable} />
          </tr>
        ))}
      </>
    );
  },
);
