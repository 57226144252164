import React from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmationModal, useModal } from '@evacenter/eden';

import { useGetReportIdsByStudyIdLazyQuery } from '@eva-pacs/client';

import { useStudyOptionMenuActions } from '~/hooks';

interface useDoubleSignatureModalProps {
  onConfirm?: () => void;
  isDoubleSignatureEnabled?: boolean;
  isStudyPendingReview?: boolean;
  studyId: string;
}

export const useDoubleSignedStudyModal = ({
  isDoubleSignatureEnabled,
  studyId,
  isStudyPendingReview,
  onConfirm,
}: useDoubleSignatureModalProps) => {
  const { modalNode, openModal } = useModal();
  const { t } = useTranslation();
  const {
    performRequireReviewReportAction,
    performToggleDoubleSignAction,
    performCancelRequireReviewReportAction,
  } = useStudyOptionMenuActions();
  const [getReportIds] = useGetReportIdsByStudyIdLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      const results = response?.reports?.results;
      if (!results?.length) return;
      const activeReport = results[0];

      // If the double signature is currently enabling, we should cancel it and then toggle it.
      if (isDoubleSignatureEnabled && isStudyPendingReview) {
        performCancelRequireReviewReportAction(studyId, activeReport?.id);
      } else {
        performRequireReviewReportAction(studyId, activeReport?.id);
      }

      performToggleDoubleSignAction(studyId);
    },
  });

  const handleConfirmation = async () => {
    const confirmation = await openModal<boolean>((close) => (
      <ConfirmationModal
        header={{ hasCloseBtn: true, title: t('study.studyTable.tableActions.signedStudyAlertTitle') }}
        description={t('study.studyTable.tableActions.signedStudyAlertDescription')}
        cancelBtnLabel={t('admin.formActions.cancel')}
        confirmBtnLabel={t('general.actions.accept')}
        onClose={() => close(false)}
        onConfirm={() => {
          getReportIds({ variables: { studyId } });
          close(false);
          if (onConfirm) onConfirm();
        }}
      />
    ));

    return confirmation;
  };

  return {
    modalNode,
    handleConfirmation,
  };
};
