import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { StudyUrgencyLevel } from '@eva-pacs/client';
import { StatusMenu, StatusOption, StatusType } from '@eva-pacs/ui';

export interface PriorityCellProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * The initial value
   */
  defaultValue?: string;

  /**
   * Pass if StatusMenu should be disabled
   */
  isDisabled?: boolean;

  /**
   * The callback to get notified when a item was clicked.
   */
  onItemClick: (option: StatusOption) => void;
}

/**
 * Priority Cell (Table)
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-07-27
 */
export const PriorityCell: React.FC<PriorityCellProps> = ({ className, isDisabled, defaultValue, onItemClick }) => {
  const cellClass = cn(className);
  const { t } = useTranslation();
  const options = [
    { type: StatusType.positive, label: t('study.urgencyLevel.normal'), value: StudyUrgencyLevel.NORMAL },
    { type: StatusType.warning, label: t('study.urgencyLevel.priority'), value: StudyUrgencyLevel.PRIORITY },
    { type: StatusType.negative, label: t('study.urgencyLevel.urgent'), value: StudyUrgencyLevel.URGENT },
  ];
  const defaultOption = options.find((option) => option.value.toUpperCase() === defaultValue);

  const handleItemClick = (value: StatusOption) => {
    if (onItemClick) onItemClick(value);
  };

  return (
    <StatusMenu
      className={cellClass}
      defaultOption={defaultOption || options[0]}
      isDisabled={isDisabled}
      options={options}
      onItemClick={handleItemClick}
    />
  );
};
