import React from 'react';
import { useTranslation } from 'react-i18next';

import type { StudyRow } from '../studyList.d';

interface JoinStudiesDescriptionProps {
  /**
   * Target study to interact with.
   */
  study: StudyRow;

  /**
   * ReactNode to display as the cancel action at the bottom of the content.
   */
  children: React.ReactNode;
}

/**
 * Use this component in order to show or add description to the join studies content
 * @author Carlos Cuatin<carlos.cuatin@evacenter.com>
 * Created at 2023-22-09
 */
export const JoinStudiesDescription = ({ study, children }: JoinStudiesDescriptionProps) => {
  const { t } = useTranslation();

  return (
    <div className="e-flex e-flex-col e-p-4 e-bg-neutral-700 e-rounded-md e-space-y-4">
      <div className="e-text-neutral-50 e-font-medium e-text-base">{study.patient.name}</div>
      <div className="e-space-y-2">
        <div className="e-text-neutral-200 e-text-md e-font-medium">{t('study.studyFields.dicomDescription')}</div>
        {children}
      </div>
    </div>
  );
};
