import { useMemo } from 'react';

import { Subscription, useStudiesUpdateSubscription, useValidateStudyPermissionsLazyQuery } from '@eva-pacs/client';

import { useSessionStore } from '~/src/store';
import { NotificationType, useNotificationsStore } from '../../store/notifications-store';
import { UpdateEvent } from '~/src/hooks/worklist/useWorklistUpdates';
import { useStudyUpdatesOrchestrator } from './useStudyUpdatesOrchestrator';
import { useFeatureFlagsStore } from '~/src/store/flagsStore';
import { FeatureFlags } from '~/src/constants/featureFlags';

export enum StudyUpdateType {
  created = 'created',
  updated = 'updated',
  error = 'error',
}

export type StudyUpdateEvent = Record<string, StudyUpdateType>;

type StudiesUpdateSubscriptionEventType = {
  type: string;
  study_id: string;
  practitioner_assigned_id: string;
  old_practitioner_assigned_id: string;
  status: string;
  old_status: string;
  created_at: string;
  reviewer_practitioner_id: string;
  old_reviewer_practitioner_id: string;
};

type UseStudiesChangesHook = {
  studiesCreated: number;
};

export const useStudyListUpdates = (): UseStudiesChangesHook => {
  const WS_IS_ACTIVE = process.env.REACT_APP_WS_IS_ACTIVE === 'true';
  const { currentOrganizationId } = useSessionStore();
  const { getNotifications, setNotifications } = useNotificationsStore();
  const hasFlag = useFeatureFlagsStore((state) => state.hasFlag);
  const enableAutomaticWorklistUpdates = hasFlag(FeatureFlags.EnableAutomaticUpdatesWorklist);
  const { processStudy } = useStudyUpdatesOrchestrator();
  const currentAccount = useSessionStore((state) => state.getCurrentAccount);
  const practitionerId = currentAccount()?.practitionerId;

  const studyListUpdatesNotifications = getNotifications(NotificationType.StudyListUpdates) as StudyUpdateEvent;

  const [validateStudyPermission] = useValidateStudyPermissionsLazyQuery({
    onCompleted: (data) => {
      if (!data?.study?.id) return;
      // Register incoming events in the notifications store for created studies
      setNotifications<StudyUpdateEvent>({
        type: NotificationType.StudyListUpdates,
        payload: {
          [data?.study?.id]: StudyUpdateType.created,
        },
      });
    },
  });

  const studiesCreated = useMemo(() => {
    return Object.keys(studyListUpdatesNotifications).filter(
      (studyId) => studyListUpdatesNotifications?.[studyId] === StudyUpdateType.created,
    ).length;
  }, [studyListUpdatesNotifications]);

  useStudiesUpdateSubscription({
    skip: !WS_IS_ACTIVE,
    variables: {
      organizationId: currentOrganizationId,
    },
    onSubscriptionData: async ({ subscriptionData }) => {
      const { studiesUpdate } = (subscriptionData?.data ?? {}) as Pick<Subscription, 'studiesUpdate'>;
      const {
        type: eventType,
        study_id: studyId,
        practitioner_assigned_id: studyPractitionerId,
        old_practitioner_assigned_id: studyOldPractitionerId,
        status,
        old_status: oldStatus,
        created_at: createdAt,
        reviewer_practitioner_id: reviewerPractitionerId,
        old_reviewer_practitioner_id: oldReviewerPractitionerId,
      } = JSON.parse(studiesUpdate?.event ?? {}) as StudiesUpdateSubscriptionEventType;

      // only process updates when FF is enabled.
      if (enableAutomaticWorklistUpdates && eventType) {
        processStudy({
          studyPractitionerId,
          studyId,
          eventType: eventType as UpdateEvent,
          studyOldPractitionerId,
          userId: practitionerId,
          reviewerPractitionerId,
          oldReviewerPractitionerId,
          status,
          oldStatus,
          createdAt,
        });
      }

      // for study list.
      if (eventType === StudyUpdateType.created) {
        validateStudyPermission({
          variables: {
            studyId,
          },
        });
      }
    },
  });

  return {
    studiesCreated,
  };
};
