import React, { AnimationEvent, useEffect } from 'react';
import cn from 'classnames';
import { VirtualItem } from 'react-virtual';
import { Row, flexRender } from '@tanstack/react-table';

import { RowAnimation, addClassByDataId, removeClassByDataId } from '@evacenter/eden';

import { useStudyListStore } from '~/src/store';
import { Accessor } from '../useStudyTableColumns';

interface Column {
  [key: string]: string | any;
}

export interface RowItemProps {
  row: Row<Column>;
  virtualRow: VirtualItem;
  onDeleteRowAnimationEnd?: (rowId: string) => void;
}

export const RowItem = ({ row, virtualRow, onDeleteRowAnimationEnd }: RowItemProps) => {
  const clearStudyAnimation = useStudyListStore((state) => state.clearStudyAnimationEntrance);
  const classes = {
    row: cn('e-border-b e-border-neutral-700'),
    cell: (columnId: Accessor) =>
      cn('text-basic-white text-caption font-regular', 'px-3 py-2', {
        'sticky right-0 e-bg-neutral-900 text-center': columnId === Accessor.actionColumn,
        'text-left': columnId !== Accessor.actionColumn,
      }),
  };

  const cleanEntranceStudyAnimation = () => {
    clearStudyAnimation(row.id);

    // clear both possible animations.
    removeClassByDataId(`table-row-id`, row.id, `eva-fade-in-row-with-indicator-animation`);
    removeClassByDataId(`table-row-id`, row.id, `eva-fade-in-row-animation`);
  };

  useEffect(() => {
    // When unmounting from the DOM, clear the animation class to avoid side effects.

    if (row.original.enableEntranceAnimation) {
      if (row.original.isSwapStudy) return addClassByDataId(`table-row-id`, row.id, `eva-fade-in-row-animation`);

      addClassByDataId(`table-row-id`, row.id, `eva-fade-in-row-with-indicator-animation`);
    }

    return () => {
      cleanEntranceStudyAnimation();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAnimationEnd = (event: AnimationEvent<HTMLTableRowElement>, rowId: string) => {
    if (event.animationName === RowAnimation.REMOVE_LEFT_BORDER) return cleanEntranceStudyAnimation();

    if (event.animationName !== RowAnimation.HIDE_ROW) return;

    if (onDeleteRowAnimationEnd) onDeleteRowAnimationEnd(rowId);
  };

  return (
    <tr
      data-table-row-id={row.id}
      className={classes.row}
      style={{
        height: `${virtualRow.size}px`,
      }}
      onAnimationEnd={(event: AnimationEvent<HTMLTableRowElement>) => handleAnimationEnd(event, row.id)}>
      {row.getVisibleCells().map((cell: any) => {
        return (
          <td
            key={cell.id}
            className={classes.cell(cell.column.id)}
            style={{ minWidth: cell.column.getSize(), maxWidth: cell.column.getSize() }}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        );
      })}
    </tr>
  );
};
