import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { SupportChatButton } from '~/components/SupportChatButton/SupportChatButton';

interface WorklistEmptyStateProps {
  showWorklistEmptyState: boolean;
  isChatAvailable: boolean;
}

export const WorklistEmptyState = ({ showWorklistEmptyState, isChatAvailable }: WorklistEmptyStateProps) => {
  const { t } = useTranslation();

  const classes = {
    chatButton: cn('shadow-lg'),
    worklistVideo: cn(
      'e-w-full transition-opacity duration-1000 absolute e-right-0 e-bottom-0 min-w-screen -e-z-1 min-h-screen object-cover',
      {
        'opacity-100': showWorklistEmptyState,
        'opacity-0': !showWorklistEmptyState,
      },
    ),
    motivationalMilestone: cn('e-bottom-0 absolute -e-z-1 transition-opacity duration-1000', {
      'opacity-100': showWorklistEmptyState,
      'opacity-0': !showWorklistEmptyState,
    }),
    supportChatbutton: cn(
      'e-mr-2 absolute bottom-0 right-0 flex justify-end pointer-events-none transition-opacity duration-1000 rounded-t-lg',
      {
        'opacity-100': showWorklistEmptyState,
        'opacity-0': !showWorklistEmptyState,
      },
    ),
  };

  return (
    <>
      <video className={classes.worklistVideo} autoPlay muted loop playsInline controls={false} id="myVideo">
        <source src="https://cdn-assets.evacenter.com/videos/worklist/vid-dia-2.webm" type="video/webm"></source>
        <source src="https://cdn-assets.evacenter.com/videos/worklist/vid-dia-2.mp4" type="video/mp4"></source>
      </video>
      <div className={classes.motivationalMilestone}>
        <div className="e-ml-10 e-mb-20 md:e-mb-10">
          {' '}
          <p className="e-text-base-white e-text-base e-text-base md:e-text-xl">
            {t('study.worklist.emptyStateTitleLegend')}
          </p>
          <p className="e-mb-3 e-text-base-white e-text-base e-text-base md:e-text-xl md:e-mt-2">
            {t('study.worklist.emptyStateSubtitleLegend')}
          </p>
          <p className="e-text-neutral-50 e-text-xs md:e-text-lg">{t('study.worklist.emptyStateDescription')}</p>
        </div>
      </div>
      {isChatAvailable && showWorklistEmptyState && (
        <div className={classes.supportChatbutton}>
          <SupportChatButton className={classes.chatButton} />
        </div>
      )}
    </>
  );
};
