import React, { FC } from 'react';
import cn from 'classnames';

import { Sizes } from '../useStudyTableColumns';

interface RowProps {
  showInTable?: boolean;
}

export const Row: FC<RowProps> = ({ showInTable = true }) => {
  const classes = {
    paddingAction: cn('text-left', {
      'px-3 py-2': showInTable,
      'px-4 py-3': !showInTable,
    }),
    padding: cn('text-left', {
      'px-3 py-2': showInTable,
      'px-4 py-3': !showInTable,
    }),
  };

  return (
    <>
      <td className={classes.paddingAction} style={{ minWidth: Sizes.selection, maxWidth: Sizes.selection }}>
        <div className="e-bg-neutral-600 w-4 h-4 rounded-sm" />
      </td>
      <td className={`${classes.padding} space-y-2`} style={{ minWidth: Sizes.patient, maxWidth: Sizes.patient }}>
        <div className="e-bg-neutral-600 w-24 h-4 rounded-sm" />
        <div className="e-bg-neutral-600 w-8 h-4 rounded-sm" />
      </td>
      <td className={classes.padding} style={{ minWidth: Sizes.modalities, maxWidth: Sizes.modalities }}>
        <div className="e-bg-neutral-600 w-16 h-4 rounded-sm" />
      </td>
      <td className={classes.padding} style={{ minWidth: Sizes.dicomDescription, maxWidth: Sizes.dicomDescription }}>
        <div className="e-bg-neutral-600 w-40 h-4 rounded-sm" />
      </td>
      <td className={classes.padding} style={{ minWidth: Sizes.facility, maxWidth: Sizes.facility }}>
        <div className="e-bg-neutral-600 w-20 h-4 rounded-sm" />
      </td>
      <td
        className={classes.padding}
        style={{ minWidth: Sizes.practitionerAssigned, maxWidth: Sizes.practitionerAssigned }}>
        <div className="e-bg-neutral-600 w-48 h-4 rounded-sm" />
      </td>
      <td
        className={classes.padding}
        style={{ minWidth: Sizes.referringPractitioner, maxWidth: Sizes.referringPractitioner }}>
        <div className="e-bg-neutral-600 w-48 h-4 rounded-sm" />
      </td>
      <td className={classes.padding} style={{ minWidth: Sizes.status, maxWidth: Sizes.status }}>
        <div className="e-bg-neutral-600 w-16 h-4 rounded-sm" />
      </td>
      <td className={classes.padding} style={{ minWidth: Sizes.urgencyLevel, maxWidth: Sizes.urgencyLevel }}>
        <div className="e-bg-neutral-600 w-16 h-4 rounded-sm" />
      </td>
      <td
        className={classes.padding}
        style={{ minWidth: Sizes.dicomDateTimeEditable, maxWidth: Sizes.dicomDateTimeEditable }}>
        <div className="e-bg-neutral-600 w-16 h-4 rounded-sm" />
      </td>
      <td className={classes.padding} style={{ minWidth: Sizes.createdAt, maxWidth: Sizes.createdAt }}>
        <div className="e-bg-neutral-600 w-16 h-4 rounded-sm" />
      </td>
      <td
        className={`${classes.paddingAction} sticky right-0 text-center e-bg-neutral-900`}
        style={{ minWidth: Sizes.actionColumn, maxWidth: Sizes.actionColumn }}>
        <div className="e-bg-neutral-600 w-8 h-8 rounded-lg" />
      </td>
    </>
  );
};
