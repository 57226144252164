import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Badge, BadgeColor, BadgeSize } from '@eva-pacs/ui';

import { Status, StatusLabel } from '~/pages/StudyList/StudyList';

export interface KpisSectionProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * historic value
   */
  historicCount?: number;

  /**
   * normal value
   */
  normalCount?: number;

  /**
   * normal no report value
   */
  normalNoReportCount?: number;

  /**
   * priority count value
   */
  priorityCount?: number;

  /**
   * priority no report value
   */
  priorityNoReportCount?: number;

  /**
   * urgent count value
   */
  urgentCount?: number;

  /**
   * urgent no report count value
   */
  urgentNoReportCount?: number;
}

/**
 * KPI section (Study List)
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-10-08
 */
export const KpisSection: React.FC<KpisSectionProps> = ({
  historicCount = 0,
  normalCount = 0,
  normalNoReportCount = 0,
  priorityCount = 0,
  priorityNoReportCount = 0,
  urgentCount = 0,
  urgentNoReportCount = 0,
  className,
}) => {
  const { t } = useTranslation();
  const kpisSectionClass = cn('flex items-center justify-items-center md:justify-items-start mb-4', className);

  const renderStatusPanelKPI = (status: Status, value: number, noReportAmount: number, dataTestId: string) => {
    let statusColor = BadgeColor.positive;
    let statusLabel = StatusLabel.active;
    switch (status) {
      case Status.active:
        statusColor = BadgeColor.positive;
        statusLabel = StatusLabel.active;
        break;
      case Status.pending:
        statusColor = BadgeColor.warning;
        statusLabel = StatusLabel.pending;
        break;
      case Status.suspended:
        statusColor = BadgeColor.negative;
        statusLabel = StatusLabel.suspended;
        break;
    }

    return (
      <div className="flex" data-testid={dataTestId}>
        <Badge className="mr-2 mt-1" size={BadgeSize.sm} color={statusColor} />
        <div className="flex flex-col">
          <div className="flex items-center mb-2">
            <span className="text-basic-white text-label-md font-semi-bold">
              <span className="mr-1">{statusLabel}: </span>
              <span className="text-label-lg">{value}</span>
            </span>
          </div>
          <div className="flex items-center">
            <span className="mr-1 text-light-gray-600 text-label-sm">{t('study.kpiSection.statusTitle')}:</span>
            <span className="text-basic-white text-label-sm">{noReportAmount}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section className={kpisSectionClass} data-testid="kpis-section">
      <div className="flex flex-row items-center mr-0 md:flex-col md:items-start md:mr-12">
        <span className="mb-0 text-light-gray-600 text-label-md md:mb-2.5">{t('study.kpiSection.title')}</span>
        <span className="ml-4 text-basic-white text-h4 font-bold leading-none md:ml-0" data-testid="historic-count">
          {historicCount}
        </span>
      </div>
      <div className="hidden items-center space-x-8 md:flex md:items-start">
        {/* eslint-disable-next-line i18next/no-literal-string */}
        {renderStatusPanelKPI(Status.suspended, urgentCount, urgentNoReportCount, 'kpis-section-urgent')}
        {/* eslint-disable-next-line i18next/no-literal-string */}
        {renderStatusPanelKPI(Status.pending, priorityCount, priorityNoReportCount, 'kpis-section-priority')}
        {/* eslint-disable-next-line i18next/no-literal-string */}
        {renderStatusPanelKPI(Status.active, normalCount, normalNoReportCount, 'kpis-section-normal')}
      </div>
    </section>
  );
};
