import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react';
import cn from 'classnames';
import shallow from 'zustand/shallow';
import { useVirtual } from 'react-virtual';
import { useTranslation } from 'react-i18next';
import { Icon, IconCatalog } from '@evacenter/eden';
import { Breakpoint, isMobileDevice, useBreakpoint } from '@eva-pacs/core';
import { ColumnDef, flexRender, Row, HeaderGroup } from '@tanstack/react-table';

import { useStudyListStore } from '~/src/store';

import { StudyTableProps } from '.';
import { Skeleton } from './Skeleton';
import { RowItem } from './Rows/RowItem';
import { Accessor } from './useStudyTableColumns';
import { StudyTableNotificationRow } from './StudyListTableNotificationRow';

const HEIGHT_ROW = 80;
const STUDY_TABLE_COLUMNS_OFFSET = 1;

interface Column {
  [key: string]: string | any;
}

interface TableProps extends Omit<StudyTableProps, 'data' | 'onTableLoad'> {
  className?: string;
  hasNextPage: boolean;
  rows: Array<Row<Column>>;
  headers: HeaderGroup<Column>[];
  columns: Array<ColumnDef<Column, any>>;
}

export const Table = ({
  rows,
  columns,
  headers,
  rootRef,
  className,
  sentryRef,
  hasNextPage,
  hasPreviousPage,
  isEmptyFilterFields = true,
  onTableNotificationRowClick,
}: TableProps) => {
  const { t } = useTranslation();
  const breakpoint = useBreakpoint();
  const lastScrollDistanceToBottomRef = useRef<number>();
  const scrollableRootRef = useRef<HTMLDivElement | null>(null);
  const [reverse, setEnableGoToTop, setEnableGoToBottom, enabledInfiniteScroll] = useStudyListStore(
    (state) => [state.reverse, state.setEnableGoToTop, state.setEnableGoToBottom, state.enabledInfiniteScroll],
    shallow,
  );
  const { virtualItems: virtualRows, totalSize } = useVirtual({
    overscan: 5,
    size: rows.length,
    parentRef: scrollableRootRef,
    estimateSize: useCallback(() => HEIGHT_ROW, []),
  });

  const totalHeight = STUDY_TABLE_COLUMNS_OFFSET * HEIGHT_ROW;
  const showEndOfList = enabledInfiniteScroll && isEmptyFilterFields && (!hasNextPage || reverse);
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom = virtualRows.length > 0 ? totalSize - virtualRows?.[virtualRows.length - 1]?.end || 0 : 0;

  const isBreakpointMobile = useMemo(() => [Breakpoint.xs, Breakpoint.sm, Breakpoint.md].includes(breakpoint), [
    breakpoint,
  ]);
  const isMobile = useMemo(() => isMobileDevice() || isBreakpointMobile, [isBreakpointMobile]);

  const rootRefSetter = useCallback(
    (node: HTMLDivElement) => {
      if (!isMobile) rootRef(node);
      scrollableRootRef.current = node;
    },
    [isMobile, rootRef],
  );

  const handleRootScroll = useCallback(() => {
    const rootNode = scrollableRootRef.current;
    if (rootNode) {
      let goToTop = false;
      let goToBottom = false;
      let scrollDistanceToBottom = 0;
      const { scrollTop, scrollHeight, clientHeight } = rootNode;

      if (isMobile) {
        goToTop = window.scrollY > totalHeight;
        scrollDistanceToBottom = document.body.scrollHeight - window.innerHeight - window.scrollY;
        goToBottom = scrollDistanceToBottom > totalHeight;
      } else {
        goToTop = scrollTop > totalHeight;
        scrollDistanceToBottom = scrollHeight - scrollTop;
        goToBottom = scrollDistanceToBottom - clientHeight > totalHeight;
      }

      setEnableGoToTop(reverse ? true : goToTop);
      setEnableGoToBottom(reverse ? goToBottom : true);
      lastScrollDistanceToBottomRef.current = scrollDistanceToBottom;
    }
  }, [isMobile, setEnableGoToTop, reverse, setEnableGoToBottom, totalHeight]);

  useLayoutEffect(() => {
    const scrollableRoot = scrollableRootRef.current;
    if (reverse && scrollableRoot) {
      const lastScrollDistanceToBottom = lastScrollDistanceToBottomRef.current ?? 0;
      if (isMobile) {
        window.scrollTo(0, scrollableRoot.scrollHeight - lastScrollDistanceToBottom);
      } else {
        scrollableRoot.scrollTop = scrollableRoot.scrollHeight - lastScrollDistanceToBottom;
      }
    }
  }, [reverse, isMobile, rows]);

  useLayoutEffect(() => {
    if (isMobile) {
      window.addEventListener('scroll', handleRootScroll);
    } else {
      scrollableRootRef.current?.addEventListener('scroll', handleRootScroll);
    }
    return () => {
      if (isMobile) {
        window.removeEventListener('scroll', handleRootScroll);
      } else {
        scrollableRootRef.current?.removeEventListener('scroll', handleRootScroll);
      }
    };
  }, [handleRootScroll, isMobile]);

  const classes = {
    container: cn(className, 'table-scrollbar flex-grow overflow-auto mb-14'),
    header: (columnId: Accessor) =>
      cn('relative p-3', 'text-left text-basic-white text-label-sm font-medium', {
        'sticky right-0 e-bg-neutral-900': columnId === Accessor.actionColumn,
      }),
  };

  return (
    <div ref={rootRefSetter} id="studies-section" className={classes.container}>
      <table data-testid="table-study-list" className="relative w-full border-collapse">
        <thead className="e-bg-neutral-900 sticky z-2 -top-0.5">
          {headers.map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header: Column) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className={classes.header(header.id)}
                  style={{ minWidth: header.getSize(), maxWidth: header.getSize() }}>
                  {/*eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                  <div
                    tabIndex={0}
                    role="button"
                    onClick={header.column.getToggleSortingHandler()}
                    className={header.column.getCanSort() ? 'cursor-pointer select-none' : ''}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    <CaretIcons column={header.column} />
                  </div>
                  {/* BORDER BOTTOM */}
                  <div className="absolute bottom-0 left-0 block w-full h-0.5 bg-gray-600" />
                </th>
              ))}
            </tr>
          ))}
          <StudyTableNotificationRow
            isInfiniteScrollEnabled={enabledInfiniteScroll}
            colSpan={columns.length + STUDY_TABLE_COLUMNS_OFFSET}
            onNotificationRowClick={onTableNotificationRowClick}
          />
        </thead>
        <tbody style={{ height: `${totalSize}px` }}>
          {enabledInfiniteScroll && hasPreviousPage && reverse && (
            <Skeleton data-testid="skeleton-study-list" ref={sentryRef} numberOfItems={3} />
          )}
          {paddingTop > 0 && <tr style={{ height: `${paddingTop}px` }} />}
          {virtualRows.map((virtualRow) => {
            const row = rows[virtualRow.index];
            return <RowItem row={row} key={row.id} virtualRow={virtualRow} />;
          })}
          {paddingBottom > 0 && <tr style={{ height: `${paddingBottom}px` }} />}
          {enabledInfiniteScroll && hasNextPage && !reverse && (
            <Skeleton data-testid="skeleton-study-list" ref={sentryRef} numberOfItems={3} />
          )}
        </tbody>
        {showEndOfList && (
          <tfoot>
            <tr>
              <td colSpan={columns.length + STUDY_TABLE_COLUMNS_OFFSET}>
                <div className="e-pt-8 e-pb-8 e-w-full e-text-center e-text-neutral-300 e-text-base">
                  {t('study.studyTable.totalStudies')}
                </div>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

interface CaretIconProps {
  column: Column;
}

const CaretIcons = ({ column }: CaretIconProps) => {
  if ([Accessor.actionColumn, Accessor.selection].includes(column.id)) return <></>;
  if (!column.getIsSorted())
    return (
      <span className="absolute inline-flex flex-col text-gray-100 -space-y-4" style={{ bottom: '5px' }}>
        <Icon icon={IconCatalog.caretUp} width="24" height="24" />
        <Icon icon={IconCatalog.caretDown} width="24" height="24" />
      </span>
    );

  if (column.getIsSorted() === 'desc')
    return (
      <span className="absolute inline-flex flex-col text-gray-100 -space-y-4" style={{ bottom: '7px' }}>
        <Icon icon={IconCatalog.caretDown} width="24" height="24" />
      </span>
    );

  return (
    <span className="absolute inline-flex flex-col text-gray-100 -space-y-4" style={{ bottom: '7px' }}>
      <Icon icon={IconCatalog.caretUp} width="24" height="24" />
    </span>
  );
};
