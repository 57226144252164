import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import shallow from 'zustand/shallow';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modals, useModal, Family } from '@eva-pacs/core';
import { useTrackVisibility } from 'react-intersection-observer-hook';
import { StudyStatisticsType, useStudiesStatisticsLazyQuery, useDeleteUserPreferenceMutation } from '@eva-pacs/client';

import { useErrorHandler } from '~/utils/appHelpers';
import { CAN_USE_WORKLIST, POLICIES } from '~/constants';
import { useOrganizationSpecificFieldsFilter } from '~/src/hooks/filters/useOrganizationSpecificFieldsFilters';
import { AppliedFilters, AppliedFilterOption } from '~/components/StudyList/AppliedFilters';
import { StudyFilterFields, StudyFilterName } from '~/components/StudyList/StudyFilterForm';
import { NotificationType, useNotificationsStore } from '~/src/store/notifications-store';
import { useLoadProductPermissionGroups, useStudyList, useStudyTableUtils } from '~/hooks';
import { usePostMessageListener } from '~/src/hooks/usePostMessageListener';
import { FilterStudyModal } from '~/components/StudyList/FilterStudyModal';
import { StudyListActions } from '~/components/StudyList/StudyListActions';
import { useSearchParams, PARAMS_KEYS } from '~/src/hooks/useSearchParams';
import { SearchBar } from '~/components/StudyList/SearchBar/SearchBar';
import { useUserFilters } from '~/src/hooks/filters/useUserFilters';
import { CrossPacsActionType } from '~/src/constants/eventMessage';
import { KpisSection } from '~/components/StudyList/KpisSection';
import { StudyTable } from '~/components/StudyList/StudyTable';
import { useFeatureFlagsStore } from '~/src/store/flagsStore';
import { FeatureFlags } from '~/src/constants/featureFlags';
import { useFilters } from '~/src/hooks/filters/useFilters';
import { useSessionStore, useStudyListStore } from '~/src/store';
import { Onboarding } from '~/components/Onboarding';
import { Footer } from '~/components/StudyList/Footer';
import { useStudyActionsStore } from '~/components/StudyList/studyActionsStore';
import { useSwitchListType } from '~/src/hooks/studyList/useSwitchListType';

export const STUDY_ORGANIZATION_SPECIFIC_FILTER_KEYS = [StudyFilterName.rightPossession];

export enum Status {
  active = 'active',
  pending = 'pending',
  suspended = 'suspended',
}

export enum StatusLabel {
  active = 'Normales',
  pending = 'Prioritarios',
  suspended = 'Urgentes',
}

/**
 * This is the entry page for the users. The user can see the study list on this page.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-07-19
 */
let timeout;

const TypeaheadDebounceTime = 1000;
const StudyListPage: React.FC = () => {
  /*------------------*/
  /*  INIT VARIABLES  */
  /*------------------*/
  const history = useHistory();
  const { t } = useTranslation();
  const { rows } = useStudyTableUtils();
  const user = useSessionStore((state) => state.user);
  const { loadProductPermissionGroups } = useLoadProductPermissionGroups();
  const [searchBy, setSearchBy] = useState(
    Object.fromEntries(new URLSearchParams(window.location.search).entries()).q ?? null,
  );
  const [totalCount, setTableSortBy, currentPage] = useStudyListStore(
    (state) => [state.totalCount, state.setTableSortBy, state.currentPage],
    shallow,
  );

  const searchParams = useSearchParams();

  const { setCurrentModal } = useModal();
  const { handleError } = useErrorHandler();
  const hasFlag = useFeatureFlagsStore((state) => state.hasFlag);
  const isChatAvailable = hasFlag(FeatureFlags.CustomerSupportChat);
  const [filterFields, setFilterFields] = useState<StudyFilterFields>();
  const clearNotifications = useNotificationsStore((state) => state.clearNotifications);
  const [studiesStatisticResponse, setStudiesStatisticResponse] = useState<StudyStatisticsType>();
  const [joinedStudies, setJoinedStudies] = useStudyActionsStore(
    (state) => [state.joinedStudies, state.setJoinedStudies],
    shallow,
  );

  const isWorkListEnabled = hasFlag(FeatureFlags.EnableNewWorklistSection);
  const canUseWorklist = POLICIES[CAN_USE_WORKLIST](user);

  const enableWorklistStyles = isWorkListEnabled && canUseWorklist;

  const classes = {
    studyListContainer: cn('bottom-0 left-0 right-0 flex flex-col pt-6 px-2 lg:fixed lg:pb-0 lg:px-8', {
      'top-36': enableWorklistStyles,
      'top-20': !enableWorklistStyles,
    }),
  };

  /*
   * Setup `message` listener
   */
  usePostMessageListener({
    actionType: CrossPacsActionType.REFRESH_STUDY_LIST,
    onMessageReceived: () => handleRefresh(),
  });

  const isOrganizationSpecificFieldsEnabled = hasFlag(FeatureFlags.EnableOrganizationSpecificFields);
  const showStatisticsMetricsEnabled = hasFlag(FeatureFlags.ShowStudyStatisticsMetrics);

  /**
   * Init KPIs
   */
  // const [timeout, setInternalTimeout] = useState<number>();

  /**
   * Init Filters states
   */
  const [appliedFilters, setAppliedFilters] = useState<Array<AppliedFilterOption>>([]);

  const parseSortBy = (sortBy) => {
    if (!sortBy) return '';
    // eslint-disable-next-line i18next/no-literal-string
    return sortBy.length !== 0 ? `${sortBy[0].id},${sortBy[0].desc ? 'desc' : 'asc'}` : '';
  };

  const { handleSwitchListType } = useSwitchListType({ onSuccess: () => handleRefresh() });

  const [sentryRef, { isVisible, rootRef }] = useTrackVisibility({
    rootMargin: `480px 0px 480px 0px`,
  });

  const {
    loading,
    pageSize,
    reloadData,
    hasNextPage,
    handleReset,
    hasPreviousPage,
    initialLoading,
    handleSetCurrentPage,
  } = useStudyList({ filterFields, isVisible: isVisible, searchBy });

  const [fetchStudiesStatistics] = useStudiesStatisticsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data.studiesStatistics) return null;
      setStudiesStatisticResponse(data.studiesStatistics);
    },
  });

  const { getUserFilters, getUserFilterByKey, userFilters, loading: loadingUserFilters } = useUserFilters({
    onCompleted: (userPreferences) => processFilters(userPreferences),
  });

  const { updateFilter, createFilter, removeAllFilters } = useFilters({
    onSuccess: () => handleRefresh(),
  });

  const searchFilter = useMemo(() => {
    if (userFilters) return getUserFilterByKey(StudyFilterName.search);
    return undefined;
  }, [userFilters, getUserFilterByKey]);

  useEffect(() => {
    const noEmptySearchAndPreference = searchFilter && searchBy !== null;
    const preferenceShouldBeCreated = !searchFilter && searchBy && searchBy.length > 0 && !loadingUserFilters;
    const isInitialStateAndPreferenceExists = searchBy === null && searchFilter;

    // had to double assert on some assertions below due to prettier/linting/compilation rules coming out different in unit tests pipeline compile errors

    if (noEmptySearchAndPreference) {
      const isSearchTermEmpty = searchBy.length < 1;
      const preferenceAndSearchDiffer =
        searchBy.length > 0 && searchFilter && JSON.parse(searchFilter!.value) !== searchBy;

      if (preferenceAndSearchDiffer) {
        updateFilter(StudyFilterName.search, searchBy, searchFilter!.id);
      }

      if (isSearchTermEmpty) {
        deleteUserPreferenceMutation({
          variables: {
            id: searchFilter!.id,
          },
        });
      }
    }

    if (preferenceShouldBeCreated) {
      createFilter(StudyFilterName.search, searchBy);
    }

    if (isInitialStateAndPreferenceExists) {
      const { value } = searchFilter!;
      const parsedValue = JSON.parse(value);
      setSearchBy(parsedValue);
      // @ts-expect-error
      searchParams.set(PARAMS_KEYS.SEARCH, parsedValue);
      history.replace({
        search: searchParams.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBy, searchFilter, setSearchBy]);

  const {
    processOrganizationSpecificFilters,
    removeOrganizationSpecificFilter,
    saveOrganizationSpecificFilters,
  } = useOrganizationSpecificFieldsFilter({
    onSuccess: () => handleRefresh(),
  });

  const [deleteUserPreferenceMutation] = useDeleteUserPreferenceMutation({
    onCompleted: ({ deleteUserPreference: { success } }) => {
      if (success) handleRefresh();
    },
    onError: (error) => handleError({ logMessage: error }),
  });

  const processFilters = (preferences) => {
    const filters = parsePreferencesToFilters(preferences);
    const studyFilters = Object.fromEntries(filters) as StudyFilterFields;
    const { organizationSpecificFields, ...restFilters } = studyFilters;

    const organizationSpecificFilters = processOrganizationSpecificFilters(organizationSpecificFields);

    setFilterFields({
      ...restFilters,
      ...organizationSpecificFilters,
    });
  };

  const parsePreferencesToFilters = (preferences) => {
    return preferences
      .filter((preference) => preference.family === Family.filter)
      .map((filter) => [filter.key, JSON.parse(filter.value)]);
  };

  const removeFilter = async (filter: AppliedFilterOption) => {
    // Remove an organization specific filter
    if (STUDY_ORGANIZATION_SPECIFIC_FILTER_KEYS.includes(filter?.key)) return removeOrganizationSpecificFilter(filter);

    const filterToDelete = getUserFilterByKey(filter.key);

    deleteUserPreferenceMutation({
      variables: {
        id: filterToDelete?.id,
      },
    });
  };

  const saveFilters = (filterKeys: Array<string>, fields: StudyFilterFields) => {
    filterKeys?.forEach(async (key) => {
      const filterToSave = getUserFilterByKey(key);
      if (filterToSave) updateFilter(key, fields[key], filterToSave.id);
      if (!filterToSave) createFilter(key, fields[key]);
    });
  };

  const parseFieldsToFilters = (filters?: StudyFilterFields): Array<AppliedFilterOption> => {
    if (!filters) return [];

    const filtersList: Array<AppliedFilterOption> = [];
    const SEPARATOR = ', ';

    for (const [key, value] of Object.entries(filters)) {
      if (value === null) continue;
      if (typeof value === 'string' && value === '') continue;
      if (typeof value === 'object' && (value?.value === '' || value?.value === null)) continue;
      if (Array.isArray(value) && value.length === 0) continue;

      switch (key) {
        case StudyFilterName.patientFullName:
          filtersList.push({ label: t('study.filterFields.patientName'), value, key });
          break;

        case StudyFilterName.patientGender: {
          const textList = value.map((elem) => elem.label);
          filtersList.push({ label: t('study.filterFields.patientGender'), value: textList.join(SEPARATOR), key });
          break;
        }

        case StudyFilterName.seriesModality: {
          const textList = value.map((elem) => elem.label);
          filtersList.push({ label: t('study.filterFields.seriesModality'), value: textList.join(SEPARATOR), key });
          break;
        }

        case StudyFilterName.dicomDateTimeGte:
          filtersList.push({ label: t('study.filterFields.shortFromDicomDateTime'), value, key });
          break;

        case StudyFilterName.dicomDateTimeLte:
          filtersList.push({ label: t('study.filterFields.shortToDicomDateTime'), value, key });
          break;

        case StudyFilterName.status: {
          const textList = value.map((elem) => elem.label);
          filtersList.push({ label: t('study.filterFields.status'), value: textList.join(SEPARATOR), key });
          break;
        }

        case StudyFilterName.urgencyLevel: {
          const textList = value.map((elem) => elem.label);
          // eslint-disable-next-line i18next/no-literal-string
          filtersList.push({ label: t('study.filterFields.urgencyLevel'), value: textList.join(SEPARATOR), key });
          break;
        }

        case StudyFilterName.studyType: {
          const textList = value.map((elem) => elem.label);
          filtersList.push({ label: t('study.filterFields.studyType'), value: textList.join(SEPARATOR), key });
          break;
        }

        case StudyFilterName.practitionersAssignedUserId: {
          const textList = value.map((elem) => elem.label);
          filtersList.push({
            label: t('study.filterFields.practitionerAssigned'),
            value: textList.join(SEPARATOR),
            key,
          });
          break;
        }

        case StudyFilterName.practitionerReviewerUserId: {
          const textList = value.map((elem) => elem.label);
          filtersList.push({
            label: t('study.filterFields.reviewerPractitioner'),
            value: textList.join(SEPARATOR),
            key,
          });
          break;
        }

        case StudyFilterName.seriesDicomOperatorsName:
          filtersList.push({ label: t('study.filterFields.dicomOperatorsName'), value: value.value, key });
          break;

        case StudyFilterName.facility: {
          const textList = value.map((elem) => elem.label);
          filtersList.push({ label: t('study.filterFields.facilityName'), value: textList.join(SEPARATOR), key });
          break;
        }

        case StudyFilterName.reportsBirads: {
          const textList = value.map((elem) => elem.label);
          filtersList.push({ label: t('study.filterFields.reportsBirads'), value: textList.join(SEPARATOR), key });
          break;
        }

        case StudyFilterName.patientAgeStart: {
          filtersList.push({ label: t('study.filterFields.minAge'), value: (Number(value) as unknown) as string, key });
          break;
        }

        case StudyFilterName.patientAgeEnd: {
          filtersList.push({ label: t('study.filterFields.maxAge'), value: (Number(value) as unknown) as string, key });
          break;
        }

        case StudyFilterName.pacsStudyStartDate:
          filtersList.push({ label: t('study.filterFields.fromPacsStudyDateTime'), value, key });
          break;

        case StudyFilterName.pacsStudyEndDate:
          filtersList.push({ label: t('study.filterFields.toPacsStudyDateTime'), value, key });
          break;

        case StudyFilterName.reportSignedStartDate:
          filtersList.push({ label: t('study.filterFields.fromReportSignedDateTime'), value, key });
          break;

        case StudyFilterName.reportSignedEndDate:
          filtersList.push({ label: t('study.filterFields.toReportSignedDateTime'), value, key });
          break;

        // Organization specific filters
        case StudyFilterName.rightPossession: {
          filtersList.push({ label: t('study.filterFields.rightPossession'), value: value?.label, key });
          break;
        }
      }
    }
    return filtersList;
  };

  /*-------------------*/
  /*    USE EFFECTS    */
  /*-------------------*/

  useEffect(() => {
    loadProductPermissionGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filterList = parseFieldsToFilters(filterFields);
    setAppliedFilters([...filterList]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterFields, t]);

  useEffect(() => {
    if (joinedStudies) {
      handleRefresh();
      setJoinedStudies(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joinedStudies]);

  const handleRemoveFilter = async (filter: AppliedFilterOption) => {
    removeFilter(filter);
  };

  const handleRemoveAllFilters = async () => {
    setSearchBy('');
    removeAllFilters();
  };

  const handleFilterSave = (fields?: StudyFilterFields) => {
    if (fields) {
      const filterList = parseFieldsToFilters(fields);
      const filterItemKeys = filterList.map((filterItem) => filterItem.key);
      const newFilterKeys = filterItemKeys.filter((filterKey) => {
        if (Object.prototype.hasOwnProperty.call(fields, filterKey)) return fields[filterKey];
      });

      const organizationSpecificFilterKeys: Array<string> = [];
      const filterKeys = newFilterKeys?.filter((filterKey) => {
        if (STUDY_ORGANIZATION_SPECIFIC_FILTER_KEYS.includes(filterKey as StudyFilterName)) {
          organizationSpecificFilterKeys.push(filterKey);
          return false;
        }
        return true;
      });

      saveFilters(filterKeys, fields);
      if (isOrganizationSpecificFieldsEnabled) saveOrganizationSpecificFilters(organizationSpecificFilterKeys, fields);
    }
    setCurrentModal(null);
  };

  const handleFilterBtnClick = () => {
    setCurrentModal({
      name: Modals.GenericModal,
      props: {
        title: t('study.filterModalTitle'),
        className: 'max-w-5xl',
        isMaxWidthCustom: true,
        children: (
          <FilterStudyModal
            defaultFilters={filterFields}
            onFilterSave={handleFilterSave}
            onCancel={() => setCurrentModal(null)}
          />
        ),
      },
    });
  };

  const handleRefresh = () => {
    reloadData();
    getUserFilters();
    if (!showStatisticsMetricsEnabled) return;
    fetchStudiesStatistics();
  };

  const handlePageChange = (page: number, scrollToPage = false) => {
    handleSetCurrentPage(page, scrollToPage);
    clearNotifications(NotificationType.StudyListUpdates);
  };

  const handleSearchChange = (event) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (event.target.value === searchBy) return;

      // @ts-expect-error
      searchParams.set(PARAMS_KEYS.SEARCH, event.target.value);
      history.replace({
        search: searchParams.toString(),
      });
      setSearchBy(event.target.value);
      handleReset();
    }, TypeaheadDebounceTime);
  };

  const handleTableLoad = useCallback((sortBy) => {
    const ordering = parseSortBy(sortBy);
    setTableSortBy(ordering);
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEmptyFilterFields = useMemo(() => {
    if (Object.keys(filterFields || {}).length === 0 && searchBy === '') return true;
    return false;
  }, [filterFields, searchBy]);

  /*------------------*/
  /*    RENDER JSX    */
  /*------------------*/
  return (
    <Onboarding>
      <div className={classes.studyListContainer}>
        {showStatisticsMetricsEnabled && (
          <div className="flex flex-col mb-3 lg:flex-row lg:items-center">
            {/* KPIs BLOCK */}
            <KpisSection
              historicCount={studiesStatisticResponse?.historicCount ?? 0}
              normalCount={studiesStatisticResponse?.normalCount ?? 0}
              normalNoReportCount={studiesStatisticResponse?.normalNoReportCount ?? 0}
              priorityCount={studiesStatisticResponse?.priorityCount ?? 0}
              priorityNoReportCount={studiesStatisticResponse?.priorityNoReportCount ?? 0}
              urgentCount={studiesStatisticResponse?.urgentCount ?? 0}
              urgentNoReportCount={studiesStatisticResponse?.urgentNoReportCount ?? 0}
            />
          </div>
        )}
        <div className="e-mb-4 flex flex-wrap-reverse justify-between">
          <SearchBar
            value={searchBy ?? ''}
            onFilterBtnClick={handleFilterBtnClick}
            onSearchChange={handleSearchChange}
          />
          <StudyListActions />
        </div>

        {filterFields && (
          <AppliedFilters
            searchBy={searchBy}
            className="mb-5"
            appliedFilters={appliedFilters}
            onRemoveFilterBtnClick={handleRemoveFilter}
            onRemoveAllFiltersBtnClick={handleRemoveAllFilters}
          />
        )}
        {/* TABLE SECTION */}
        <StudyTable
          data={rows}
          loading={loading}
          rootRef={rootRef}
          pageSize={pageSize}
          sentryRef={sentryRef}
          totalCount={totalCount}
          hasNextPage={hasNextPage}
          onTableLoad={handleTableLoad}
          initialLoading={initialLoading}
          hasPreviousPage={hasPreviousPage}
          isEmptyFilterFields={isEmptyFilterFields}
          onTableNotificationRowClick={handleRefresh}
        />
        {/* FOOTER */}
        <Footer
          pageSize={pageSize}
          totalCount={totalCount}
          currentPage={currentPage}
          isChatAvailable={isChatAvailable}
          handlePageChange={handlePageChange}
          isEmptyFilterFields={isEmptyFilterFields}
          handleSwitchListType={handleSwitchListType}
        />
      </div>
    </Onboarding>
  );
};

export default memo(StudyListPage);
