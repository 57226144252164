import { v4 as uuidv4 } from 'uuid';

import { REPORT_FULL_SCREEN_PATH, VIEWER_PATH } from '@eva-pacs/core';

export interface ScreenPreferences {
  windows: Array<WindowOpenPreference>;
}

export enum ScreenType {
  VIEWER = 'VIEWER',
  REPORT = 'REPORT',
}

export interface WindowOpenPreference {
  type: ScreenType;
  args: string;
}

export const SCREEN_PREFERENCES_MACHINE_ID_LOCAL_STORAGE_KEY = 'screenPreferencesMachineId';

export const SCREEN_PREFERENCES_MACHINE_ID_LOCAL_STORAGE_DEFAULT = uuidv4();

export const openWindowsWithScreenPreferences = (screenPreferences: ScreenPreferences, studyId: string) => {
  screenPreferences.windows.forEach((screenPreference, index) => {
    if (screenPreference.type === ScreenType.VIEWER) {
      window.open(`${VIEWER_PATH}${studyId}`, `${VIEWER_PATH}_${index}`, screenPreference.args);
    } else if (screenPreference.type === ScreenType.REPORT) {
      window.open(
        `${REPORT_FULL_SCREEN_PATH}/${studyId}`,
        `${REPORT_FULL_SCREEN_PATH}_${index}`,
        screenPreference.args,
      );
    }
  });
};
