import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  JoinMultipleStudiesMutation,
  StudiesStatisticsDocument,
  StudyListPageDocument,
  useJoinMultipleStudiesMutation,
} from '@eva-pacs/client';
import { JoinStudiesSelect } from './JoinStudiesSelect';
import { JoinStudiesOverview } from './JoinStudiesOverview';
import { getOperationNameFromDocument } from '~/utils/graphqlHelpers';
import { useStudyActionsStore } from '../studyActionsStore';
import type { StudyRow } from '../studyList.d';

export enum JoinStudiesSteps {
  Select = 'select',
  Confirm = 'confirm',
}

interface JoinStudiesProps {
  /**
   * Provide an optional handler to close the modal
   */
  onClose?: () => void;

  /**
   * Provide an optional handler that is called once the join studies mutation has finished
   */
  onJoinedStudiesCompleted?: (data?: JoinMultipleStudiesMutation) => void;
}

/**
 * Use this component in order to show the content to join studies
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2022-05-06
 */
export const JoinStudies = ({ onClose, onJoinedStudiesCompleted }: JoinStudiesProps) => {
  const { t } = useTranslation();
  const { selectedStudies } = useStudyActionsStore();
  const defaultSelectedStudy = selectedStudies[0];
  const [selectedStudy, setSelectedStudy] = useState<StudyRow>(defaultSelectedStudy);
  const [joinMultipleStudies, { loading }] = useJoinMultipleStudiesMutation({
    onCompleted: (data: JoinMultipleStudiesMutation) => {
      onJoinedStudiesCompleted && onJoinedStudiesCompleted(data);
    },
    refetchQueries: [
      // Refetch the following queries using the variables from the last call
      getOperationNameFromDocument(StudyListPageDocument),
      getOperationNameFromDocument(StudiesStatisticsDocument),
    ] as string[],
  });

  const handleConfirmJoinStudies = () => {
    const targetStudy = selectedStudy.actionColumn;
    const sourceStudies = selectedStudies
      .filter((study) => study.actionColumn !== targetStudy)
      .map((study) => study.actionColumn);

    joinMultipleStudies({
      variables: {
        input: {
          sourceStudies,
          targetStudy,
        },
      },
    });
  };

  const handleStudySelection = (study: StudyRow) => setSelectedStudy(study);

  return (
    <div className="text-left">
      <h3 className="-mt-4 mb-4 text-light-gray-300">{t('study.join.selectionDescription')}</h3>
      <div className="flex space-x-4">
        <div className="w-4/12">
          <JoinStudiesSelect
            studies={selectedStudies}
            defaultStudy={selectedStudy}
            onStudySelect={handleStudySelection}
          />
        </div>
        <div className="w-8/12">
          <JoinStudiesOverview
            loading={loading}
            study={selectedStudy}
            cancelAction={{ onClick: onClose }}
            confirmAction={handleConfirmJoinStudies}
          />
        </div>
      </div>
    </div>
  );
};
