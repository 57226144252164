import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  MENU_ITEM_EDIT,
  MENU_ITEM_EDIT_REPORT,
  MENU_ITEM_PREVIEW,
  MENU_ITEM_QR,
  MENU_ITEM_ZIP_DOWNLOAD,
  MENU_ITEM_BURN_ISO,
  MENU_ITEM_REPORT,
  MENU_ITEM_FILES,
  MENU_ITEM_STUDY_DELETE,
  MENU_ITEM_UPDATE_DOUBLE_SIGNATURE,
} from '@eva-pacs/core';
import { IconCatalog, DropdownMenuV2 as DropdownMenu, Button, ButtonVariant, ButtonSize } from '@evacenter/eden';

import { useFeatureFlagsStore } from '~/src/store/flagsStore';
import { useStudyOptionMenuActions } from '~/hooks';

import { useSessionStore } from '../../../../store';
import {
  CAN_EDIT_REPORT,
  FeatureFlags,
  CAN_BURN_DISCS,
  POLICIES,
  CAN_TOGGLE_DOUBLE_SIGNATURE,
} from '../../../../constants';
import { StudyStatus, useGetReportIdsByStudyIdLazyQuery } from '@eva-pacs/client';
import { useDoubleSignedStudyModal } from '~/components/shared/Modals/useDoubleSignedStudyModal';
import { useDisableDoubleSignatureOnSignedStudyModal } from '~/components/shared/Modals/useDisableDoubleSignatureOnSignedStudyModal';

export interface StudyOptionMenuProps {
  /**
   * Descriptive text to be read to screenreaders
   */
  ariaLabel?: string;

  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Id of the study to interact with.
   */
  studyId: string;

  /**
   * Flag to indicate if double sign is enabled.
   */
  isDoubleSignEnabled?: boolean;

  /**
   * Flag to indicate if report link should show or not
   */
  showReport?: boolean;

  /**
   * Flag to indicate if delete study link should show or not
   */
  showDeleteStudy?: boolean;

  /**
   * Study status
   */
  studyStatus?: StudyStatus;
}

/**
 * This is a dropdown menu that shows the study options
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2021-07-26
 */
export const StudyOptionMenu: React.FC<StudyOptionMenuProps> = ({
  ariaLabel,
  className,
  studyId,
  isDoubleSignEnabled,
  studyStatus,
  showReport = false,
  showDeleteStudy = false,
}) => {
  const { t } = useTranslation();
  const hasFlag = useFeatureFlagsStore((state) => state.hasFlag);
  const { modalNode, handleConfirmation } = useDoubleSignedStudyModal({
    studyId,
    isDoubleSignatureEnabled: isDoubleSignEnabled,
    isStudyPendingReview: studyStatus === StudyStatus.REVIEW_PENDING,
  });
  const {
    modalNode: disableDoubleSignatureOnSignedStudyModalNode,
    handleConfirmation: disableDoubleSignatureOnSignedStudyHandleConfirmation,
  } = useDisableDoubleSignatureOnSignedStudyModal({
    onConfirm: () => {
      getReportIdsForUndoSign({ variables: { studyId } });
    },
  });
  const HideQRCodeSending = hasFlag(FeatureFlags.HideQRCodeSending);
  const user = useSessionStore((state) => state.user);

  const canBurnDiscs = POLICIES[CAN_BURN_DISCS](user);

  const {
    performEditStudyAction,
    performPreviewAction,
    performReportViewAction,
    performBurnIsoAction,
    performCreateDownloadZipAction,
    performQRViewAction,
    performWatchFilesAction,
    performDeleteStudyAction,
    performToggleDoubleSignAction,
    performCancelRequireReviewReportAction,
    performUndoSignReport,
  } = useStudyOptionMenuActions();

  const [getReportIds] = useGetReportIdsByStudyIdLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      const results = response?.reports?.results;
      if (!results?.length) return;
      const activeReport = results[0];

      // Disabling the double signature for a "Pending review" status should cancel the review requirement
      performCancelRequireReviewReportAction(studyId, activeReport?.id);

      performToggleDoubleSignAction(studyId);
    },
  });

  const [getReportIdsForUndoSign] = useGetReportIdsByStudyIdLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      const results = response?.reports?.results;
      if (!results?.length) return;
      const activeReport = results[0];

      const onSuccessUndoSign = () => performToggleDoubleSignAction(studyId);

      performUndoSignReport(studyId, activeReport?.id, onSuccessUndoSign);
    },
  });

  const isReportSigned =
    studyStatus === StudyStatus.SIGNED ||
    studyStatus === StudyStatus.IMAGES_SENT ||
    studyStatus === StudyStatus.DELIVERED;

  const handleItemClick = (item: string) => {
    switch (item) {
      case MENU_ITEM_EDIT:
        performEditStudyAction(studyId);
        break;
      case MENU_ITEM_PREVIEW:
        performPreviewAction(studyId);
        break;
      case MENU_ITEM_REPORT:
        performReportViewAction(studyId);
        break;
      case MENU_ITEM_UPDATE_DOUBLE_SIGNATURE: {
        // Unique case for toggling off double signature.
        if (isDoubleSignEnabled && isReportSigned) {
          disableDoubleSignatureOnSignedStudyHandleConfirmation();
          break;
        }

        if (isReportSigned) {
          handleConfirmation();
          break;
        }

        if (studyStatus === StudyStatus.REVIEW_PENDING && isDoubleSignEnabled) {
          getReportIds({ variables: { studyId } });
          break;
        }

        performToggleDoubleSignAction(studyId);
        break;
      }
      case MENU_ITEM_QR:
        if (HideQRCodeSending) return;
        performQRViewAction(studyId);
        break;
      case MENU_ITEM_ZIP_DOWNLOAD:
        performCreateDownloadZipAction(studyId);
        break;
      case MENU_ITEM_FILES:
        performWatchFilesAction(studyId);
        break;
      case MENU_ITEM_STUDY_DELETE:
        performDeleteStudyAction(studyId);
        break;
      case MENU_ITEM_BURN_ISO:
        performBurnIsoAction(studyId, canBurnDiscs);
        break;
      case MENU_ITEM_EDIT_REPORT:
        window.open(`reportfullscreen/${studyId}`, '_blank');
        break;
      default:
        break;
    }
  };

  const canEditReport = POLICIES[CAN_EDIT_REPORT](user);

  // Addendunm statuses are not allowed to toggle double signature
  const canToggleDoubleSignature =
    POLICIES[CAN_TOGGLE_DOUBLE_SIGNATURE](user) &&
    studyStatus !== StudyStatus.ADDENDUM_SIGNED &&
    studyStatus !== StudyStatus.IN_ADDENDUM;

  const statusMenuClass = cn(className, 'z-30 lg:z-auto');

  return (
    <>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <Button
            size={ButtonSize.xs}
            aria-label={ariaLabel}
            variant={ButtonVariant.ghost}
            endIcon={IconCatalog.moreVert}
          />
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content className={statusMenuClass}>
            <DropdownMenu.Label className="py-2 text-center">
              {t('study.dropdownMenu.optionsSubtitle')}
            </DropdownMenu.Label>
            <DropdownMenu.Item
              id="preview-action"
              className="py-2 text-caption"
              onClick={() => handleItemClick(MENU_ITEM_PREVIEW)}>
              {t('study.dropdownMenu.preview')}
            </DropdownMenu.Item>
            {showReport && (
              <DropdownMenu.Item
                id="report-action"
                className="py-2 text-caption"
                onClick={() => handleItemClick(MENU_ITEM_REPORT)}>
                {t('study.dropdownMenu.report')}
              </DropdownMenu.Item>
            )}
            <DropdownMenu.Item
              id="files-action"
              className="py-2 text-caption"
              onClick={() => handleItemClick(MENU_ITEM_FILES)}>
              {t('study.dropdownMenu.seeUploadFiles')}
            </DropdownMenu.Item>
            {!HideQRCodeSending && (
              <DropdownMenu.Item
                id="qr-action"
                className="py-2 text-caption"
                onClick={() => handleItemClick(MENU_ITEM_QR)}>
                {t('general.modal.seeQR.title')}
              </DropdownMenu.Item>
            )}
            <DropdownMenu.Separator />
            <DropdownMenu.Label className="py-2 text-center">
              {t('study.dropdownMenu.actionsSubtitle')}
            </DropdownMenu.Label>
            <DropdownMenu.Item
              id="edit-action"
              className="py-2 text-caption"
              onClick={() => handleItemClick(MENU_ITEM_EDIT)}>
              {t('study.dropdownMenu.edit')}
            </DropdownMenu.Item>
            {canToggleDoubleSignature &&
              (isDoubleSignEnabled ? (
                <DropdownMenu.Item
                  id="disable-double-signature-action"
                  className="py-2 text-caption"
                  onClick={() => handleItemClick(MENU_ITEM_UPDATE_DOUBLE_SIGNATURE)}>
                  {t('study.dropdownMenu.disableDoubleSign')}
                </DropdownMenu.Item>
              ) : (
                <DropdownMenu.Item
                  id="enable-double-signature-action"
                  className="py-2 text-caption"
                  onClick={() => handleItemClick(MENU_ITEM_UPDATE_DOUBLE_SIGNATURE)}>
                  {t('study.dropdownMenu.enableDoubleSign')}
                </DropdownMenu.Item>
              ))}
            {canEditReport && (
              <DropdownMenu.Item
                id="edit-report-action"
                className="py-2 text-caption"
                onClick={() => handleItemClick(MENU_ITEM_EDIT_REPORT)}>
                {t('study.dropdownMenu.editReport')}
              </DropdownMenu.Item>
            )}
            <DropdownMenu.Item
              id="download-action"
              className="py-2 text-caption"
              onClick={() => handleItemClick(MENU_ITEM_ZIP_DOWNLOAD)}>{`${t(
              'general.download',
            )} DICOM`}</DropdownMenu.Item>
            <DropdownMenu.Item
              id="burn-action"
              className="py-2 text-caption"
              onClick={() => handleItemClick(MENU_ITEM_BURN_ISO)}>{`${t(
              'study.dropdownMenu.burn',
            )} CD`}</DropdownMenu.Item>
            {showDeleteStudy && (
              <DropdownMenu.Item
                id="delete-action"
                className="py-2 text-caption"
                onClick={() => handleItemClick(MENU_ITEM_STUDY_DELETE)}>
                {t('study.dropdownMenu.deleteStudy')}
              </DropdownMenu.Item>
            )}
            <DropdownMenu.Arrow />
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
      {modalNode}
      {disableDoubleSignatureOnSignedStudyModalNode}
    </>
  );
};
