import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import cn from 'classnames';

import { Button, ButtonSize, ButtonVariant } from '@evacenter/eden';

import { IconCatalog, Icon } from '@eva-pacs/ui';

import { FeatureFlags } from '~/src/constants/featureFlags';
import { useFeatureFlagsStore } from '~/src/store/flagsStore';
import { useFreshChatService } from '~/src/hooks/useFreshChatService';
import { useSessionStore } from '~/src/store/sessionStore';
import { ChatServiceStatus, FreshWidgetIframeClasses } from '~/constants';
import { getFreshChatConfig } from '~/utils/getFreshChatConfig';
import { MulticolorIconCatalog, MulticolorIcon } from './MulticolorIcon';
import { useStudyListStore } from '~/src/store';
import { WorklistTabNames } from '~/pages/StudyList/Worklist/WorklistTabs';
import { useStudyListTabs } from '~/src/hooks/study/useStudyListTabs';

interface SupportChatButtonProps {
  className?: string;
  iframeClasses?: FreshWidgetIframeClasses;
}

export const SupportChatButton = ({ className, iframeClasses }: SupportChatButtonProps): JSX.Element | null => {
  const { hasFlag } = useFeatureFlagsStore();
  const isChatAvailable = hasFlag(FeatureFlags.CustomerSupportChat);
  const worklistTotalCount = useStudyListStore((state) => state.worklistTotalCount);
  const isEmptyWorklist = worklistTotalCount === 0;
  const { activeTab } = useStudyListTabs();
  const enableWorklistFlow = isEmptyWorklist && activeTab === WorklistTabNames.WORKLIST;
  const { user, getCurrentAccount } = useSessionStore();
  const {
    freshChatWidget,
    unreadNotifications,
    isWidgetOpen,
    initFreshChatService,
    chatServiceStatus,
    isPopupPreviewOpen,
  } = useFreshChatService({
    isActive: isChatAvailable,
  });
  const { t } = useTranslation();
  const currentAccount = getCurrentAccount();

  const initFreshChat = () => {
    if (!user) return;
    const config = getFreshChatConfig({ id: user?.id, email: user?.email ?? '' }, currentAccount, iframeClasses);
    initFreshChatService(config);
  };

  useEffect(() => {
    if (!user) return;
    initFreshChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initFreshChatService, user, currentAccount, iframeClasses]);

  const renderNotificationIcon = () => {
    if (unreadNotifications > 0)
      return <MulticolorIcon iconPaths={MulticolorIconCatalog.chatNotification} className="ml-1" />;
    return <Icon icon={IconCatalog.chat} className="ml-1" />;
  };

  const handleOpenWidget = () => freshChatWidget?.open();
  if (!isChatAvailable || isWidgetOpen || chatServiceStatus !== ChatServiceStatus.Ready || isPopupPreviewOpen)
    return null;

  const classes = {
    container: cn('px-4 rounded-bl-none rounded-br-none pointer-events-auto', className, {
      'bg-transparent backdrop-blur blur-md': enableWorklistFlow,
    }),
  };

  const renderChatButton = () => {
    if (enableWorklistFlow)
      return (
        <div
          className="rounded-t-lg"
          style={{ backgroundColor: 'rgba(255,255,255,0.02)', backdropFilter: 'blur(8px)' }}>
          <Button
            size={ButtonSize.xs}
            variant={ButtonVariant.tertiaryNeutral}
            onClick={handleOpenWidget}
            className={classes.container}>
            <span className="flex items-center">
              {t('general.supportChat.buttons.openWidget')}
              {renderNotificationIcon()}
            </span>
          </Button>
        </div>
      );

    return (
      <Button
        size={ButtonSize.xs}
        variant={ButtonVariant.tertiary}
        onClick={handleOpenWidget}
        className={classes.container}>
        <span className="flex items-center">
          {t('general.supportChat.buttons.openWidget')}
          {renderNotificationIcon()}
        </span>
      </Button>
    );
  };

  return renderChatButton();
};
