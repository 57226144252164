import { useCreatePatientNoDicomMutation } from '@eva-pacs/client';
export interface PatientNoDicomInput {
  name: string;
  firstSurname: string;
  lastSurname: string;
}
export const useCreatePatientNoDicom = () => {
  const [createPatientNoDicomRequest] = useCreatePatientNoDicomMutation();

  const createPatientNoDicom = async ({
    name,
    firstSurname,
    lastSurname,
  }: PatientNoDicomInput): Promise<string | null> => {
    try {
      const response = await createPatientNoDicomRequest({
        variables: {
          input: {
            name,
            firstSurname,
            lastSurname,
          },
        },
      });
      const patientId = response.data?.createPatientNoDicom.patient?.id;
      if (!response.data?.createPatientNoDicom.success || !patientId) throw new Error();
      return patientId;
    } catch {
      return null;
    }
  };

  return { createPatientNoDicom };
};
