import cn from 'classnames';
import { ProgressColor, ProgressSize } from './';
/* Class assignment */
export var progressClass = function (className, size, isRounded) {
    return cn(className, 
    /* Progress (base) */
    'bg-gray-600 overflow-hidden relative w-full', 
    /* Progress sizes */
    size === ProgressSize.xs && 'h-0.5', size === ProgressSize.sm && 'h-1', size === ProgressSize.md && 'h-2', size === ProgressSize.lg && 'h-3', 
    /* Progress--isRounded */
    isRounded && 'rounded-full');
};
export var progressBarClass = function (size, color, isRounded) {
    return cn(
    /* Progress Bar (base) */
    'left-0 absolute top-0', 'transition-width duration-100 ease-in-out', 
    /* ProgressBar sizes */
    size === ProgressSize.xs && 'h-0.5', size === ProgressSize.sm && 'h-1', size === ProgressSize.md && 'h-2', size === ProgressSize.lg && 'h-3', 
    /* Progress sizes */
    color === ProgressColor.positive && 'bg-feedback-positive-500', color === ProgressColor.negative && 'bg-feedback-negative-500', color === ProgressColor.primary && 'bg-primary-500', 
    /* ProgressBar--isRounded */
    isRounded && 'rounded-full');
};
