import React, { useState } from 'react';
import { Transcriber } from '@eva-pacs/core';
import { Button, IconCatalog, ButtonVariant } from '@evacenter/eden';
import { recordaudiobuttonClass } from './styles';
/**
 * Button to record audio and convert it to text.
 * @author Alejo Forero<alejoforero.af@gmail.com>
 * Created at 2021-06-15
 */
export var RecordAudioButton = function (_a) {
    var className = _a.className, onRecord = _a.onRecord;
    var _b = useState(false), isRecording = _b[0], setIsRecording = _b[1];
    var transcriberSink = {
        onTranscript: function (content, isPartial) {
            if (!isPartial)
                onRecord(content);
        },
        onError: function (message) {
            setIsRecording(false);
            console.warn(message);
        },
        onTerminate: function () {
            setIsRecording(false);
        },
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var _c = useState(new Transcriber(transcriberSink, true)), _ = _c[0], setTranscriber = _c[1];
    var handleOnClick = function () {
        setTranscriber(function (transcriber) {
            if (isRecording) {
                transcriber.stop();
                setIsRecording(false);
            }
            else {
                try {
                    setIsRecording(true);
                    transcriber.start().then();
                }
                catch (error) {
                    console.log(error);
                }
            }
            return transcriber;
        });
    };
    return (React.createElement(Button, { className: recordaudiobuttonClass(className), style: { height: '32px', padding: 0 }, startIcon: isRecording ? IconCatalog.pause : IconCatalog.mic, variant: isRecording ? ButtonVariant.destructive : ButtonVariant.primary, onClick: handleOnClick }));
};
