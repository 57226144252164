import React, { createContext, useContext } from 'react';
import { useStudyListUpdates } from '~/components/StudyList/useStudyListUpdates';

const StudyListContext = createContext({} as ReturnType<typeof useStudyListContext>);

interface StudyListContextValues {
  studiesCreated?: number;
}

export const StudyListContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { studiesCreated } = useStudyListUpdates();
  const values: StudyListContextValues = {
    studiesCreated,
  };
  return <StudyListContext.Provider value={values}>{children}</StudyListContext.Provider>;
};

export const useStudyListContext = () => {
  const context = useContext(StudyListContext);
  if (!context) throw new Error(`useStudyListContext must be wrapped with <StudyListContextProvider />`);
  return context;
};
