import React from 'react';
import { Spinner } from '@eva-pacs/ui';

export const Loading = () => {
  return (
    <div data-testid="spinner-study-list" className="flex flex-grow items-center w-full">
      <Spinner />
    </div>
  );
};
