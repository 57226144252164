import { useEffect } from 'react';

import { MeasurementType, StudyType, useViewerStudyLazyQuery } from '@eva-pacs/client';
import { ApolloError, WatchQueryFetchPolicy } from '@apollo/client';

import { useMeasurementStore, useSeriesStore, useSessionStore, useStudyStore } from '~/src/store';
import { CAN_READ_MEASUREMENT, POLICIES } from '~/constants';
import shallow from 'zustand/shallow';
import { useLoadViewerSeries } from '../series/loadViewerSeries';
import { useErrorHandler } from '~/utils/appHelpers';

export interface GetViewerStudyProps {
  onSuccess?: (study: StudyType) => void;
  onError: (error: ApolloError) => void;
  studyId: string;
  credentials?: string;
  fetchPolicy?: WatchQueryFetchPolicy;
}
/** Hook to get a study from the server */
export const useGetViewerStudy = ({ studyId, credentials, fetchPolicy, onSuccess, onError }: GetViewerStudyProps) => {
  const [addMeasurement, setMeasurementsLoaded] = useMeasurementStore(
    (store) => [store.addMeasurement, store.setMeasurementsLoaded],
    shallow,
  );
  const [study, setStudy] = useStudyStore((store) => [store.study, store.setStudy], shallow);
  const { handleError } = useErrorHandler();
  const getLoadedSeries = useSeriesStore((store) => store.getLoadedSeries);
  const user = useSessionStore((store) => store.user);

  const { loadSerieMeasurements } = useLoadViewerSeries({ onError: (error) => handleError({ logMessage: error }) });

  const [getViewerStudy] = useViewerStudyLazyQuery({
    fetchPolicy,
    onError,
    onCompleted: ({ study }) => handleOnCompleteStudy(study as StudyType),
  });

  const loadMeasurements = (measurements: Array<MeasurementType>) => {
    (measurements ?? []).forEach((measurement) => {
      const data = {
        ...measurement,
        data: JSON.parse(measurement.data)[0],
      };
      addMeasurement(data);
    });
    setMeasurementsLoaded();
    getLoadedSeries().forEach((serie) => loadSerieMeasurements(serie));
  };

  const handleOnCompleteStudy = (study: StudyType) => {
    if (!study) return;
    if (POLICIES[CAN_READ_MEASUREMENT](user)) loadMeasurements(study.measurements);
    setStudy(study);
    if (onSuccess) onSuccess(study);
  };

  // Get study if it is not in the store
  useEffect(() => {
    if (study?.id !== studyId) return getViewerStudy({ variables: { id: studyId, credentials } });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials, study, studyId]);
};
