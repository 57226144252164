/* META TAGS */
export const TAG_CINE_RATE = 'x00180040'; // https://dicom.innolitics.com/ciods/us-multi-frame-image/cine/00180040
export const TAG_COLUMNS = 'x00280011'; // https://dicom.innolitics.com/ciods/ct-image/image-pixel/00280011
export const TAG_FRAME_OF_REFERENCE_UID = 'x00200052'; // https://dicom.innolitics.com/ciods/segmentation/frame-of-reference/00200052
export const TAG_IMAGE_ORIENTATION_PATIENT = 'x00200037'; // https://dicom.innolitics.com/ciods/ct-image/image-plane/00200037
export const TAG_IMAGE_POSITION_PATIENT = 'x00200032'; // https://dicom.innolitics.com/ciods/ct-image/image-plane/00200032
export const TAG_IMAGER_PIXEL_SPACING = 'x00181164'; // https://dicom.innolitics.com/ciods/cr-image/cr-image/00181164
export const TAG_LARGEST_IMAGE_PIXEL_VALUE = 'x00280107'; // https://dicom.innolitics.com/ciods/us-multi-frame-image/image-pixel/00280107
export const TAG_MEASURES_SEQUENCE = 'x00289110'; // https://dicom.innolitics.com/ciods/mr-spectroscopy/mr-spectroscopy-multi-frame-functional-groups/52009229/00289110
export const TAG_MODALITY = 'x00080060'; // https://dicom.innolitics.com/ciods/nm-image/general-series/00080060
export const TAG_PATIENT_NAME = 'x00100010'; // https://dicom.innolitics.com/ciods/rt-plan/patient/00100010
export const TAG_PER_FRAME_FUNCTIONAL_GROUPS = 'x52009230'; // https://dicom.innolitics.com/ciods/mr-spectroscopy/mr-spectroscopy-multi-frame-functional-groups/52009230
export const TAG_PHYSICAL_DELTAX = 'x0018602c'; // https://dicom.innolitics.com/ciods/us-image/us-region-calibration/00186011/0018602c
export const TAG_PHYSICAL_DELTAY = 'x0018602e'; // https://dicom.innolitics.com/ciods/us-image/us-region-calibration/00186011/0018602e
export const TAG_PHYSICAL_UNITS_X_DIRECTION = 'x00186024'; // https://dicom.innolitics.com/ciods/us-image/us-region-calibration/00186011/00186024
export const TAG_PHYSICAL_UNITS_Y_DIRECTION = 'x00186026'; // https://dicom.innolitics.com/ciods/us-image/us-region-calibration/00186011/00186026
export const TAG_PIXEL_SPACING = 'x00280030'; // https://dicom.innolitics.com/ciods/nm-image/nm-image-pixel/00280030
export const TAG_PLANE_ORIENTATION_SEQUENCE = 'x00209116'; // https://dicom.innolitics.com/ciods/mr-spectroscopy/mr-spectroscopy-multi-frame-functional-groups/52009229/00209116
export const TAG_PLANE_POSITION_SEQUENCE = 'x00209113'; // https://dicom.innolitics.com/ciods/mr-spectroscopy/mr-spectroscopy-multi-frame-functional-groups/52009229/00209113
export const TAG_RESCALE_SLOPE = 'x00281053'; // https://dicom.innolitics.com/ciods/pet-image/pet-image/00281053
export const TAG_RESCALE_INTERCEPT = 'x00281052'; // https://dicom.innolitics.com/ciods/pet-image/pet-image/00281052
export const TAG_ROWS = 'x00280010'; // https://dicom.innolitics.com/ciods/ct-image/image-pixel/00280010
export const TAG_SEQUENCE_OF_ULTRASOUND_REGIONS = 'x00186011'; // https://dicom.innolitics.com/ciods/us-image/us-region-calibration/00186011
export const TAG_SERIES_DATE = 'x00080021'; // https://dicom.innolitics.com/ciods/segmentation/general-series/00080021
export const TAG_SERIES_DESCRIPTION = 'x0008103e'; // https://dicom.innolitics.com/ciods/segmentation/general-series/0008103e
export const TAG_SERIES_ID = 'x0020000e'; // https://dicom.innolitics.com/ciods/nm-image/patient/00101100/0020000e
export const TAG_IMAGE_TYPE = 'x00080008'; // https://dicom.innolitics.com/ciods/segmentation/general-image/00080008
export const TAG_SLICE_LOCATION = 'x00201041'; // https://dicom.innolitics.com/ciods/ct-image/image-plane/00201041
export const TAG_SLICE_THICKNESS = 'x00180050'; // https://dicom.innolitics.com/ciods/ct-image/image-plane/00180050
export const TAG_SMALLEST_IMAGE_PIXEL_VALUE = 'x00280106'; // https://dicom.innolitics.com/ciods/us-multi-frame-image/image-pixel/00280106
export const TAG_STUDY_ID = 'x0020000d'; // https://dicom.innolitics.com/ciods/nm-image/patient/00101100/0020000d
export const TAG_STUDY_LATERALITY = 'x00200060'; // https://dicom.innolitics.com/ciods/cr-image/general-series/00200060
export const TAG_STUDY_IMAGE_LATERALITY = 'x00200062'; // https://dicom.innolitics.com/ciods/cr-image/general-series/00200062
export const TAG_CURRENT = 'x00181151'; // https://dicom.innolitics.com/ciods/digital-x-ray-image/x-ray-acquisition-dose/00181151
export const TAG_KVP = 'x00180060'; // https://dicom.innolitics.com/ciods/digital-x-ray-image/x-ray-generation/00180060
export const TAG_COMPRESSION_FORCE = 'x001811a2'; // https://dicom.innolitics.com/ciods/digital-x-ray-image/dx-positioning/001811a2
export const TAG_BODY_PART_THICKNESS = 'x001811a0'; // https://dicom.innolitics.com/ciods/digital-x-ray-image/dx-positioning/001811a0
export const TAG_VIEW_POSITION = 'x00185101'; // https://dicom.innolitics.com/ciods/digital-x-ray-image/dx-positioning/00185101
export const TAG_VIEW_CODE_SEQUENCE = 'x00540220'; // https://dicom.innolitics.com/ciods/digital-x-ray-image/dx-positioning/00540220
export const TAG_ENCAPSULATED_DOCUMENT_MIME_TYPE = 'x00420012'; // https://dicom.innolitics.com/ciods/encapsulated-pdf/encapsulated-document/00420012
export const TAG_ENCAPSULATED_DOCUMENT_ELEMENT = 'x00420011'; // https://dicom.innolitics.com/ciods/encapsulated-pdf/encapsulated-document/00420011
export const TAG_REPETITION_TIME = 'x00180080'; // https://dicom.innolitics.com/ciods/mr-image/mr-image/00180080
export const TAG_ECHO_TIME = 'x00180081'; // https://dicom.innolitics.com/ciods/mr-image/mr-image/00180081
/* MOUSE KEYS */
export const LEFT_MOUSE_BUTTON = 1;
export const MIDDLE_MOUSE_BUTTON = 2;
export const RIGHT_MOUSE_BUTTON = 4;

export enum TARGET_TYPES {
  ELEMENT = 'element',
  CORNERSTONE = 'cornerstone',
}

export enum LATERALITY_TYPE {
  L = 'L',
  R = 'R',
}

export enum VIEW_POSITION {
  CC = 'CC',
  MLO = 'MLO',
}

export enum VIEW_CODE_SEQUENCE {
  CC = 'CRANIO-CAUDAL',
  MLO = 'MEDIO-LATERAL OBLIQUE',
}

/* VIEWER */
export const FRAME_RATE = 24;

export const SERIE_EXTERNAL_ID = 'external';

export const ACTIVE_LINE_COLOR = '#ffff00';

export const DEFAULT_PRECISION_NUMBER = 2;

export const IMAGE_DEBOUNCE_TIME = 100;

export const LINE_COLOR = '#2CD150';

export const LOAD_INDICATOR_DELAY = 45;

export const LOSSY_IMAGE_COMPRESSION = '01';

export const PASSIVE_LINE_COLOR = '#2CD150';

export const PIXELS_NEAR_TOOL = 10;

export const PIXELS_NEAR_TOOL_TOUCH = 25;

export const MIN_DISTANCE_FOR_VALID_POINT = 5;

export const DEGREES_OF_INCLINATION_FOR_REFERENCE_LINES = 20;

/* CUSTOM EVENTS */

export const CORNERSTONE_COMPARE_REGIONS_CUSTOM_EVENT = 'cornerstone_compare_regions_custom_event';
export const CORNERSTONE_REFERENCE_LINES_EVENT = 'cornerstone_reference_lines_event';
export const NIPPLE_INJURY_UPDATE_CONF_EVENT = 'nipple_injury_update_conf_event';
export const NIPPLE_INJURY_CHANGE_MODE_EVENT = 'nipple_injury_change_mode_event';
export const CORNERSTONE_SPINE_LABEL_EVENT = 'cornerstone_spine_label_event';
