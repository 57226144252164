import { ACCESS_TOKEN_KEY, getCookie, ORGANIZATION_ID_KEY, I18N_LANGUAGE_KEY } from '@eva-pacs/core';

export const getAuthHeaders = () => {
  // get the authentication token from local storage if it exists
  const token = getCookie(ACCESS_TOKEN_KEY);
  // Organization Id
  const organizationId = getCookie(ORGANIZATION_ID_KEY);
  const acceptLanguage = getCookie(I18N_LANGUAGE_KEY);

  return {
    Authorization: `JWT ${token}`,
    'Organization-Id': organizationId,
    'Accept-Language': acceptLanguage,
  };
};
