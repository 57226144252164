import React, { CSSProperties } from 'react';
import { FrigadeNPSSurvey, useFlows } from '@frigade/react';
import { useTranslation } from 'react-i18next';
import './surveys.css';

const NPS_SURVEY_STEPS = {
  SCORE: 0,
  FEEDBACK: 1,
} as const;

type Position = CSSProperties['position'];
type ZIndex = CSSProperties['zIndex'];

const NPS_STEP_CONTAINER_CLASSES = {
  [NPS_SURVEY_STEPS.SCORE]: 'step-score',
  [NPS_SURVEY_STEPS.FEEDBACK]: 'step-feedback',
} as const;

// This is needed because the Tailwind version used in Pacs doesn't support !important classes or more specific selectors
const NPS_GENERAL_CLASSES = {
  typography: {
    color: '#fff',
    fontWeight: 600,
    fontSize: '1rem',
  },
};

// This is also needed because the Tailwind version used in Pacs doesn't support !important classes or more specific selectors
/* eslint-disable i18next/no-literal-string */
const classesNpsSurvey = {
  largeTitle: {
    ...NPS_GENERAL_CLASSES.typography,
    letterSpacing: 0,
    marginBottom: 0,
  },
  largeSubtitle: NPS_GENERAL_CLASSES.typography,
  npsSurveyDismissButton: 'hidden',
  npsNumberButton: {
    ...NPS_GENERAL_CLASSES.typography,
    color: '#60b0ff',
    borderRadius: '0.625rem',
    borderColor: '#1b1a2a',
    backgroundColor: '#1b1a2a',
    '&:hover': {
      backgroundColor: '#221f4f',
      borderColor: '#221f4f',
    },
  },
  npsSurveyContainer: {
    minWidth: 'unset',
    maxWidth: 'unset',
    zIndex: '11 !important' as ZIndex,
    padding: '1.25rem',
    borderRadius: '0.625rem',
    borderColor: '#070809',
    position: 'fixed' as Position,
    bottom: 0,
    backgroundColor: '#070809',
    boxShadow: '0 0.25rem 1.25rem 0 #0000001a',
    '&.step-score .fr-npsNumberButtonContainer, &.step-score .fr-npsNumberButtonContainer + div': {
      maxWidth: '100%',
      overflowX: 'auto',
    },
    '&.step-score .fr-npsNumberButtonContainer + div div:nth-child(1), &.step-score .fr-npsNumberButtonContainer + div div:nth-child(2)': {
      color: '#999999',
    },
    '&.step-score .fr-npsNumberButton': {
      minWidth: '3.625rem',
      height: '3.625rem',
    },
    '&.step-feedback .fr-npsNumberButtonContainer button': {
      borderRadius: '2rem',
      height: '2.5rem',
    },
    '&.step-feedback .fr-npsNumberButtonContainer': {
      marginTop: '1.5rem',
      gap: '1rem',
    },
    '&.step-feedback .fr-largeSubtitle': {
      marginBottom: '0.5rem',
    },
    '&.step-feedback .fr-npsCTAContainer .fr-buttonSecondary': {
      display: 'none',
    },
    '&.step-feedback .fr-npsNumberButtonContainer .fr-buttonSecondary': {
      color: '#60B0FF',
      borderColor: '#1b1a2a',
      backgroundColor: '#1b1a2a',
    },
    '&.step-feedback .fr-npsNumberButtonContainer .fr-buttonSecondary:hover': {
      backgroundColor: '#221f4f',
      borderColor: '#221f4f',
      opacity: 1,
    },
    '&.step-feedback .fr-npsNumberButtonContainer .fr-button': {
      width: '100%',
      color: '#fff',
      backgroundColor: '#4033F2',
      borderColor: '#4033F2',
    },
    '&.step-feedback .fr-npsNumberButtonContainer .fr-button:hover': {
      opacity: 1,
      backgroundColor: '#362cbc',
      borderColor: '#362cbc',
    },
    textarea: {
      backgroundColor: '#0F1012',
      borderColor: '#4A5259',
      color: '#fff',
      marginTop: '0.5rem',
      minHeight: '7.75rem',
    },
    'textarea::placeholder': {
      color: '#999999',
      fontSize: '0.875rem',
    },
  },
};
/* eslint-enable */

const flowIdNPSSurvey = process.env.REACT_APP_FRIGADE_NPS_SURVEY_FLOW_ID!;

const Surveys = () => {
  const { t } = useTranslation();
  const { getCurrentStepIndex } = useFlows();
  const npsSurveyStepIndex = getCurrentStepIndex(flowIdNPSSurvey!);
  const scoreStepTranslations = {
    scoreStepTitle: t('frigade.npsSurvey.score.title'),
    scoreStepPositiveLabel: t('frigade.npsSurvey.score.high'),
    scoreStepNegativeLabel: t('frigade.npsSurvey.score.low'),
  };
  const feedbackStepTranslations = {
    feedbackStepTitle: t('frigade.npsSurvey.feedback.title'),
    feedbackStepFeedbackLabel: t('frigade.npsSurvey.feedback.form.fields.textarea.label'),
    feedbackStepFeedbackPlaceholder: t('frigade.npsSurvey.feedback.form.fields.textarea.placeholder'),
    feedbackStepConfirmButtonTitle: t('frigade.npsSurvey.feedback.form.actions.submit'),
    feedbackStepGoBackButtonTitle: t('frigade.npsSurvey.feedback.form.actions.goBack'),
  };

  const appearance = {
    styleOverrides: classesNpsSurvey,
  };

  return (
    <FrigadeNPSSurvey
      flowId={flowIdNPSSurvey}
      className={NPS_STEP_CONTAINER_CLASSES[npsSurveyStepIndex]}
      appearance={appearance}
      customVariables={{
        ...scoreStepTranslations,
        ...feedbackStepTranslations,
      }}
    />
  );
};

export default Surveys;
