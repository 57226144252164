import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { CopyTooltip, SimpleTooltip } from '@evacenter/eden';
import { useLanguage } from '@eva-pacs/app-pacs/src/hooks/useLanguage';
var DEFAULT_TRUNCATE_LENGTH = 30;
/**
 * Patient Cell (Table)
 * @author Carlos Cuatin<carlos.cuatin@edenmed.com>
 * Created at 2024-01-23
 */
export var PatientCell = function (_a) {
    var title = _a.title, titleHref = _a.titleHref, className = _a.className, description = _a.description, titleOnClick = _a.titleOnClick, patientLabel = _a.patientLabel, descriptionPrefix = _a.descriptionPrefix, _b = _a.titleExternalLink, titleExternalLink = _b === void 0 ? false : _b;
    var currentLanguage = useLanguage().currentLanguage;
    var isTruncated = function (text) {
        if (!text)
            return false;
        return (text === null || text === void 0 ? void 0 : text.length) > DEFAULT_TRUNCATE_LENGTH;
    };
    var isTitleTruncated = isTruncated(title);
    var isIdTruncated = isTruncated(description || '');
    var renderTitle = function () {
        if (titleHref) {
            return titleExternalLink ? (React.createElement("a", { className: "e-underline e-truncate", href: titleHref, target: "_blank", rel: "noreferrer" }, title)) : (React.createElement(Link, { className: "e-underline e-truncate", to: titleHref }, title));
        }
        if (titleOnClick)
            return (React.createElement("button", { className: "e-text-left e-underline e-truncate", onClick: titleOnClick }, title));
        return React.createElement("span", { className: "e-truncate" }, title);
    };
    var classes = {
        container: cn(className, 'e-flex e-flex-col'),
        id: cn('e-inline-block e-truncate', {
            'e-w-full': isIdTruncated,
        }),
    };
    return (React.createElement("div", { className: classes.container },
        React.createElement("div", { className: "e-inline-flex e-mb-1 e-text-basic-white hover:e-text-light-gray-600 e-text-caption" }, isTitleTruncated ? (React.createElement(SimpleTooltip, { description: title, delayDuration: 0 }, renderTitle())) : (renderTitle())),
        description && (React.createElement("div", { className: "e-inline-flex e-text-light-gray-600 hover:e-text-light-gray-600 e-text-caption e-font-regular" },
            React.createElement(CopyTooltip, { className: classes.id, textToCopy: description, locale: currentLanguage, title: isIdTruncated ? descriptionPrefix : undefined, description: isIdTruncated ? description : undefined },
                descriptionPrefix,
                description))),
        React.createElement("span", { className: "e-text-light-gray-600 e-text-caption e-font-regular" }, patientLabel)));
};
