/* eslint-disable i18next/no-literal-string */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useStudyService } from '~/hooks';
import { useTranslation } from 'react-i18next';
import { BasicInput, Button, ButtonColor, ButtonProps, ButtonType, FormField } from '@eva-pacs/ui';

import type { StudyRow } from '../studyList.d';
import { JoinStudiesDetails } from './JoinStudiesDetails';
import { JoinStudiesDescription } from './JoinStudiesDescription';

type ActionButton = ButtonProps & {
  label?: string;
};

export interface DescriptionFormFields {
  /**
   * Description of the study
   */
  description: string;
}

interface JoinStudiesOverviewProps {
  /**
   * Target study to interact with.
   */
  study: StudyRow;

  /**
   * ReactNode to display as the cancel action at the bottom of the content.
   */
  cancelAction?: ActionButton;

  /**
   * Function to execute once the user confirms the action.
   */
  confirmAction?: () => void;

  /**
   * Flag to indicate if the component is loading.
   */
  loading?: boolean;
}

/**
 * Use this component in order to display information relevant to the target study.
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2022-05-06
 */
export const JoinStudiesOverview = ({
  study,
  cancelAction,
  confirmAction,
  loading = false,
}: JoinStudiesOverviewProps) => {
  const { t } = useTranslation();
  const { updateStudy } = useStudyService();

  const {
    control,
    setValue,
    setFocus,
    handleSubmit,
    formState: { errors },
  } = useForm<DescriptionFormFields>({
    criteriaMode: 'all',
    mode: 'all',
  });

  const renderActionButtons = () => {
    return (
      <>
        {cancelAction && (
          <Button {...cancelAction} color={ButtonColor.primaryAlternative} isRounded>
            {t('general.actions.cancel')}
          </Button>
        )}
        {confirmAction && (
          <Button isRounded type={ButtonType.submit} isLoading={loading}>
            {t('general.genericConfirmationTitle')}
          </Button>
        )}
      </>
    );
  };

  const handleOnSubmit = (data: DescriptionFormFields) => {
    confirmAction?.();
    updateStudy(study.actionColumn, { dicomDescription: data.description });
  };

  useEffect(() => {
    setValue('description', study.dicomDescription || '');
    setFocus('description');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [study]);

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <div className="e-flex e-flex-col e-justify-between e-min-h-full e-space-y-4">
        <div className="e-flex e-flex-col e-h-full e-space-y-2">
          <JoinStudiesDescription study={study as StudyRow}>
            <FormField
              control={control}
              name="description"
              error={errors.description}
              className="col-span-12 md:col-span-4"
              defaultValue={study.dicomDescription || ''}
              render={({ field }) => {
                return <BasicInput placeholder={t('study.join.descriptionPlaceholder')} {...field} />;
              }}
            />
          </JoinStudiesDescription>
          <JoinStudiesDetails study={study as StudyRow} />
        </div>
        <div className="e-flex e-justify-end e-space-x-2">{renderActionButtons()}</div>
      </div>
    </form>
  );
};
