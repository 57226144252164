import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PARAMS_KEYS, useSearchParams } from '~/src/hooks/useSearchParams';
import { useHistory } from 'react-router-dom';

import shallow from 'zustand/shallow';
import cn from 'classnames';

import { TabsRoot, TabsList, TabsContent, TabsItem, TagVariant } from '@evacenter/eden';

import { useStudyListStore } from '~/src/store';
import { useGetWorklistTotalCountLazyQuery } from '@eva-pacs/client';

import StudyList from '../StudyList';
import Worklist from './Worklist';
import { useStudyListTabs } from '~/src/hooks/study/useStudyListTabs';

export enum WorklistTabNames {
  WORKLIST = 'workList',
  STUDY_LIST = 'studyList',
}

export const WorklistTabs = () => {
  const [isLoadingInitialCount, setIsLoadingInitialCount] = useState(true);
  const [worklistTotalCount, setWorklistTotalCount] = useStudyListStore(
    (state) => [state.worklistTotalCount, state.setWorklistTotalCount],
    shallow,
  );
  const searchParams = useSearchParams();
  const history = useHistory();
  const { activeTab } = useStudyListTabs();
  const isEmptyWorklist = worklistTotalCount === 0;
  const tabsContainerClass = cn('e-flex', {
    'e-border-t e-border-t-neutral-600 e-bg-neutral-900': !isEmptyWorklist,
  });

  useEffect(() => {
    getTotalCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [getTotalCount] = useGetWorklistTotalCountLazyQuery({
    fetchPolicy: 'no-cache',
    // eslint-disable-next-line i18next/no-literal-string
    nextFetchPolicy: 'cache-first',
    onCompleted: (result) => {
      const totalCount = result.studyWorklist?.totalCount;
      setWorklistTotalCount(totalCount || 0);
      setIsLoadingInitialCount(false);
    },
    onError: () => {
      setWorklistTotalCount(0);
      setIsLoadingInitialCount(false);
    },
  });

  const handleSetStudyListTabParam = () => {
    // @ts-expect-error
    searchParams.set(PARAMS_KEYS.TABS, WorklistTabNames.STUDY_LIST);
    history.replace({
      search: searchParams.toString(),
    });
    return WorklistTabNames.STUDY_LIST;
  };

  const handleSetWorklistTabParam = () => {
    // @ts-expect-error
    searchParams.set(PARAMS_KEYS.TABS, WorklistTabNames.WORKLIST);
    history.replace({
      search: searchParams.toString(),
    });
    return WorklistTabNames.WORKLIST;
  };

  const renderDefaultValue = useMemo(() => {
    // If the first query is still loading, we don't want to render any tab.
    if (isLoadingInitialCount) return '';

    if (!searchParams.get(PARAMS_KEYS.TABS)) {
      if (worklistTotalCount <= 0) {
        return handleSetStudyListTabParam();
      }

      return handleSetWorklistTabParam();
    }

    if (searchParams.get(PARAMS_KEYS.TABS) === WorklistTabNames.STUDY_LIST) return handleSetStudyListTabParam();

    // If we don't have any item in worklist, default is studyList tab.
    if (worklistTotalCount <= 0) return handleSetStudyListTabParam();

    if (searchParams.get(PARAMS_KEYS.SEARCH)) return handleSetStudyListTabParam();

    // Worklist case
    handleSetWorklistTabParam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingInitialCount]);

  if (isLoadingInitialCount) return null;

  return (
    <TabsRoot value={activeTab} className="e-flex-grow e-z-50" defaultValue={renderDefaultValue}>
      <TabsList>
        <div className={tabsContainerClass} role="tablist" aria-label="TabList">
          <Tabs counter={worklistTotalCount} />
        </div>
      </TabsList>

      <TabsContent className="e-flex e-flex-col e-flex-grow" value={WorklistTabNames.STUDY_LIST}>
        <StudyList />
      </TabsContent>
      <TabsContent className="e-flex e-flex-col e-flex-grow" value={WorklistTabNames.WORKLIST}>
        <Worklist />
      </TabsContent>
    </TabsRoot>
  );
};

interface TabsProps {
  counter: number;
}

const Tabs = ({ counter }: TabsProps) => {
  const { t } = useTranslation();
  const tabItemClass = cn('e-py-4 e-flex e-justify-center e-text-base e-px-4');
  const { activeTab } = useStudyListTabs();
  const [worklistTotalCount, setCurrentPage] = useStudyListStore(
    (state) => [state.worklistTotalCount, state.setCurrentPage],
    shallow,
  );
  const searchParams = useSearchParams();
  const history = useHistory();

  const tagVariant = useMemo(() => {
    return worklistTotalCount === 0 && activeTab === WorklistTabNames.WORKLIST
      ? TagVariant.transparent
      : TagVariant.primary;
  }, [activeTab, worklistTotalCount]);

  const handleStudyListClick = () => {
    // reset page
    setCurrentPage(1);
    // @ts-expect-error
    searchParams.set(PARAMS_KEYS.TABS, WorklistTabNames.STUDY_LIST);
    history.replace({
      search: searchParams.toString(),
    });
  };

  const handleWorklistClick = () => {
    setCurrentPage(1);
    // @ts-expect-error
    searchParams.set(PARAMS_KEYS.TABS, WorklistTabNames.WORKLIST);
    history.replace({
      search: searchParams.toString(),
    });
  };

  return (
    <div className="e-ml-6 e-flex">
      <TabsItem
        id="study-list-tab"
        tagVariant={tagVariant}
        label={t('general.navbar.studyLists')}
        className={tabItemClass}
        onClick={handleStudyListClick}
        value={WorklistTabNames.STUDY_LIST}
      />
      <TabsItem
        id="wokrk-list-tab"
        tagVariant={tagVariant}
        counter={counter}
        label={t('general.navbar.workList')}
        onClick={handleWorklistClick}
        className={tabItemClass}
        value={WorklistTabNames.WORKLIST}
      />
    </div>
  );
};
