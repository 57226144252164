import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NotificationAlert } from '~/components/shared/NotificationAlert';
import { NotificationType, useNotificationsStore } from '~/src/store/notifications-store';
import { InlineAlert } from '@evacenter/eden';
import { useStudyListContext } from '~/src/contexts/studyListContext';
import { StudyUpdateEvent, StudyUpdateType } from '../../useStudyListUpdates';

interface StudyTableNotificationRowProps {
  colSpan: number;
  isInfiniteScrollEnabled?: boolean;
  onNotificationRowClick?: () => void;
}

// TODO: Delete this component when we finish automatic updates for study list.
export const StudyTableNotificationRow: React.FC<StudyTableNotificationRowProps> = ({
  colSpan,
  isInfiniteScrollEnabled,
  onNotificationRowClick,
}) => {
  const { t } = useTranslation();
  const { clearNotifications, getNotifications } = useNotificationsStore();
  const { studiesCreated } = useStudyListContext();

  const handleNotificationRowClick = () => {
    onNotificationRowClick && onNotificationRowClick();
    clearNotifications(NotificationType.StudyListUpdates);
  };

  const studyListUpdatesNotifications = getNotifications(NotificationType.StudyListUpdates) as StudyUpdateEvent;

  const studiesUpdateError = useMemo(() => {
    return Object.keys(studyListUpdatesNotifications).filter(
      (studyId) => studyListUpdatesNotifications?.[studyId] === StudyUpdateType.error,
    ).length;
  }, [studyListUpdatesNotifications]);

  if (studiesUpdateError)
    return (
      <td colSpan={colSpan}>
        <AutomaticUpdateNotificationRowError onNotificationRowClick={handleNotificationRowClick} />;
      </td>
    );

  if (!studiesCreated) return null;

  return (
    <tr>
      <td colSpan={colSpan}>
        {isInfiniteScrollEnabled ? (
          <NewStudyNotification studiesCreated={studiesCreated} onNotificationRowClick={handleNotificationRowClick} />
        ) : (
          <NotificationAlert
            text={t('study.studyTable.studiesCreated', {
              count: studiesCreated,
            })}
            onClick={handleNotificationRowClick}
          />
        )}
      </td>
    </tr>
  );
};

type AutomaticUpdateNotificationRowErrorProps = Omit<
  StudyTableNotificationRowProps,
  'isInfiniteScrollEnabled' | 'colSpan' | 'studiesCreated'
>;

const AutomaticUpdateNotificationRowError = ({ onNotificationRowClick }: AutomaticUpdateNotificationRowErrorProps) => {
  const { t } = useTranslation();
  const { clearNotifications } = useNotificationsStore();
  const handleNotificationRowClick = () => {
    onNotificationRowClick && onNotificationRowClick();
    clearNotifications(NotificationType.StudyListUpdates);
  };

  return (
    <InlineAlert
      hasIcon={false}
      className="e-bg-primary-900"
      description={
        <div className="e-w-full e-text-center e-text-primary-300 e-text-sm">
          <Trans i18nKey="study.studyTable.automaticUpdatesError" />{' '}
          <span
            tabIndex={0}
            role="button"
            onClick={handleNotificationRowClick}
            onKeyPress={handleNotificationRowClick}
            className="e-cursor-pointer e-underline">
            {t('study.studyTable.studiesCreatedAction')}
          </span>
        </div>
      }
    />
  );
};

interface NotificationRowProps extends Omit<StudyTableNotificationRowProps, 'isInfiniteScrollEnabled' | 'colSpan'> {
  studiesCreated: number;
}

const NewStudyNotification = ({ studiesCreated, onNotificationRowClick }: NotificationRowProps) => {
  const { t } = useTranslation();
  const { clearNotifications } = useNotificationsStore();
  const handleNotificationRowClick = () => {
    onNotificationRowClick && onNotificationRowClick();
    clearNotifications(NotificationType.StudyListUpdates);
  };
  return (
    <InlineAlert
      hasIcon={false}
      className="e-bg-primary-900"
      description={
        <div className="e-w-full e-text-center e-text-primary-300 e-text-sm">
          <Trans
            i18nKey="study.studyTable.studiesCreatedInfiniteScroll"
            values={{ count: studiesCreated }}
            components={{ 1: <span className="e-font-bold" /> }}
          />{' '}
          <span
            tabIndex={0}
            role="button"
            onClick={handleNotificationRowClick}
            onKeyPress={handleNotificationRowClick}
            className="e-cursor-pointer e-underline">
            {t('study.studyTable.studiesCreatedAction')}
          </span>
        </div>
      }
    />
  );
};
