import React from 'react';
import Icon from '../Icon';
import { iconClasses, labelClasses, wrapperClasses } from './styles';
/**
 * This component show a text with an icon on left side
 * @author Alejo Forero<alejoforero.af@gmail.com>
 * Created at 2021-06-09
 */
export var IconLabel = function (_a) {
    var className = _a.className, iconClassName = _a.iconClassName, labelClassName = _a.labelClassName, icon = _a.icon, label = _a.label;
    return (React.createElement("div", { className: wrapperClasses(className) },
        React.createElement("i", { className: iconClasses(iconClassName) },
            React.createElement(Icon, { icon: icon })),
        React.createElement("span", { className: labelClasses(labelClassName) }, label)));
};
