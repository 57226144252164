import React from 'react';
import { useTranslation } from 'react-i18next';
import { RadioGroup, RadioCard } from '@eva-pacs/ui';
import { HTMLSpecialCharacters } from '~/utils/appHelpers';

import type { StudyRow } from '../studyList.d';

interface JoinStudiesSelectProps {
  /**
   * Collection of studies to display as the options to be selected.
   */
  studies: StudyRow[];

  /**
   * ID of the study to mark initially as selected.
   */
  defaultStudy?: StudyRow;

  /**
   * Provide a handler to perform an action when an option is selected.
   */
  onStudySelect: (study: StudyRow) => void;
}

/**
 * Use this component in order to display a list of options from where to choose the target study to perform the joining.
 * @author Alan Chávez<alan.chavez@evacenter.com>
 * Created at 2022-05-06
 */
export const JoinStudiesSelect = ({ studies, defaultStudy, onStudySelect }: JoinStudiesSelectProps) => {
  const { t } = useTranslation();

  const onStudyChange = (event) => {
    const study = studies.find((study) => study.actionColumn === event.target.value) as StudyRow;
    onStudySelect(study);
  };

  return (
    <>
      <p className="mb-5">
        <span className="text-light-gray-300">{t('study.join.selectedStudies')}:</span>{' '}
        <span className="text-basic-white font-semi-bold">{studies.length}</span>
      </p>
      <RadioGroup onChange={onStudyChange} defaultValue={defaultStudy?.actionColumn}>
        <div className="scrollbar-w-1 scrollbar-thumb-gray-200 scrollbar-thumb-rounded-lg flex flex-col overflow-y-auto space-y-4">
          {studies.map((study, index) => (
            <RadioCard value={study.actionColumn} key={`${study.actionColumn}-${index}`}>
              <div className="flex flex-col w-full space-y-2">
                <div className="text-basic-white text-paragraph-sm leading-tight">{study.patient.name}</div>
                <div className="flex flex-col text-light-gray-300 text-caption space-y-2">
                  <div className="flex overflow-hidden">
                    <div className="flex-none flex-nowrap">
                      {t('study.studyTable.identifier')}: {study.patient.identifier}
                    </div>
                    {study.dicomDescription && (
                      <>
                        <div className="mx-2">{HTMLSpecialCharacters.middot}</div>
                        <div className="max-w-full overflow-hidden">
                          <div className="truncate">{study.dicomDescription}</div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </RadioCard>
          ))}
        </div>
      </RadioGroup>
    </>
  );
};
