import { conrnerstoneUnits, transformUnits } from '@eva-pacs/core';
import csTools from 'cornerstone-tools';
import { PIXELS_NEAR_TOOL } from '~/constants';

const lineSegDistance = csTools.importInternal('util/lineSegDistance');
const getPixelSpacing = csTools.importInternal('util/getPixelSpacing');
const getNewContext = csTools.importInternal('drawing/getNewContext');
const setShadow = csTools.importInternal('drawing/setShadow');
const drawLine = csTools.importInternal('drawing/drawLine');
const draw = csTools.importInternal('drawing/draw');
const drawLinkedTextBox = csTools.importInternal('drawing/drawLinkedTextBox');
const drawHandles = csTools.importInternal('drawing/drawHandles');

/**
 * @public
 * @class Length
 * @memberof Tools.Annotation
 * @classdesc Tool for measuring distances.
 * @extends Tools.Annotation.Length
 * @author Ricardo Aguirre <ricardo.a.nava@gmail.com>
 * Created at 2022-03-16
 */
export default class CustomLength extends csTools.LengthTool {
  name;
  constructor(props) {
    super(props);
  }

  /**
   *
   *
   * @param {*} element
   * @param {*} data
   * @param {*} coords
   * @returns {Boolean}
   */
  pointNearTool(element, data, coords) {
    const hasStartAndEndHandles = data && data.handles && data.handles.start && data.handles.end;
    const validParameters = hasStartAndEndHandles;

    if (!validParameters) {
      csTools.logger.warn(`invalid parameters supplied to tool ${this.name}'s pointNearTool`);

      return false;
    }

    if (data.visible === false) return false;

    const segDistance = lineSegDistance(element, data.handles.start, data.handles.end, coords);
    return segDistance < PIXELS_NEAR_TOOL;
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    // @ts-ignore
    const { handleRadius, drawHandlesOnHover, hideHandlesIfMoving, renderDashed, digits } = this.configuration;
    const toolData = csTools.getToolState(evt.currentTarget, this.name);

    if (!toolData) return;

    // We have tool data for this element - iterate over each one and draw it
    const context = getNewContext(eventData.canvasContext.canvas);
    const { image, element } = eventData;
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

    const lineWidth = csTools.toolStyle.getToolWidth();
    const lineDash = csTools.getModule('globalConfiguration').configuration.lineDash;

    for (let i = 0; i < toolData.data.length; i++) {
      const data = toolData.data[i];

      if (data.visible === false) continue;

      draw(context, (context) => {
        // @ts-ignore
        setShadow(context, this.configuration);

        const color = csTools.toolColors.getColorIfActive(data);

        const lineOptions = { color, lineDash: undefined };

        if (renderDashed) lineOptions.lineDash = lineDash;

        // Draw the measurement line
        drawLine(context, element, data.handles.start, data.handles.end, lineOptions);

        // Draw the handles
        const handleOptions = {
          color,
          handleRadius,
          drawHandlesIfActive: drawHandlesOnHover,
          hideHandlesIfMoving,
        };

        // @ts-ignore
        if (this.configuration.drawHandles) drawHandles(context, eventData, data.handles, handleOptions);

        if (!data.handles.textBox.hasMoved) {
          const coords = {
            x: Math.max(data.handles.start.x, data.handles.end.x),
            y: Math.min(data.handles.start.y, data.handles.end.y),
          };

          // Depending on which handle has the largest x-value,
          // Set the y-value for the text box
          if (coords.x === data.handles.start.x) {
            coords.y = data.handles.start.y;
          } else {
            coords.y = data.handles.end.y;
          }

          data.handles.textBox.x = coords.x;
          data.handles.textBox.y = coords.y;
        }

        // Move the textbox slightly to the right and upwards
        // So that it sits beside the length tool handle
        const xOffset = 10;

        // Update textbox stats
        if (data.invalidated === true) {
          if (data.length) {
            // @ts-ignore
            this.throttledUpdateCachedStats(image, element, data);
          } else {
            // @ts-ignore
            this.updateCachedStats(image, element, data);
          }
        }

        const text = textBoxText(data, rowPixelSpacing, colPixelSpacing);
        const label = transformUnits(text);

        drawLinkedTextBox(
          context,
          element,
          data.handles.textBox,
          label,
          data.handles,
          textBoxAnchorPoints,
          color,
          lineWidth,
          xOffset,
          true,
        );
      });
    }

    // - SideEffect: Updates annotation 'suffix'
    function textBoxText(annotation, rowPixelSpacing, colPixelSpacing) {
      const measuredValue = _sanitizeMeasuredValue(annotation.length);

      // Measured value is not defined, return empty string
      if (!measuredValue) return '';

      // Set the length text suffix depending on whether or not pixelSpacing is available
      let suffix = conrnerstoneUnits.MM;

      if (!rowPixelSpacing || !colPixelSpacing) suffix = conrnerstoneUnits.pixelsComplete;

      annotation.unit = suffix;

      return `${measuredValue.toFixed(digits)} ${suffix}`;
    }

    function textBoxAnchorPoints(handles) {
      const midpoint = {
        x: (handles.start.x + handles.end.x) / 2,
        y: (handles.start.y + handles.end.y) / 2,
      };

      return [handles.start, midpoint, handles.end];
    }
  }
}

/**
 * Attempts to sanitize a value by casting as a number; if unable to cast,
 * we return `undefined`
 *
 * @param {*} value
 * @returns a number or undefined
 */
function _sanitizeMeasuredValue(value) {
  const parsedValue = Number(value);
  const isNumber = !isNaN(parsedValue);

  return isNumber ? parsedValue : undefined;
}
